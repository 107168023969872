import React from "react";
import { twMerge } from "tailwind-merge";
import BaseInput from "./BaseInput";

type BaseRadioProps = {
  name?: string;
  label: string;
  value: number | string | boolean;
  currentValue?: number | string | boolean;
  className?: string;
  onChange?: React.FormEventHandler<HTMLInputElement>;
};

export default function BaseRadio(props: BaseRadioProps) {
  const val =
    typeof props.value !== "boolean" ? props.value : props.value ? "1" : "";
  const currentVal =
    typeof props.currentValue !== "boolean"
      ? props.currentValue
      : props.currentValue
      ? "1"
      : "";

  return (
    <label
      className={twMerge(
        "flex flex-row gap-3 items-center font-normal",
        props.className
      )}
    >
      <BaseInput
        type="radio"
        name={props.name}
        value={val}
        checked={val === currentVal}
        onChange={props.onChange}
      />
      {props.label}
    </label>
  );
}
