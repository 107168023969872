import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

type SectionProps = {
  title: React.ReactNode | string;
  children?: React.ReactNode;
};

export default function Section(props: SectionProps) {
  return (
    <div>
      <h2 className="flex font-extrabold text-xl mb-5 place-items-center">
        <FontAwesomeIcon
          icon={faCircle}
          className="mr-3 text-green-2 text-xs self-center"
        />
        {props.title}
      </h2>
      {props.children}
    </div>
  );
}
