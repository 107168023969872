import React from "react";

type ThePlanningHeaderProps = {
  nbDays: number;
  begin: number;
  zoom: number;
  weekfocus: Date;
  currentmonth: Date;
  onHover: (begin: Date, duration: number) => void;
};

export default function ThePlanningHeader(props: ThePlanningHeaderProps) {

  function periodFromDate(type: "year" | "month" | "week" | "day", date: Date) {
    let duration = 0;

    const wBegin = new Date(props.begin);
    wBegin.setHours(0, 0, 0);
    const lastDay = new Date(date);
    lastDay.setHours(0, 0, 0);
    let firstDay = new Date(date);
    firstDay.setHours(0, 0, 0);
    firstDay.setDate(firstDay.getDate() - 1);

    switch (type) {
      case "year": {
        firstDay.setMonth(0);
        firstDay.setDate(1);
        if (firstDay < wBegin) {
          firstDay = wBegin;
        }
        duration = Math.round(
          (lastDay.getTime() - firstDay.getTime()) / 1000 / 60 / 60 / 24
        );
        break;
      }
      case "month": {
        firstDay.setDate(1);
        if (firstDay < wBegin) {
          firstDay = wBegin;
        }
        duration = Math.round(
          (lastDay.getTime() - firstDay.getTime()) / 1000 / 60 / 60 / 24
        );
        break;
      }
      case "week": {
        duration = firstDay.getDay() || 7;

        firstDay.setDate(firstDay.getDate() - duration + 1);
        const diff =
          (wBegin.getTime() - firstDay.getTime()) / 1000 / 60 / 60 / 24;
        if (diff > 0) {
          duration = duration - diff;
        }
        break;
      }
    }
    const beginDate = new Date(date);
    beginDate.setDate(beginDate.getDate() - duration);
    beginDate.setHours(0, 0, 0);
    beginDate.setTime(
      beginDate.getTime() - beginDate.getTimezoneOffset() * 60000
    );

    return {
      begin: beginDate,
      duration: duration || 1,
    };
  }

  return (
    <div
      className="px-3.5 grid justify-items-stretch items-center h-16 sticky top-0"
      style={{
        gridTemplateColumns: `repeat(${props.nbDays},minmax(0,1fr))`,
      }}
    >
      {(() => {
        const results = {
          years: new Array<React.ReactNode>(),
          months: new Array<React.ReactNode>(),
          weeks: new Array<React.ReactNode>(),
          days: new Array<React.ReactNode>(),
        };
        let key = 0;
        const currentDate = new Date(props.begin);
        let currentYear = 0;
        let yearLen = 0;
        let currentMonth = "";
        let monthLen = 0;
        let currentWeek = 0;
        let weekLen = 0;
        for (let i = 0; i < props.nbDays; i++) {
          if (currentDate.getFullYear() === currentYear) {
            yearLen++;
          } else {
            addYear();
            currentYear = currentDate.getFullYear();
            yearLen = 1;
          }
          if (
            currentDate
              .toLocaleString("fr", { month: "short" })
              .toUpperCase() === currentMonth
          ) {
            monthLen++;
          } else {
            addMonth();
            currentMonth = currentDate
              .toLocaleString("fr", { month: "short" })
              .toUpperCase();
            monthLen = 1;
          }
          if (currentDate.getWeekNumber() === currentWeek) {
            weekLen++;
          } else {
            addWeek();
            currentWeek = currentDate.getWeekNumber();
            weekLen = 1;
          }
          results.days.push(
            <span
              key={key++}
              className="text-center h-4 text-xs hover:bg-opacity-20 hover:bg-green-1"
              data-focus={
                props.weekfocus.getDate() === currentDate.getDate()
                && props.weekfocus.getMonth() === currentDate.getMonth()
                && props.weekfocus.getFullYear() === currentDate.getFullYear()
                ? "weekfocus" : ""
              }
              data-value={currentDate.toISOString()}
              onMouseEnter={(event) => {
                const currDate = event.currentTarget.dataset.value;
                if (currDate) {
                  const period = periodFromDate("day", new Date(currDate));
                  props.onHover(period.begin, period.duration);
                }
              }}
              onMouseLeave={() => {
                props.onHover(new Date(props.begin), 0);
              }}
            >
              {currentDate.toLocaleDateString("fr-FR", { weekday: "narrow" }) +
                " " +
                currentDate.getDate()}
            </span>
          );
          currentDate.setDate(currentDate.getDate() + 1);
        }
        addYear();
        addMonth();
        addWeek();
        return [
          results.years.length <= 12 * (props.zoom / 100) ? results.years : [],
          results.months.length <= 12 * (props.zoom / 100)
            ? results.months
            : [],
          results.weeks.length <= 20 * (props.zoom / 100) ? results.weeks : [],
          results.days.length <= 50 * (props.zoom / 100) ? results.days : [],
        ];

        function addYear() {
          if (yearLen !== 0) {
            results.years.push(
              <span
                key={key++}
                className="text-center h-4 hover:bg-opacity-20 hover:bg-green-1 flex justify-center"
                style={{ gridColumn: `auto / span ${yearLen}` }}
                data-value={currentDate.toISOString()}
                onMouseEnter={(event) => {
                  const currDate = event.currentTarget.dataset.value;
                  if (currDate) {
                    const period = periodFromDate("year", new Date(currDate));
                    props.onHover(period.begin, period.duration);
                  }
                }}
                onMouseLeave={() => {
                  props.onHover(new Date(props.begin), 0);
                }}
              >
                <span className="sticky text-xs left-48 right-0">{currentYear}</span>
              </span>
            );
          }
        }
        function addMonth() {
          if (monthLen !== 0) {
            results.months.push(
              <span
                key={key++}
                className="text-center h-4 hover:bg-opacity-20 hover:bg-green-1 flex justify-center"
                style={{ gridColumn: `auto / span ${monthLen}` }}
                data-value={currentDate.toISOString()}
                data-focus={
                  props.currentmonth.getFullYear() === currentDate.getFullYear()
                  && props.currentmonth.getMonth() === currentDate.getMonth()
                  ? "monthfocus" : ""
                }
                onMouseEnter={(event) => {
                  const currDate = event.currentTarget.dataset.value;
                  if (currDate) {
                    const period = periodFromDate("month", new Date(currDate));
                    props.onHover(period.begin, period.duration);
                  }
                }}
                onMouseLeave={() => {
                  props.onHover(new Date(props.begin), 0);
                }}
              >
                <span className="sticky text-xs left-48 right-0">{currentMonth}</span>
              </span>
            );
          }
        }
        function addWeek() {
          if (weekLen !== 0) {
            results.weeks.push(
              <span
                key={key++}
                className="text-center h-4 hover:bg-opacity-20 hover:bg-green-1 flex justify-center"
                style={{ gridColumn: `auto / span ${weekLen}` }}
                data-value={currentDate.toISOString()}
                onMouseEnter={(event) => {
                  const currDate = event.currentTarget.dataset.value;
                  if (currDate) {
                    const period = periodFromDate("week", new Date(currDate));
                    props.onHover(period.begin, period.duration);
                  }
                }}
                onMouseLeave={() => {
                  props.onHover(new Date(props.begin), 0);
                }}
              >
                <span className="sticky text-xs left-48 right-0">S{currentWeek}</span>
              </span>
            );
          }
        }
      })()}
    </div>
  );
}
