import React from "react";
import LotsPhasesList from "../components/LotsPhasesList";
import { useNewWorksite } from "./worksitesNew";

export default function WorksitesNewLotsPhases() {
  const context = useNewWorksite();
  const [worksite, setWorksite] = context.worksite;
  const formRef = context.formRef;
  const partners = context.partners;

  return (
    <form
      ref={(ref) => {
        formRef.current = ref;
      }}
    >
      <LotsPhasesList
        worksiteName={worksite.name}
        worksite={undefined}
        lots={worksite.lots}
        handleNewLot={(newLot) =>
          new Promise<void>((resolve) => {
            const newID =
              Math.max(...worksite.lots.map((lot) => lot.id), 0) + 1;
            setWorksite((oldWorksite) => ({
              ...oldWorksite,
              lots: [...oldWorksite.lots, { ...newLot, id: newID, "@id": "" }],
            }));
            resolve();
          })
        }
        handleEditLot={(lot) =>
          new Promise<void>((resolve) => {
            setWorksite((oldWorksite) => ({
              ...oldWorksite,
              lots: oldWorksite.lots.map((l) => {
                if (l.id === lot.id) {
                  return lot;
                }
                return l;
              }),
            }));
            resolve();
          })
        }
        handleDeleteLot={(id) =>
          new Promise<void>((resolve) => {
            setWorksite((oldWorksite) => ({
              ...oldWorksite,
              lots: oldWorksite.lots.filter((lot) => lot.id !== id),
              phases: oldWorksite.phases.filter((phase) => +phase.lot !== id),
            }));
            resolve();
          })
        }
        phases={worksite.phases}
        handleNewPhase={(newPhase) =>
          new Promise<void>((resolve) => {
            const newID =
              Math.max(...worksite.phases.map((phase) => phase.id), 0) + 1;
            setWorksite((oldWorksite) => ({
              ...oldWorksite,
              phases: [...oldWorksite.phases, { ...newPhase, id: newID }],
            }));
            resolve();
          })
        }
        handleEditPhase={(phase) =>
          new Promise<void>((resolve) => {
            setWorksite((oldWorksite) => ({
              ...oldWorksite,
              phases: oldWorksite.phases.map((p) => {
                if (p.id === phase.id) {
                  return phase;
                }
                return p;
              }),
            }));
            resolve();
          })
        }
        handleDeletePhase={(id) =>
          new Promise<void>((resolve) => {
            setWorksite((oldWorksite) => ({
              ...oldWorksite,
              phases: oldWorksite.phases.filter((phase) => phase.id !== id),
            }));
            resolve();
          })
        }
        targets={partners.filter((partner) =>
          worksite.partners.find((p) => p === partner.value)
        )}
      />
    </form>
  );
}
