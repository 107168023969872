import { faPlus, faSpinner, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mime from "mime";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import React from "react";
import BaseButton from "../components/BaseButton";
import BaseInput from "../components/BaseInput";
import BaseLabel from "../components/BaseLabel";
import BasePopup from "../components/BasePopup";
import BaseRadio from "../components/BaseRadio";
import BaseSelect from "../components/BaseSelect";
import BaseTextArea from "../components/BaseTextArea";
import DoubleColumn from "../components/DoubleColumn";
import FilesCategory from "../components/FilesCategory";
import {
  Alert,
  File as MyFile,
  FilesCategory as FilesCategoryType,
} from "../routes/worksitesNew";
import BaseInputFile from "../components/BaseInputFile";
import { useCheckTokenAndFetch } from "../auth";
import { toast } from "react-toastify";
import { b64toBin } from "../utils";
import { useLocation } from "react-router-dom";

type FilesListProps = {
  clientID?: string;
  worksiteID?: number;
  worksiteName: string;
  partners: Array<string>;
  insurances?: Array<MyFile>;
  reports?: Array<MyFile>;
  files: Array<FilesCategoryType>;
  filesSetter: (files: Array<FilesCategoryType>) => void;
  onDelete?: (id: number) => Promise<Response>;
  onEdit?: (file: MyFile) => Promise<Response>;
  onNew?: (file: MyFile, category: string) => Promise<Response>;
};

type PartnerDocuments = {
  insurances: Array<MyFile>;
};

export default function FilesList(props: FilesListProps) {
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isDownloadLoading, setIsDownloadLoading] =
    React.useState<boolean>(false);
  const [isExportLoading, setIsExportLoading] = React.useState<boolean>(false);
  const [isExportPopupActive, setIsExportPopupActive] = React.useState<boolean>(false);
  const [exportMessage, setExportMessage] = React.useState<string>("");
  const [isReviewLoading, setIsReviewLoading] = React.useState<boolean>(false);
  const [isReviewPopupActive, setIsReviewPopupActive] = React.useState<boolean>(false);
  const [reviewMessage, setReviewMessage] = React.useState<string>("");
  const [isNewFileActive, setIsNewFileActive] = React.useState<boolean>(false);
  // const [partnersDocuments, setPartnersDocuments] = React.useState<
  //   Array<PartnerDocuments>
  // >([]);
  const [currentFile, setCurrentFile] = React.useState<{
    cIndex: number;
    fIndex: number;
    file: MyFile;
  } | null>(null);
  const [newFileContent, setNewFileContent] = React.useState<string>("");
  const [newFile, setNewFile] = React.useState<MyFile>({
    "@id": "",
    name: "",
    createdAt: new Date().toISOString(),
    content: "",
    alerts: [],
  });

  // React.useEffect(() => {
  //   Promise.all(
  //     props.partners.map((partner) =>
  //       checkTokenAndFetch(`${partner}/get_files`, {
  //         method: "GET",
  //       }).then((p: PartnerDocuments) => {
  //         return Promise.resolve(p);
  //       })
  //     )
  //   ).then((partners) => {
  //     setPartnersDocuments(partners);
  //   });
  // }, [props.partners]);
  function handleNewFileChange(
    event: React.BaseSyntheticEvent,
    alertIndex?: number,
    alertParam?: keyof Alert
  ) {
    if (alertIndex !== undefined && alertParam !== undefined) {
      setNewFile({
        ...newFile,
        alerts: newFile.alerts.map((alert, aIndex) => {
          if (aIndex === alertIndex) {
            let value = event.currentTarget.value;
            if (alertParam === "hasClientAlert") {
              value = !!value;
            }
            return {
              ...alert,
              [alertParam]: value,
            };
          }
          return alert;
        }),
      });
    } else {
      const param: keyof MyFile = event.currentTarget.name;

      setNewFile({
        ...newFile,
        [param]: event.currentTarget.value,
      });
    }
  }
  function handleEditFileChange(
    event: React.BaseSyntheticEvent,
    alertIndex?: number,
    alertParam?: keyof Alert
  ) {
    if (currentFile) {
      if (alertIndex !== undefined && alertParam !== undefined) {
        setCurrentFile({
          ...currentFile,
          file: {
            ...currentFile.file,
            alerts: currentFile.file.alerts.map((alert, aIndex) => {
              if (aIndex === alertIndex) {
                let value = event.currentTarget.value;
                if (alertParam === "hasClientAlert") {
                  value = !!value;
                }
                return {
                  ...alert,
                  [alertParam]: value,
                };
              }
              return alert;
            }),
          },
        });
      } else {
        const param: Exclude<keyof MyFile, "id"> = event.currentTarget.name;
        setCurrentFile({
          ...currentFile,
          file: { ...currentFile.file, [param]: event.currentTarget.value },
        });
      }
    }
  }
  function handleNewFile(event: React.SyntheticEvent) {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      type: { value: number };
    };
    const myFile = {
      name: newFile.name,
      createdAt: new Date().toISOString(),
      content: newFileContent,
      alerts: newFile.alerts,
    };
    const cat = props.files[+target.type.value]["@id"];
    if (props.onNew && cat) {
      props.onNew(myFile, cat).then((resp) => {
        const respJson = JSON.parse(JSON.stringify(resp));
        addFile(respJson);
        setNewFileContent("");
        setNewFile({
          name: "",
          createdAt: new Date().toISOString(),
          content: "",
          alerts: [],
        });
        setIsNewFileActive(false);
      });
    } else {
      addFile(myFile);
    }

    function addFile(file: MyFile) {
      props.filesSetter(
        props.files.map((category, cIndex) => {
          if (+target.type.value === cIndex) {
            category.files.push(file);
          }
          return category;
        })
      );
      setNewFile({
        name: "",
        createdAt: new Date().toISOString(),
        content: "",
        alerts: [],
      });
      setNewFileContent("");
      setIsNewFileActive(false);
    }
  }
  function onDelete(toDelete: number, catI: number) {
    const cat = props.files[catI];
    const id = cat.files[toDelete].id;
    if (props.onDelete && id) {
      props.onDelete(id).then((resp) => {
        if (resp.ok) {
          cat.files = cat.files.filter((_, index) => index !== toDelete);
          props.filesSetter([...props.files]);
        }
      });
    } else {
      cat.files = cat.files.filter((_, index) => index !== toDelete);
      props.filesSetter([...props.files]);
    }
  }

  function handleExportCNL(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsExportLoading(true);
    checkTokenAndFetch(
      `/api/worksites/${props.worksiteID}/export_request`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          exportMessage: exportMessage,
        }),
      }
    )
      .then(() => {
        toast.success(
          "Un email d'export vient d'être envoyé au client"
        );
      })
      .catch((err) => {
        toast.error("Une erreur est survenue");
      })
      .finally(() => {
        setIsExportLoading(false);
        setIsExportPopupActive(false);
        setExportMessage("");
      });
  }

  function handleSendNewReview(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsReviewLoading(true);
    checkTokenAndFetch(`${props.clientID}/review_request`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        reviewMessage: reviewMessage,
      }),
    })
      .then(() => {
        toast.success(
          "Un email de demande d'avis google a été envoyé au client"
        );
      })
      .catch((err) => {
        toast.error("Une erreur est survenue");
      })
      .finally(() => {
        setIsReviewLoading(false);
        setIsReviewPopupActive(false);
        setReviewMessage("");
      });
  }

  return (
    <>
      <div className="flex gap-5 mb-9">
        <BaseButton
          type="button"
          styleType="primary"
          icon={{ icon: faPlus }}
          content="Ajouter un document"
          onClick={() => {
            setIsNewFileActive(true);
          }}
        />
        {!useLocation().pathname.includes("/chantiers/ajouter/documents") && (
          <BaseButton
            type="button"
            styleType="primary"
            content="Tout télécharger"
            icon={
              isDownloadLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                  }
                : undefined
            }
            onClick={() => {
              setIsDownloadLoading(true);
              const zip = new JSZip();
              const categories = props.files;
              categories.push({
                name: "Assurances",
                roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
                files: props.insurances || [],
              });
              if (props.reports) {
                categories.push({
                  name: "PV de réception",
                  roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
                  files: props.reports,
                });
              }
              categories.forEach((category) => {
                const folder = zip.folder(
                  category.name.toLowerCase().split(" ").join("_")
                );
                if (folder) {
                  category.files.forEach((file) => {
                    if (file.content) {
                      const [type, content] = file.content.split(";");
                      const bin = b64toBin(content.split(",")[1]);
                      if (bin) {
                        folder.file(
                          `${file.name}.${mime.getExtension(type.split(":")[1])}`,
                          bin
                        );
                      }
                    }
                  });
                }
              });
              zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, `${props.worksiteName}.zip`);
                setIsDownloadLoading(false);
              });
            }}
          />
        )}
        {props.worksiteID && (
          <BaseButton
            type="button"
            styleType="secondary"
            content="Générer CNL"
            icon={
              isExportLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                  }
                : undefined
            }
            onClick={() => {
              setIsExportPopupActive(true);
            }}
          />
        )}
        <div className="grow"></div>
        {props.clientID && (
          <BaseButton
            type="button"
            styleType="secondary"
            icon={
              isReviewLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                  }
                : { icon: faStar }
            }
            content="Avis client"
            onClick={() => {
              setIsReviewPopupActive(true);
            }}
          />
        )}
      </div>

      <DoubleColumn
        left={props.files
          .filter((_, i) => i < ((props.files.length / 2) + 1))
          .map((category, cIndex) => (
            <FilesCategory
              key={cIndex}
              files={category}
              onDelete={(cat) => {
                onDelete(cat, cIndex);
              }}
              onEdit={(file, fIndex) => {
                setCurrentFile({
                  cIndex: cIndex,
                  fIndex: fIndex,
                  file: file,
                });
              }}
            />
          ))}
        right={[
          <FilesCategory
            key="insurances"
            files={{
              name: "Assurances",
              roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
              files: props.insurances ? props.insurances : [],
            }}
          />,
          props.reports && (
            <FilesCategory
              key="reports"
              files={{
                name: "PV de réception",
                roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
                files: props.reports,
              }}
            />
          ),
        ].concat(
          props.files
            .filter((_, i) => i >= ((props.files.length / 2) + 1))
            .map((category, cIndex) => (
              <FilesCategory
                key={cIndex}
                files={category}
                onDelete={(cat) => {
                  onDelete(
                    cat,
                    Math.floor(props.files.length / 2 + 1) + cIndex + 1
                  );
                }}
                onEdit={(file, fIndex) => {
                  setCurrentFile({
                    cIndex: Math.floor(props.files.length / 2 + 1) + cIndex + 1,
                    fIndex: fIndex,
                    file: file,
                  });
                }}
              />
            ))
        )}
      />
      <BasePopup
        title="Nouveau document"
        isVisible={isNewFileActive}
        className="min-w-[400px]"
        onClose={() => {
          setIsNewFileActive(false);
        }}
      >
        <form onSubmit={handleNewFile}>
          <div className="flex flex-col gap-5">
            <BaseLabel required label="Catégorie du document">
              <BaseSelect
                required
                name="type"
                options={props.files.map((category, cIndex) => ({
                  label: category.name,
                  value: cIndex,
                }))}
              />
            </BaseLabel>
            <BaseLabel required label="Libellé">
              <BaseInput
                required
                type="text"
                name="name"
                value={newFile.name}
                onChange={handleNewFileChange}
              />
            </BaseLabel>
            <BaseInputFile
              required
              onChange={(file) => {
                if (file) {
                  setNewFileContent(file.content);
                }
              }}
            />
            <div className="bg-grey-7 rounded p-4">
              <div className="flex flex-wrap items-center gap-2 text-green-1 font-bold">
                <div
                  className="flex place-items-center justify-center rounded-full bg-green-3 bg-opacity-20 w-8 h-8 cursor-pointer"
                  onClick={() => {
                    setNewFile({
                      ...newFile,
                      alerts: [
                        ...newFile.alerts,
                        {
                          name: "",
                          description: "",
                          hasClientAlert: false,
                          dueDate: new Date().toISOString().slice(0, 10),
                          recallDate: "",
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                Alerte
              </div>
            </div>
            {newFile.alerts.map((alert, aIndex) => (
              <div
                key={aIndex}
                className="bg-grey-7 rounded p-4 flex flex-col gap-3"
              >
                <BaseLabel required label="Nom de l'alerte">
                  <BaseInput
                    required
                    type="text"
                    value={alert.name}
                    onChange={(event) => {
                      handleNewFileChange(event, aIndex, "name");
                    }}
                  />
                </BaseLabel>
                <BaseLabel label="Description">
                  <BaseInput
                    type="text"
                    value={alert.description}
                    onChange={(event) => {
                      handleNewFileChange(event, aIndex, "description");
                    }}
                  />
                </BaseLabel>
                <div className="grid grid-flow-col grid-rows-2 gap-x-3">
                  <BaseLabel required label="Echeance">
                    <BaseInput
                      required
                      type="date"
                      value={alert.dueDate}
                      onChange={(event) => {
                        handleNewFileChange(event, aIndex, "dueDate");
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel label="Rappel">
                    <BaseInput
                      type="date"
                      value={alert.recallDate}
                      onChange={(event) => {
                        handleNewFileChange(event, aIndex, "recallDate");
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel required label="Alerte envoyée au client ?">
                    <div className="flex flex-row gap-6">
                      <BaseRadio
                        className="shrink"
                        label="Non"
                        value={false}
                        currentValue={alert.hasClientAlert}
                        onChange={(event) => {
                          handleNewFileChange(event, aIndex, "hasClientAlert");
                        }}
                      />
                      <BaseRadio
                        className="shrink"
                        label="Oui"
                        value={true}
                        currentValue={alert.hasClientAlert}
                        onChange={(event) => {
                          handleNewFileChange(event, aIndex, "hasClientAlert");
                        }}
                      />
                    </div>
                  </BaseLabel>
                  <BaseButton
                    type="button"
                    styleType="danger"
                    content="Supprimer"
                    className="w-min place-self-end justify-self-end"
                    onClick={() => {
                      setNewFile({
                        ...newFile,
                        alerts: newFile.alerts.filter((_, i) => i !== aIndex),
                      });
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <BaseButton
            type="submit"
            styleType="primary"
            content="Ajouter"
            className="mt-4 float-right"
          />
        </form>
      </BasePopup>
      <BasePopup
        title="Modifier le document"
        isVisible={currentFile !== null}
        className="min-w-[400px]"
        onClose={() => {
          setCurrentFile(null);
        }}
      >
        {currentFile && (
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (props.onEdit && currentFile.file.id) {
                props.onEdit(currentFile.file).then(() => {
                  addFile();
                });
              } else {
                addFile();
              }

              setCurrentFile(null);

              function addFile() {
                if (currentFile) {
                  props.filesSetter(
                    props.files.map((category, cIndex) => {
                      if (cIndex === currentFile?.cIndex) {
                        category.files = category.files.map((file, fIndex) => {
                          if (fIndex === currentFile.fIndex) {
                            return currentFile.file;
                          } else {
                            return file;
                          }
                        });
                      }
                      return category;
                    })
                  );
                }
              }
            }}
          >
            <div className="flex flex-col gap-5">
              <BaseLabel required label="Libellé">
                <BaseInput
                  required
                  type="text"
                  name="name"
                  value={currentFile.file.name}
                  onChange={handleEditFileChange}
                />
              </BaseLabel>
              <div className="bg-grey-7 rounded p-4">
                <div className="flex flex-wrap items-center gap-2 text-green-1 font-bold">
                  <div
                    className="flex place-items-center justify-center rounded-full bg-green-3 bg-opacity-20 w-8 h-8 cursor-pointer"
                    onClick={() => {
                      setCurrentFile({
                        ...currentFile,
                        file: {
                          ...currentFile.file,
                          alerts: [
                            ...currentFile.file.alerts,
                            {
                              name: "",
                              description: "",
                              hasClientAlert: false,
                              dueDate: new Date().toISOString().slice(0, 10),
                              recallDate: "",
                            },
                          ],
                        },
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  Alerte
                </div>
              </div>
              {currentFile.file.alerts.map((alert, aIndex) => (
                <div
                  key={aIndex}
                  className="bg-grey-7 rounded p-4 flex flex-col gap-3"
                >
                  <BaseLabel required label="Nom de l'alerte">
                    <BaseInput
                      required
                      type="text"
                      value={alert.name}
                      onChange={(event) => {
                        //TODO
                        handleEditFileChange(event, aIndex, "name");
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel label="Description">
                    <BaseInput
                      type="text"
                      value={alert.description}
                      onChange={(event) => {
                        handleEditFileChange(event, aIndex, "description");
                      }}
                    />
                  </BaseLabel>
                  <div className="grid grid-flow-col grid-rows-2 gap-x-3">
                    <BaseLabel required label="Echeance">
                      <BaseInput
                        required
                        type="date"
                        value={alert.dueDate.slice(0, 10)}
                        onChange={(event) => {
                          handleEditFileChange(event, aIndex, "dueDate");
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel label="Rappel">
                      <BaseInput
                        type="date"
                        value={alert.recallDate?.slice(0, 10)}
                        onChange={(event) => {
                          handleEditFileChange(event, aIndex, "recallDate");
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Alerte envoyée au client ?">
                      <div className="flex flex-row gap-6">
                        <BaseRadio
                          className="shrink"
                          label="Non"
                          value={false}
                          currentValue={alert.hasClientAlert}
                          onChange={(event) => {
                            handleEditFileChange(
                              event,
                              aIndex,
                              "hasClientAlert"
                            );
                          }}
                        />
                        <BaseRadio
                          className="shrink"
                          label="Oui"
                          value={true}
                          currentValue={alert.hasClientAlert}
                          onChange={(event) => {
                            handleEditFileChange(
                              event,
                              aIndex,
                              "hasClientAlert"
                            );
                          }}
                        />
                      </div>
                    </BaseLabel>
                    <BaseButton
                      type="button"
                      styleType="danger"
                      content="Supprimer"
                      className="w-min place-self-end justify-self-end"
                      onClick={() => {
                        setCurrentFile({
                          ...currentFile,
                          file: {
                            ...currentFile.file,
                            alerts: currentFile.file.alerts.filter(
                              (_, i) => i !== aIndex
                            ),
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <BaseButton
              type="submit"
              styleType="primary"
              content="Enregistrer"
              className="mt-4 float-right"
            />
          </form>
        )}
      </BasePopup>
      <BasePopup
        title="Générer le CNL"
        isVisible={isExportPopupActive}
        className="max-w-3xl min-w-[800px]"
        onClose={() => {
          setIsExportPopupActive(false);
        }}
      >
        <form
          onSubmit={(e) => {
            handleExportCNL(e);
          }}
        >
          <div className="flex flex-col gap-5">
            <BaseLabel label="Message personnalisé">
              <BaseTextArea
                rows={5}
                placeholder="Bonjour [Prénom] [Nom],&#10;Veuillez trouver ci-dessous le lien de téléchargement du fichier contenant l’ensemble des documents concernant votre construction."
                value={exportMessage}
                onChange={(e) => {
                  setExportMessage((e.target as HTMLInputElement).value );
                }}
              />
            </BaseLabel>
          </div>
          <BaseButton
            type="submit"
            styleType="primary"
            content="Générer"
            className="mt-4 float-right"
            icon={
              isExportLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                } : undefined
            }
          />
        </form>
      </BasePopup>
      <BasePopup
        title="Envoyer avis client"
        isVisible={isReviewPopupActive}
        className="max-w-3xl min-w-[800px]"
        onClose={() => {
          setIsReviewPopupActive(false);
        }}
      >
        <form
          onSubmit={(e) => {
            handleSendNewReview(e);
          }}
        >
          <div className="flex flex-col gap-5">
            <BaseLabel label="Contenu personnalisé">
              <BaseTextArea
                rows={5}
                placeholder="Bonjour [Prénom] [Nom],&#10;Votre construction est terminée, vous avez pris possession des lieux. Laissez-nous votre retour d’expérience !&#10;Afin de nous laisser un avis google merci de suivre le lien suivant :"
                value={reviewMessage}
                onChange={(e) => {
                  setReviewMessage((e.target as HTMLInputElement).value );
                }}
              />
            </BaseLabel>
          </div>
          <BaseButton
            type="submit"
            styleType="primary"
            content="Envoyer"
            className="mt-4 float-right"
            icon={
              isReviewLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                  }
                : undefined
            }
          />
        </form>
      </BasePopup>
    </>
  );
}
