import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {
  matchPath,
  Navigate,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import Badge from "../components/Badge";
import BaseSelect from "../components/BaseSelect";
import Loading from "../components/Loading";
import Page from "../components/Page";
import Tabs from "../components/Tabs";
import { useCheckTokenAndFetch } from "../auth";
import { useWorksites } from "./layout";
import { toast } from "react-toastify";

export type File = {
  id?: number;
  createdAt: string;
  name?: string;
  content: string;
};

export type Memo = {
  id: number;
  createdAt: string;
  target?: {
    "@id": string;
    "@type": string;
    companyName: string;
    firstname: string;
    lastname: string;
  };
  message: string;
  attachedFiles: Array<File>;
};

export type Client = {
  "@id": string;
  id: number;
  sex: number;
  firstname: string;
  lastname: string;
  email: string;
  isProfessional: boolean;
  companyStatus: string;
  companyName: string;
  address: string;
  zipCode: string;
  city: string;
  cellPhone: string;
  homePhone: string;
  related?: Omit<
    Client,
    "related" | "isProfessional" | "companyStatus" | "companyName"
  >;
};

export type Report = {
  "@id": string;
  id: number;
  state: number;
  date: string;
  message: string;
  partner: string;
  attachedFile: File;
};

export type Partner = {
  "@id": string;
  firstname: string;
  lastname: string;
  companyName: string;
};

export type PartnerAPI = {
  label: string;
  value: string;
};

export type CountsType = {
  tasksCount: number;
  reportsCount: number;
};

export default function Worksite() {
  const location = useLocation();
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [worksites, setWorksites] = useWorksites();
  const worksite = React.useMemo(
    () => worksites.find((worksite) => `${worksite.id}` === params.id),
    [params.id, worksites]
  );
  const worksiteStates = [
    {
      label: "En création",
      value: 0,
    },
    {
      label: "Ouvert",
      value: 1,
    },
    {
      label: "En standby",
      value: 2,
    },
    {
      label: "Terminé",
      value: 3,
    },
  ];
  // const [tasksCount, setTasksCount] = React.useState<number>(0);
  // const [reportsCount, setReportsCount] = React.useState<number>(0);
  const [counts, setCounts] = React.useState<CountsType>({
    tasksCount: 0,
    reportsCount: 0,
  });


  React.useEffect(() => {
    // setReportsCount(
    //   worksite?.lots
    //     .flatMap((lot) => lot.reports)
    //     .filter((report) => report.state !== 4)
    //     .concat(worksite.generalReports.filter((report) => report.state !== 4))
    //     .length ?? 0
    // );
    // setTasksCount(
    //   worksite?.tasks
    //     .filter((task) => !task.isDone)
    //     .length ?? 0
    // );
    setCounts({
      tasksCount:
        worksite?.tasks
          .filter((task) => !task.isDone)
          .length ?? 0
      ,
      reportsCount:
        worksite?.lots
          .flatMap((lot) => lot.reports)
          .filter((report) => report.state !== 4)
          .concat(worksite.generalReports.filter((report) => report.state !== 4))
          .length ?? 0
    });
  }, [worksite]);

  return matchPath(`chantiers/${params.id}`, location.pathname) ? (
    <Navigate replace to="tableau-de-bord" />
  ) : worksite !== undefined ? (
    <Page
      icon={{ icon: faBuilding }}
      title={
        <>
          {worksite.name}
          <label className="pl-10 text-base text-green-1 font-normal">
            Chantier :
            <BaseSelect
              className="ml-2 w-max"
              name="state"
              value={worksite.state}
              options={worksiteStates}
              onChange={(event) => {
                const val = +event.currentTarget.value;
                checkTokenAndFetch(`/api/worksites/${params.id}/patch_state`, {
                  headers: {
                    "Content-Type": "application/merge-patch+json",
                  },
                  method: "PATCH",
                  body: JSON.stringify({
                    state: val,
                  }),
                }).then(() => {
                  setWorksites(
                    worksites.map((w) => {
                      if (w.id === worksite.id) {
                        return {
                          ...worksite,
                          state: val,
                        };
                      }
                      return w;
                    })
                  );
                  toast.success("L'état du chantier a bien été mis à jour");
                });
              }}
            />
          </label>
        </>
      }
    >
      <Tabs
        items={[
          { to: "tableau-de-bord", content: "Tableau de bord" },
          { to: "infos", content: "Chantier" },
          { to: "planning", content: "Planning" },
          {
            to: "taches-infos",
            content: (
              <>
                Infos / Tâches
                <Badge
                  value={counts.tasksCount}
                  type="primary"
                />
              </>
            ),
          },
          { to: "financier", content: "Financier" },
          {
            to: "pv-reception",
            content: (
              <>
                PV Réception
                <Badge
                  value={counts.reportsCount}
                  type="danger"
                />
              </>
            ),
          },
          { to: "lots-phases", content: "Lot / Phases" },
          { to: "documents", content: "Documents" },
        ]}
      >
        <Outlet context={[counts, setCounts]} />
      </Tabs>
    </Page>
  ) : (
    <Loading />
  );
}
