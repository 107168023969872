import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useParams, useOutletContext } from "react-router-dom";
import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BaseForm from "../components/BaseForm";
import BaseInput from "../components/BaseInput";
import BaseInputFile from "../components/BaseInputFile";
import BaseLabel from "../components/BaseLabel";
import BasePopup from "../components/BasePopup";
import BasePopupConfirmDelete from "../components/BasePopupConfirmDelete";
import BaseRadio from "../components/BaseRadio";
import BaseSelect from "../components/BaseSelect";
import BaseTextArea from "../components/BaseTextArea";
import DoubleColumn from "../components/DoubleColumn";
import Loading from "../components/Loading";
import MemoSummary from "../components/MemoSummary";
import SectionWithBooleanTabs from "../components/SectionWithBooleanTabs";
import TaskSummary from "../components/TaskSummary";
import { useCheckTokenAndFetch } from "../auth";
import { File, PartnerAPI } from "./worksite";
import { toast } from "react-toastify";
import { useWorksites } from "./layout";

type Target = {
  "@id": string;
  "@type": string;
  companyName: string;
  firstname: string;
  lastname: string;
};

export type Memo = {
  id: number;
  createdAt: string;
  target?: Target;
  message: string;
  attachedFiles: Array<string>;
};

type MemoForm = {
  id?: number;
  createdAt: string;
  target?: string;
  message: string;
  attachedFiles: Array<File>;
};

export type Task = {
  id: number;
  target: Target;
  message: string;
  isDone: boolean;
  dueDate: string;
  hasEmailNotification: boolean;
  hasSMSNotification: boolean;
  attachedFiles: Array<string>;
};

type TaskForm = {
  id?: number;
  target: string;
  message: string;
  isDone: boolean;
  dueDate: string;
  hasEmailNotification: boolean;
  hasSMSNotification: boolean;
  attachedFiles: Array<File>;
};

type WorksiteTasksAndMemosType = {
  "@id": string;
  client: {
    "@id": string;
  };
  partners: Array<string>;
  tasks: Array<Task>;
  memos: Array<Memo>;
};

export type CountsType = {
  tasksCount: number;
  reportsCount: number;
};

export default function WorksiteTasksMemos() {
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [worksite, setWorksite] = React.useState<WorksiteTasksAndMemosType>();
  // const [worksites, setWorksites] = useWorksites();
  const [counts, setCounts] = useOutletContext() as [CountsType, React.Dispatch<React.SetStateAction<CountsType>>];
  const [partners, setPartners] = React.useState<Array<PartnerAPI>>();
  const [currentMemosAreClient, setCurrentMemosAreClient] =
    React.useState<boolean>(true);
  const [currentTasksAreInProgress, setCurrentTasksAreInProgress] =
    React.useState<boolean>(true);
  const [popup, setPopup] = React.useState<
    | {
        type: null;
      }
    | {
        type: "new" | "edit";
        itemType: "memos";
        item: MemoForm;
      }
    | {
        type: "new" | "edit";
        itemType: "tasks";
        item: TaskForm;
      }
    | {
        type: "delete";
        itemType: "memos" | "tasks";
        itemID: number;
      }
  >({ type: null });
  const [isClient, setIsClient] = React.useState<boolean>(true);

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/${params.id}/get_tasks_and_memos`, {
      method: "GET",
    }).then((worksite) => {
      setWorksite(worksite);
    });
    checkTokenAndFetch(`/api/partners/get_listing`, { method: "GET" }).then(
      (partners: { "hydra:member": Array<Target> }) => {
        setPartners(
          partners["hydra:member"]
          .map((partner) => ({
            label: `${partner.companyName} - ${partner.firstname} ${partner.lastname}`,
            value: partner["@id"],
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
        );
      }
    );
  }, [params.id]);
  React.useEffect(() => {
    if (worksite && (popup.type === "new" || popup.type === "edit")) {
      setIsClient(popup.item.target === worksite.client["@id"]);
    }
  }, [popup.type]);

  // React.useEffect(() => {
  //   setWorksites([
  //     ...worksites.map((w) => {
  //       if (w["@id"] === worksite?.["@id"]) {
  //         return {
  //           ...w,
  //           ...worksite,
  //         };
  //       }
  //       return w;
  //     }),
  //   ]);
  // }, [worksite]);

  React.useEffect(() => {
    setCounts({
      ...counts,
      tasksCount: worksite?.tasks
        .filter((task) => !task.isDone)
        .length ?? 0,
    });
  }, [worksite]);

  function popupForm() {
    if (
      worksite === undefined ||
      (popup.type !== "new" && popup.type !== "edit")
    ) {
      return null;
    }

    let formFields = null;
    const params = {
      memos: {
        new: {
          message: "L'information a bien été créée",
          title: "Nouvelle info",
          url: `/api/memos/post_new`,
        },
        edit: {
          message: "L'information a bien été mise à jour",
          title: "Modifier l'info",
          url: `/api/memos/${popup.item.id}/patch`,
        },
      },
      tasks: {
        new: {
          message: "La tâche a bien été créée",
          title: "Nouvelle tâche",
          url: `/api/tasks/post_new`,
        },
        edit: {
          message: "La tâche a bien été mise à jour",
          title: "Modifier la tâche",
          url: `/api/tasks/${popup.item.id}/patch`,
        },
      },
    };
    switch (popup.itemType) {
      case "memos":
        if (popup.item.target === "") {
          setPopup({
            ...popup,
            item: {
              ...popup.item,
              target: worksite.client["@id"],
            },
          });
          popup.item.target = worksite.client["@id"];
        }
        formFields = (
          <>
            {popup.type === "new" && (
              <div className="flex flex-row gap-6">
                <BaseRadio
                  className="shrink"
                  label="Client"
                  value={true}
                  currentValue={isClient}
                  onChange={() => {
                    setIsClient(true);
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        target: worksite.client["@id"],
                      },
                    });
                  }}
                />
                <BaseRadio
                  className="shrink"
                  label="Partenaire"
                  value={false}
                  currentValue={isClient}
                  onChange={() => {
                    setIsClient(false);
                    setPopup({
                      ...popup,
                      item: {
                        ...(popup.item as MemoForm),
                        target: undefined,
                      },
                    });
                  }}
                />
                {!isClient && (
                  <BaseSelect
                    placeholder="Tous"
                    value={popup.item.target}
                    options={
                      partners === undefined
                        ? []
                        : partners.filter((partner) =>
                            worksite.partners.includes(partner.value)
                          )
                    }
                    onChange={(event) => {
                      setPopup({
                        ...popup,
                        item: {
                          ...popup.item,
                          target:
                            event.currentTarget.value === ""
                              ? undefined
                              : event.currentTarget.value,
                        },
                      });
                    }}
                  />
                )}
              </div>
            )}
            <BaseLabel required label="Message">
              <BaseTextArea
                required
                rows={5}
                placeholder="Veuillez rentrer un message."
                value={popup.item.message}
                onChange={(event) => {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      message: event.currentTarget.value,
                    },
                  });
                }}
              />
            </BaseLabel>
            <BaseInputFile
              multiple
              value={popup.item.attachedFiles}
              onChange={(files) => {
                setPopup({
                  ...popup,
                  item: {
                    ...popup.item,
                    attachedFiles: files.map((file) => ({
                      name: file.name,
                      content: file.content,
                      createdAt: new Date().toISOString(),
                    })),
                  },
                });
              }}
            />
          </>
        );
        break;
      case "tasks":
        if (popup.item.target === "") {
          setPopup({
            ...popup,
            item: {
              ...popup.item,
              target: worksite.client["@id"],
            },
          });
          popup.item.target = worksite.client["@id"];
        }
        formFields = (
          <>
            {popup.type === "new" && (
              <div className="flex flex-row gap-6">
                <BaseRadio
                  className="shrink"
                  label="Client"
                  value={true}
                  currentValue={isClient}
                  onChange={() => {
                    setIsClient(true);
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        target: worksite.client["@id"],
                      },
                    });
                  }}
                />
                <BaseRadio
                  className="shrink"
                  label="Partenaire"
                  value={false}
                  currentValue={isClient}
                  onChange={() => {
                    setIsClient(false);
                  }}
                />
                {!isClient && (
                  <BaseSelect
                    required
                    value={popup.item.target}
                    options={
                      partners === undefined
                        ? []
                        : partners.filter((partner) =>
                            worksite.partners.includes(partner.value)
                          )
                    }
                    onChange={(event) => {
                      setPopup({
                        ...popup,
                        item: {
                          ...popup.item,
                          target: event.currentTarget.value,
                        },
                      });
                    }}
                  />
                )}
              </div>
            )}
            <BaseLabel required label="Message">
              <BaseTextArea
                required
                rows={5}
                placeholder="Veuillez rentrer un message."
                value={popup.item.message}
                onChange={(event) => {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      message: event.currentTarget.value,
                    },
                  });
                }}
              />
            </BaseLabel>
            <BaseInputFile
              multiple
              value={popup.item.attachedFiles}
              onChange={(files) => {
                setPopup({
                  ...popup,
                  item: {
                    ...popup.item,
                    attachedFiles: files.map((file) => ({
                      name: file.name,
                      content: file.content,
                      createdAt: new Date().toISOString(),
                    })),
                  },
                });
              }}
            />
            <div className="flex flex-row gap-5">
              <BaseLabel
                label="Email"
                className="shrink flex flex-row-reverse gap-4 w-auto"
              >
                <BaseInput
                  type="checkbox"
                  checked={popup.item.hasEmailNotification}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        hasEmailNotification: event.currentTarget.checked,
                      },
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel
                label="SMS"
                className="shrink flex flex-row-reverse gap-4 w-auto"
              >
                <BaseInput
                  type="checkbox"
                  checked={popup.item.hasSMSNotification}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        hasSMSNotification: event.currentTarget.checked,
                      },
                    });
                  }}
                />
              </BaseLabel>
            </div>
          </>
        );
        break;
    }

    return (
      <BasePopup
        title={params[popup.itemType][popup.type].title}
        isVisible={popup.type === "new" || popup.type === "edit"}
        className="min-w-[600px]"
        onClose={() => {
          setPopup({ type: null });
        }}
      >
        <BaseForm
          isNew={popup.type === "new"}
          url={params[popup.itemType][popup.type].url}
          body={
            popup.type === "new"
              ? {
                  ...popup.item,
                  worksite: worksite["@id"],
                  createdAt: new Date().toISOString(),
                }
              : popup.item
          }
          loadingSetter={setIsLoading}
          onSuccess={(result: Memo | Task) => {
            switch (popup.type) {
              case "new":
                setWorksite({
                  ...worksite,
                  [popup.itemType]: [...worksite[popup.itemType], result],
                });
                break;
              case "edit":
                setWorksite({
                  ...worksite,
                  [popup.itemType]: worksite[popup.itemType].map((item) => {
                    if (item.id === popup.item.id) {
                      return result;
                    }
                    return item;
                  }),
                });
                break;
            }
            setPopup({ type: null });
          }}
          successMessage={params[popup.itemType][popup.type].message}
        >
          <div className="flex flex-col gap-5">
            {formFields}
            <BaseButton
              type="submit"
              styleType="primary"
              content={popup.type === "new" ? "Ajouter" : "Enregistrer"}
              icon={
                isLoading
                  ? {
                      icon: faSpinner,
                      className: "animate-spin",
                    }
                  : undefined
              }
              className="self-end"
            />
          </div>
        </BaseForm>
      </BasePopup>
    );
  }

  return worksite !== undefined ? (
    <>
      <DoubleColumn
        config="lrr"
        left={
          <SectionWithBooleanTabs
            title={
              <div className="place-self-center grow flex items-center">
                <span>Infos envoyées</span>
                <div className="grow"></div>
                <BaseButton
                  styleType="primary"
                  type="button"
                  content="Nouvelle info"
                  onClick={() => {
                    setPopup({
                      itemType: "memos",
                      type: "new",
                      item: {
                        createdAt: new Date().toISOString(),
                        target: "",
                        message: "",
                        attachedFiles: [],
                      },
                    });
                  }}
                />
              </div>
            }
            array={worksite.memos ? worksite.memos.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            ): []}
            booleanCategory={currentMemosAreClient}
            setBooleanCategory={setCurrentMemosAreClient}
            initialCategory={(memo) => memo.target?.["@type"] === "Client"}
            trueCategory={{
              title: "Infos client",
            }}
            falseCategory={{
              title: "Infos partenaires",
            }}
            content={(memo) => (
              <MemoSummary
                memo={memo}
                onEdit={() => {
                  Promise.all(
                    memo.attachedFiles.map((file) =>
                      checkTokenAndFetch(file, { method: "GET" })
                    )
                  ).then((files: Array<File>) => {
                    setPopup({
                      type: "edit",
                      itemType: "memos",
                      item: {
                        ...memo,
                        target: memo.target?.["@id"],
                        attachedFiles: files,
                      },
                    });
                  });
                }}
                onDelete={() => {
                  setPopup({
                    type: "delete",
                    itemType: "memos",
                    itemID: memo.id,
                  });
                }}
              />
            )}
          />
        }
        right={
          <SectionWithBooleanTabs
            title={
              <div className="place-self-center grow flex items-center">
                <span>Tâches</span>
                {/* <Badge
                  value={tasksCount}
                  type="primary"
                /> */}
                <div className="grow"></div>
                <BaseButton
                  styleType="primary"
                  type="button"
                  content="Nouvelle tâche"
                  onClick={() => {
                    setPopup({
                      itemType: "tasks",
                      type: "new",
                      item: {
                        target: "",
                        message: "",
                        isDone: false,
                        dueDate: new Date().toISOString().slice(0, 10),
                        hasEmailNotification: false,
                        hasSMSNotification: false,
                        attachedFiles: [],
                      },
                    });
                  }}
                />
              </div>
            }
            array={worksite.tasks ? worksite.tasks.sort(
              (a, b) =>
                new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
            ): []}
            booleanCategory={currentTasksAreInProgress}
            setBooleanCategory={setCurrentTasksAreInProgress}
            initialCategory={(task) => !task.isDone}
            trueCategory={{
              title: "Tâches en cours",
              value: counts.tasksCount,
            }}
            falseCategory={{
              title: "Tâches terminées",
              value: worksite.tasks.filter((task) => task.isDone).length,
            }}
            content={(task) => (
              <TaskSummary
                task={task}
                onEdit={() => {
                  Promise.all(
                    task.attachedFiles.map((file) =>
                      checkTokenAndFetch(file, { method: "GET" })
                    )
                  ).then((files: Array<File>) => {
                    setPopup({
                      type: "edit",
                      itemType: "tasks",
                      item: {
                        ...task,
                        target: task.target["@id"],
                        attachedFiles: files,
                      },
                    });
                  });
                }}
                onDelete={() => {
                  setPopup({
                    type: "delete",
                    itemType: "tasks",
                    itemID: task.id,
                  });
                }}
                onToggleState={() => {
                  task.isDone = !task.isDone;
                  setWorksite({
                    ...worksite,
                  });
                }}
              />
            )}
          />
        }
      />
      {popup.type === "delete" && (
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={popup.type === "delete"}
          onClose={() => {
            setPopup({ type: null });
          }}
          onSuccess={() => {
            switch (popup.itemType) {
              case "memos":
                setWorksite({
                  ...worksite,
                  memos: worksite.memos.filter(
                    (memo) => memo.id !== popup.itemID
                  ),
                });
                toast.success("L'information a bien été supprimée");
                break;
              case "tasks":
                setWorksite({
                  ...worksite,
                  tasks: worksite.tasks.filter(
                    (task) => task.id !== popup.itemID
                  ),
                });
                toast.success("La tâche a bien été supprimée");
                break;
            }
          }}
          url={`/api/${popup.itemType}/${popup.itemID}`}
        >
          Etes-vous sûr de vouloir supprimer cet élément ? Cette opération est
          irréversible.
        </BasePopupConfirmDelete>
      )}
      {popupForm()}
    </>
  ) : (
    <Loading />
  );
}
