import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useParams } from "react-router-dom";
import Amount from "../components/Amount";
import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BaseTextArea from "../components/BaseTextArea";
import Card from "../components/Card";
import CardContent from "../components/CardContent";
import DoubleColumn from "../components/DoubleColumn";
import Loading from "../components/Loading";
import MemoSummary from "../components/MemoSummary";
import Section from "../components/Section";
import TaskSummary, { Task } from "../components/TaskSummary";
import WorksiteLocation from "../components/WorksiteLocation";
import { useCheckTokenAndFetch } from "../auth";
import { Memo } from "./worksiteTasksMemos";
import { toast } from "react-toastify";

type Invoice = {
  id: number;
  totalAmountIncludingTaxes: number;
  paidAmountIncludingTaxes: number;
};

type Quotation = {
  id: number;
  amountIncludingTaxes: number;
  amountExcludingTaxes: number;
  isSigned: boolean;
};

type WorksiteSummaryType = {
  locationLatitude: number;
  locationLongitude: number;
  description: string;
  memos: Array<Memo>;
  tasks: Array<Task>;
  invoices: Array<Invoice>;
  quotations: Array<Quotation>;
};

export default function WorksiteDashboard() {
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [worksite, setWorksite] = React.useState<WorksiteSummaryType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>("");

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/${params.id}/get_summary`, {
      method: "GET",
    }).then((data) => {
      setWorksite(data.worksite);
      setDescription(data.worksite.description);
      setWorksite({
        ...data.worksite,
        invoices: data.invoices,
        quotations: data.quotations,
      });
    });
  }, [params.id]);
  function totalQuotations() {
    const result = {
      totalIncludingTaxes: 0,
      totalExcludingTaxes: 0,
      signedIncludingTaxes: 0,
    };

    if (worksite !== undefined && worksite.quotations !== undefined) {
      worksite.quotations.forEach((quotation) => {
        result.totalIncludingTaxes += quotation.amountIncludingTaxes;
        result.totalExcludingTaxes += quotation.amountExcludingTaxes;
        if (quotation.isSigned) {
          result.signedIncludingTaxes += quotation.amountIncludingTaxes;
        }
      });
    }

    return result;
  }
  function totalInvoices() {
    const result = {
      paidIncludingTaxes: 0,
      remainingIncludingTaxes: 0,
    };

    if (worksite !== undefined && worksite.invoices !== undefined) {
      worksite.invoices.forEach((invoice) => {
        result.paidIncludingTaxes += invoice.paidAmountIncludingTaxes;
        result.remainingIncludingTaxes +=
          invoice.totalAmountIncludingTaxes - invoice.paidAmountIncludingTaxes;
      });
    }

    return result;
  }

  return worksite !== undefined ? (
    <DoubleColumn
      left={
        <>
          <WorksiteLocation
            position={{
              lat: worksite.locationLatitude,
              lng: worksite.locationLongitude,
            }}
          />
          <Section title="Résumé financier">
            <Card className="max-h-max">
              <Amount
                title="Global"
                type="positive"
                large
                amountIncludingTaxes={totalQuotations().totalIncludingTaxes}
                amountExcludingTaxes={totalQuotations().totalExcludingTaxes}
              />
              <Amount
                title="Devis signés"
                type="neutral"
                large
                amountIncludingTaxes={totalQuotations().signedIncludingTaxes}
              />
              <Amount
                title="Montant réglé"
                type="neutral"
                large
                amountIncludingTaxes={totalInvoices().paidIncludingTaxes}
              />
              <Amount
                title="Reste à régler"
                type="negative"
                large
                amountIncludingTaxes={totalInvoices().remainingIncludingTaxes}
              />
            </Card>
          </Section>
        </>
      }
      right={
        <>
          <Section title="Note OMDO">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                setIsLoading(true);
                checkTokenAndFetch(
                  `/api/worksites/${params.id}/patch_description`,
                  {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                    method: "PATCH",
                    body: JSON.stringify({ description: description }),
                  }
                )
                  .then(() => {
                    setWorksite({
                      ...worksite,
                      description: description,
                    });
                    toast.success(
                      "la note OMDO du chantier a bien été mise à jour"
                    );
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              <BaseTextArea
                rows={5}
                placeholder="Pas de note pour le moment"
                value={description}
                onChange={(event) => {
                  setDescription(event.currentTarget.value);
                }}
              />
              <BaseButton
                type="submit"
                styleType="primary"
                content="Enregistrer"
                icon={
                  isLoading
                    ? {
                        icon: faSpinner,
                        className: "animate-spin",
                      }
                    : undefined
                }
              />
            </form>
          </Section>
          <Section title="Informations">
            <Card>
              {worksite.memos
                .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                .map((memo, mIndex) => (
                  <CardContent key={mIndex}>
                    {[
                      <MemoSummary
                        key={0}
                        memo={memo}
                        link={`../taches-infos#memo-${memo.id}`}
                      />,
                    ]}
                  </CardContent>
                ))
              }
            </Card>
          </Section>
          <Section
            title={
              <>
                Tâches en cours
                <Badge
                  value={worksite.tasks.filter((task) => !task.isDone).length}
                  type="primary"
                />
              </>
            }
          >
            <Card>
              {worksite.tasks
                .filter((task) => !task.isDone)
                .sort((a, b) => b.dueDate.localeCompare(a.dueDate))
                .map((task, tIndex) => (
                  <CardContent key={tIndex}>
                    {[
                      <TaskSummary
                        key={tIndex}
                        link={`../taches-infos#task-${task.id}`}
                        task={task}
                        onToggleState={() => {
                          task.isDone = !task.isDone;
                          setWorksite({
                            ...worksite,
                          });
                        }}
                      />,
                    ]}
                  </CardContent>
                ))}
            </Card>
          </Section>
        </>
      }
    />
  ) : (
    <Loading />
  );
}
