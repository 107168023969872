import React from "react";
import {
  faFileCsv,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import BaseButton from "./BaseButton";
import BaseInput from "./BaseInput";
import BasePopup from "./BasePopup";
import LotsPhasesListLot, { Lot } from "./LotsPhasesListLot";
import LotsPhasesListPhase, { Phase } from "./LotsPhasesListPhase";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

type Target = {
  label: string;
  value: string;
};

type LotsPhasesListProps = {
  worksiteName?: string;
  worksite: string | undefined;
  lots: Array<Lot>;
  handleNewLot: (newLot: Omit<Lot, "@id" | "id">) => Promise<void | Response>;
  handleEditLot: (lot: Lot) => Promise<void | Response>;
  handleDeleteLot: (id: number) => Promise<void | Response>;
  phases: Array<Phase>;
  handleNewPhase: (newPhase: Omit<Phase, "id">) => Promise<void | Response>;
  handleEditPhase: (lot: Phase) => Promise<void | Response>;
  handleDeletePhase: (id: number) => Promise<void | Response>;
  targets?: Array<Target>;
};

export default function LotsPhasesList(props: LotsPhasesListProps) {
  const [beginDate, setBeginDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");
  const [isNewLotLoading, setIsNewLotLoading] = React.useState<boolean>(false);
  const [isNewPhaseLoading, setIsNewPhaseLoading] =
    React.useState<boolean>(false);
  const [isGeneratePopupActive, setIsGeneratePopupActive] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    let begin = Infinity;
    let end = -Infinity;

    props.phases.forEach((phase) => {
      if (phase.beginDate) {
        const date = new Date(phase.beginDate);
        begin = Math.min(begin, date.getTime());
        date.setDate(date.getDate() + phase.duration);
        end = Math.max(end, date.getTime());
      }
    });

    if (begin !== Infinity && end !== Infinity) {
      setBeginDate(new Date(begin).toISOString().slice(0, 10));
      setEndDate(new Date(end).toISOString().slice(0, 10));
    }
  }, [props.phases]);
  function onDrop(result: DropResult) {
    if (!result.destination) {
      return;
    }

    let removed = null;
    switch (result.type) {
      case "lot":
        {
          const lotsClone: Array<Lot> = JSON.parse(JSON.stringify(props.lots));
          [removed] = lotsClone.splice(result.source.index, 1);
          lotsClone.splice(result.destination.index, 0, removed);
          Promise.all(
            lotsClone.map((lot, lIndex) => {
              props.handleEditLot({
                ...lot,
                position: lIndex,
              });
            })
          ).then(() => {
            toast.success("Le lot a bien été déplacé");
          });
        }
        break;
      case "phase":
        {
          const phasesClone: Array<Phase> = JSON.parse(
            JSON.stringify(props.phases)
          );
          [removed] = phasesClone.splice(result.source.index, 1);
          phasesClone.splice(result.destination.index, 0, removed);
          Promise.all(
            phasesClone.map((phase, pIndex) => {
              props.handleEditPhase({
                ...phase,
                position: pIndex,
              });
            })
          ).then(() => {
            toast.success("La phase a bien été déplacée");
          });
        }
        break;
    }
  }
  function reGenerate() {
    let current = beginDate;
    if (current) {
      Promise.all(
        props.phases
          .sort((a, b) => a.position - b.position)
          .map((phase) => {
            const newPhase: Phase = {
              ...phase,
              beginDate: current,
            };
            const date = new Date(current);
            date.setDate(date.getDate() + phase.duration);
            current = date.toISOString().slice(0, 10);
            return props.handleEditPhase(newPhase);
          })
      ).then(() => {
        setEndDate(current);
        setIsGeneratePopupActive(false);
        toast.success("Les dates de début ont bien été mises à jour");
      });
    } else {
      setIsGeneratePopupActive(false);
    }
  }
  function csv() {
    let content = "";
    const separator = ";";
    const headersRow = [];
    headersRow.push("Lot");
    headersRow.push("Phase");
    headersRow.push("Date de début");
    headersRow.push("Durée");
    headersRow.push("Partenaire");
    content += headersRow.join(separator);
    content += "\r\n";
    props.phases
      .sort((a, b) => a.position - b.position)
      .forEach((phase) => {
        const phaseRow = [];
        phaseRow.push(props.lots.find((lot) => lot["@id"] === phase.lot)?.name);
        phaseRow.push(phase.name);
        phaseRow.push(
          phase.beginDate ? new Date(phase.beginDate).toLocaleDateString() : ""
        );
        phaseRow.push(phase.duration);
        const target = props.targets?.find(
          (target) => target.value === phase.target
        );
        if (target) {
          phaseRow.push(target.label);
        }
        content += phaseRow.join(separator);
        content += "\r\n";
      });
    return (
      "data:text/csv;base64," +
      window.btoa(unescape(encodeURIComponent("\ufeff" + content)))
    );
  }

  return (
    <>
      {props.targets && (
        <div className="flex items-center mb-5 gap-5">
          <div className="rounded bg-green-1 bg-opacity-20 p-5">
            <label className="flex items-center">
              <span className="text-green-3 font-bold pr-12">
                Début du chantier
              </span>
              <BaseInput
                type="date"
                value={beginDate}
                onChange={(event) => {
                  setBeginDate(event.currentTarget.value);
                }}
                className="w-min"
              />
            </label>
          </div>
          <div className="rounded bg-white p-5">
            <label className="flex items-center font-bold">
              <span className="pr-12">Fin prévue</span>
              {endDate ? (
                new Date(endDate).toLocaleDateString()
              ) : (
                <>Dates à générer</>
              )}
            </label>
          </div>
        </div>
      )}
      <div className="flex mb-3 gap-3">
        {!useLocation().pathname.includes("/chantiers/ajouter/lots-phases") && (
          <>
            <BaseButton
              type="button"
              styleType="primary"
              content="Ajouter un lot"
              icon={
                isNewLotLoading
                  ? {
                      icon: faSpinner,
                      className: "animate-spin",
                    }
                  : {
                      icon: faPlus,
                    }
              }
              onClick={() => {
                setIsNewLotLoading(true);
                props
                  .handleNewLot({
                    name: "Nouveau lot",
                    position:
                      Math.max(...props.lots.map((lot) => lot.position), 0) + 1,
                    color: "#000000",
                    worksite: props.worksite ? props.worksite : undefined,
                  })
                  .then(() => {
                    toast.success("Le lot a bien été créé");
                  })
                  .finally(() => {
                    setIsNewLotLoading(false);
                  });
              }}
            />
            <BaseButton
              disabled={!props.lots.length || (props.targets && !beginDate)}
              title={
                !props.lots.length
                  ? "Impossible d'ajouter une phase si aucun lot n'est disponible"
                  : props.targets && !beginDate
                  ? "Impossible d'ajouter une phase sans date de début de chantier"
                  : undefined
              }
              type="button"
              styleType="primary"
              content="Ajouter une phase"
              icon={
                isNewPhaseLoading
                  ? {
                      icon: faSpinner,
                      className: "animate-spin",
                    }
                  : {
                      icon: faPlus,
                    }
              }
              onClick={() => {
                setIsNewPhaseLoading(true);
                props
                  .handleNewPhase({
                    name: "Nouvelle phase",
                    position:
                      Math.max(...props.phases.map((phase) => phase.position), 0) +
                      1,
                    duration: 1,
                    lot: props.lots[0]["@id"] || "",
                    beginDate: endDate ? endDate : undefined,
                  })
                  .then(() => {
                    toast.success("La phase a bien été créée");
                  })
                  .finally(() => {
                    setIsNewPhaseLoading(false);
                  });
              }}
            />
          </>
        )}
        {props.targets && props.worksiteName && (
          <>
            <BaseButton
              type="submit"
              ref={(ref) => {
                if (
                  ref &&
                  !props.phases.some((phase) => {
                    if (!phase.beginDate) {
                      ref.setCustomValidity(
                        "Veuillez générer les dates avant de continuer."
                      );
                      return true;
                    }
                    return false;
                  })
                ) {
                  ref.setCustomValidity("");
                }
              }}
              styleType="secondary"
              content={
                props.phases.some((phase) => phase.beginDate === undefined)
                  ? "Générer les dates"
                  : "Re-générer les dates"
              }
              onClick={(event) => {
                event.preventDefault();
                setIsGeneratePopupActive(true);
              }}
            />
            <div className="grow"></div>
            {!useLocation().pathname.includes("/chantiers/ajouter/lots-phases") && (
              <a href={csv()} download={`${props.worksiteName}_lots-et-phases`}>
                <BaseButton
                  type="button"
                  styleType="csv"
                  content="Export CSV"
                  icon={{ icon: faFileCsv }}
                  onClick={() => {
                    return;
                  }}
                />
              </a>
            )}
          </>
        )}
      </div>
      {!useLocation().pathname.includes("/chantiers/ajouter/lots-phases") && (
        <DragDropContext onDragEnd={onDrop}>
          <div className="flex flex-col gap-8">
            <Droppable droppableId="lots" type="lot">
              {(drop) => (
                <div ref={drop.innerRef}>
                  {props.lots
                    .sort((a, b) => a.position - b.position)
                    .map((lot, lIndex) => (
                      <LotsPhasesListLot
                        key={lIndex}
                        index={lIndex}
                        lot={lot}
                        handleDelete={props.handleDeleteLot}
                        handleEdit={props.handleEditLot}
                      />
                    ))}
                  {drop.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="phases" type="phase">
              {(drop) => (
                <table ref={drop.innerRef} className="w-full table-auto">
                  <tbody>
                    {props.phases
                      .sort((a, b) => a.position - b.position)
                      .map((phase, pIndex) => (
                        <LotsPhasesListPhase
                          key={pIndex}
                          index={pIndex}
                          phase={phase}
                          lots={props.lots}
                          targets={props.targets}
                          handleDelete={props.handleDeletePhase}
                          handleEdit={props.handleEditPhase}
                        />
                      ))}
                    {drop.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      )}
      <BasePopup
        title="Générer les dates"
        isVisible={isGeneratePopupActive}
        onClose={() => {
          setIsGeneratePopupActive(false);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            reGenerate();
          }
        }}
      >
        <div className="text-center bg-grey-7 rounded p-6">
          <h1 className="font-bold">Attention !</h1>
          Vous vous apprêtez à re-générer les dates. Toutes vos précédentes
          modifications seront réinitialisées.
        </div>
        <div className="mt-4 floa">
          <div className="flex justify-end gap-6">
            <BaseButton
              type="button"
              styleType="cancel"
              content="Annuler"
              onClick={() => {
                setIsGeneratePopupActive(false);
              }}
            />
            <BaseButton
              type="button"
              styleType="primary"
              content="Continuer"
              onClick={() => {
                reGenerate();
              }}
            />
          </div>
        </div>
      </BasePopup>
    </>
  );
}
