import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {
  matchPath,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "../auth";
import { ClientInput } from "../components/ClientsNewForm";
import Page from "../components/Page";
import Tabs from "../components/Tabs";
import { useCheckTokenAndFetch } from "../auth";
import { toast } from "react-toastify";
import { Lot } from "../components/LotsPhasesListLot";
import { Phase } from "../components/LotsPhasesListPhase";

export type Alert = {
  name: string;
  description: string;
  hasClientAlert: boolean;
  dueDate: string;
  recallDate?: string;
};

export type File = {
  "@id"?: string;
  id?: number;
  name: string;
  createdAt: string;
  content?: string;
  alerts: Array<Alert>;
  startDate?: string;
  endDate?: string;
};

export type FilesCategory = {
  "@id"?: string;
  id?: number;
  name: string;
  roles: Array<string>;
  files: Array<File>;
};

export type Worksite = {
  client: string | ClientInput;
  name: string;
  cadastralSection: string;
  address: string;
  zipCode: string;
  city: string;
  locationLatitude: number;
  locationLongitude: number;
  partners: Array<string>;
  filesCategories: Array<FilesCategory>;
  lots: Array<Lot>;
  phases: Array<Phase>;
};

type PartnerAPI = {
  label: string;
  value: string;
};

type Partner = {
  "@id": string;
  id: number;
  companyName: string;
  firstname: string;
  lastname: string;
  category: {
    name: string;
  };
};

const NewWorksiteContext = React.createContext<{
  worksite: [Worksite, React.Dispatch<React.SetStateAction<Worksite>>];
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  partners: Array<PartnerAPI>;
}>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

export function useNewWorksite() {
  return React.useContext(NewWorksiteContext);
}

export default function WorksitesNew() {
  const formRef = React.useRef<HTMLFormElement | null>(null);
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [partners, setPartners] = React.useState<Array<PartnerAPI>>([]);
  const [newWorksite, setNewWorksite] = React.useState<Worksite>({
    client: "",
    name: "",
    cadastralSection: "",
    address: "",
    zipCode: "",
    city: "",
    locationLatitude: 0,
    locationLongitude: 0,
    partners: [],
    filesCategories: [
      {
        name: "Permis de construire",
        roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
        files: [],
      },
      {
        name: "Devis et contrats OMDO",
        roles: ["ROLE_CLIENT"],
        files: [],
      },
      {
        name: "Etudes",
        roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
        files: [],
      },
      {
        name: "Divers",
        roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
        files: [],
      },
      {
        name: "Fiches produits",
        roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
        files: [],
      },
      {
        name: "Plans Exécution",
        roles: ["ROLE_CLIENT", "ROLE_PARTNER"],
        files: [],
      },
      {
        name: "Dommage ouvrage",
        roles: ["ROLE_CLIENT"],
        files: [],
      },
    ],
    lots: [],
    phases: [],
  });

  React.useEffect(() => {
    checkTokenAndFetch(`/api/default_lots/get_listing`, { method: "GET" }).then(
      (lots: { "hydra:member": Array<Lot> }) => {
        setNewWorksite((oldWorksite) => ({
          ...oldWorksite,
          lots: lots["hydra:member"].map((lot) => ({
            ...lot,
            "@id": `${lot.id}`,
          })),
        }));
      }
    );
    checkTokenAndFetch(`/api/default_phases/get_listing`, {
      method: "GET",
    }).then((phases: { "hydra:member": Array<Phase> }) => {
      setNewWorksite((oldWorksite) => ({
        ...oldWorksite,
        phases: phases["hydra:member"].map((phase) => {
          const lotSplit = phase.lot.split("/");
          return {
            ...phase,
            lot: lotSplit[lotSplit.length - 1],
          };
        }),
      }));
    });
    checkTokenAndFetch(`/api/partners/get_listing`, { method: "GET" }).then(
      (partners: { "hydra:member": Array<Partner> }) => {
        setPartners(
          partners["hydra:member"].map((partner) => ({
            label: `${partner.companyName} - ${partner.firstname} ${partner.lastname}`,
            value: partner["@id"],
          }))
        );
      }
    );
  }, [auth]);

  return matchPath("chantiers/ajouter", location.pathname) ? (
    <Navigate replace to="client" />
  ) : (
    <Page icon={{ icon: faBuilding }} title="Nouveau chantier">
      <Tabs
        formRef={formRef}
        items={[
          { to: "client", content: "Clients" },
          { to: "infos", content: "Chantier" },
          { to: "documents", content: "Documents" },
          { to: "lots-phases", content: "Lots/Phases" },
        ]}
        onSubmit={() =>
          checkTokenAndFetch(`/api/worksites/post_new`, {
            headers: {
              "Content-Type": "application/ld+json",
            },
            method: "POST",
            body: JSON.stringify(newWorksite),
          }).then(async (createdWorksite) => {
            const lotsIDs: {
              [key: string]: string;
            } = {};
            await Promise.all(
              newWorksite.lots.map((lot) => checkTokenAndFetch(`/api/lots/post_new`, {
                headers: {
                  "Content-Type": "application/ld+json",
                },
                method: "POST",
                body: JSON.stringify({
                  ...lot,
                  "@id": undefined,
                  id: undefined,
                  worksite: createdWorksite["@id"],
                }),
              }).then((createdLot: Lot) => {
                lotsIDs[lot["@id"]] = createdLot["@id"];
              })
              )
            );
            await Promise.all(
              newWorksite.phases.map((phase) => checkTokenAndFetch(`/api/phases/post_new`, {
                headers: {
                  "Content-Type": "application/ld+json",
                },
                method: "POST",
                body: JSON.stringify({
                  ...phase,
                  "@id": undefined,
                  id: undefined,
                  lot: lotsIDs[phase.lot],
                }),
              })
              )
            );
            navigate("/chantiers");
            toast.success("Le chantier a bien été créé");
            return createdWorksite;
          })
        }
      >
        <NewWorksiteContext.Provider
          value={{
            worksite: [newWorksite, setNewWorksite],
            formRef: formRef,
            partners: partners,
          }}
        >
          <Outlet />
        </NewWorksiteContext.Provider>
      </Tabs>
    </Page>
  );
}
