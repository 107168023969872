import { twMerge } from "tailwind-merge";
import React from "react";
import { currencyFormat } from "../utils";

type AmountProps = {
  title: string;
  type: "neutral" | "positive" | "negative";
  amountIncludingTaxes: number;
  amountExcludingTaxes?: number;
  withTaxes?: boolean;
  large?: boolean;
};

export default function Amount(props: AmountProps) {
  function getTaxes() {
    return props.amountExcludingTaxes
      ? currencyFormat.format(
          props.amountIncludingTaxes - props.amountExcludingTaxes
        )
      : null;
  }
  function getStyle() {
    switch (props.type) {
      case "neutral":
        return props.large ? "bg-grey-7" : "bg-white";
      case "positive":
        return "bg-green-1 bg-opacity-20 text-green-3";
      case "negative":
        return "bg-red-2 bg-opacity-20 text-red-2";
    }
  }

  return (
    <div
      className={twMerge(
        "flex rounded-md py-4",
        props.large ? "px-11" : "px-4",
        getStyle()
      )}
    >
      <h3 className="font-extrabold mb-5">{props.title}</h3>
      <div className="grow"></div>
      <div
        className={twMerge(
          "flex flex-col items-end font-bold w-full",
          props.large ? " text-xl" : " text-sm"
        )}
      >
        {props.amountExcludingTaxes && (
          <span>{currencyFormat.format(props.amountExcludingTaxes)} HT</span>
        )}
        {props.withTaxes && <span>{getTaxes()} TVA</span>}
        <span>{currencyFormat.format(props.amountIncludingTaxes)} TTC</span>
      </div>
    </div>
  );
}
