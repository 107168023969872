import React from "react";
import DoubleColumn from "../components/DoubleColumn";
import WorksiteBaseInfos from "../components/WorksiteBaseInfos";
import WorksiteLocation, { Position } from "../components/WorksiteLocation";
import WorksitePartners from "../components/WorksitePartners";
import { useNewWorksite } from "./worksitesNew";

export default function WorksitesNewInfos() {
  const context = useNewWorksite();
  const [newWorksite, setNewWorksite] = context.worksite;
  const partners = context.partners;
  const formRef = context.formRef;

  return (
    <DoubleColumn
      left={
        <>
          <WorksiteBaseInfos
            worksite={newWorksite}
            formRef={formRef}
            onChange={(infos) => {
              setNewWorksite({
                ...newWorksite,
                ...infos,
              });
            }}
            onLocalize={() =>
              fetch(
                "https://nominatim.openstreetmap.org/search?" +
                  new URLSearchParams({
                    q: [
                      newWorksite.address,
                      newWorksite.zipCode,
                      newWorksite.city,
                    ].join(" "),
                    format: "json",
                    polygon: "1",
                    addressdetails: "1",
                  }),
                {
                  headers: {
                    Accept: "application/json",
                  },
                  method: "GET",
                }
              )
                .then((resp) => resp.json())
                .then((json) => {
                  if (json.length) {
                    setNewWorksite({
                      ...newWorksite,
                      locationLatitude: +json[0].lat,
                      locationLongitude: +json[0].lon,
                    });
                  }
                })
            }
          />
          {/* <WorksitePartners
            partners={newWorksite.partners}
            partnersListing={partners}
            onDelete={(pID: string) =>
              new Promise<void>((resolve) => {
                setNewWorksite({
                  ...newWorksite,
                  partners: newWorksite.partners.filter((item) => item !== pID),
                });
                resolve();
              })
            }
            onAdd={(pID: string) =>
              new Promise<void>((resolve) => {
                setNewWorksite({
                  ...newWorksite,
                  partners: [...newWorksite.partners, pID],
                });
                resolve();
              })
            }
          /> */}
        </>
      }
      right={
        <WorksiteLocation
          position={{
            lat: newWorksite.locationLatitude,
            lng: newWorksite.locationLongitude,
          }}
          setPosition={(pos: Position) =>
            new Promise<void>((resolve) => {
              setNewWorksite({
                ...newWorksite,
                locationLatitude: pos.lat,
                locationLongitude: pos.lng,
              });
              resolve();
            })
          }
        />
      }
    />
  );
}
