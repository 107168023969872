import React from "react";
import { twMerge } from "tailwind-merge";
import {
  faBriefcase,
  faBuilding,
  faCog,
  faColumns,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import TheSidebarNavLink from "./TheSidebarNavLink";
import { useWorksites } from "../routes/layout";
import TheSidebarNavLinkSubLink from "./TheSidebarNavLinkSubLink";

type TheSidebarProps = {
  className?: string;
};

export default function TheSidebar(props: TheSidebarProps) {
  const [worksites] = useWorksites();

  return (
    <div
      className={twMerge(
        "w-64 flex flex-col bg-darkgrey text-white",
        props.className
      )}
    >
      <TheSidebarNavLink to="/" content="Tableau de bord" icon={faColumns} />
      <TheSidebarNavLink to="/chantiers" content="Chantiers" icon={faBuilding}>
        {worksites
          .filter((worksite) => worksite.state !== 3)
          .map((worksite, wIndex) => (
            <TheSidebarNavLinkSubLink
              key={wIndex}
              to={`/chantiers/${worksite.id}`}
              content={worksite.name + (worksite.state === 2 ? " (SB)" : "")}
            />
          ))}
      </TheSidebarNavLink>
      <TheSidebarNavLink to="/clients" content="Clients" icon={faUser} />
      <TheSidebarNavLink
        to="/partenaires"
        content="Partenaires"
        icon={faBriefcase}
      />
      <TheSidebarNavLink
        notClickable
        to="/configuration"
        content="Configuration"
        icon={faCog}
      >
        <>
          <TheSidebarNavLinkSubLink
            to="/configuration/lots-phases"
            content="Lots / Phases"
          />
          <TheSidebarNavLinkSubLink
            to="/configuration/administrateurs"
            content="Administrateurs"
          />
        </>
      </TheSidebarNavLink>
    </div>
  );
}
