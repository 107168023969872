import React from "react";
import { twMerge } from "tailwind-merge";
import { NavLink } from "react-router-dom";

type TabsItemProps = {
  to: string;
  content: React.ReactNode;
  disabled?: boolean;
};

export default function TabsItem(props: TabsItemProps) {
  return (
    <NavLink
      onClick={(event) => {
        props.disabled && event.preventDefault();
      }}
      className={({ isActive }) =>
        twMerge(
          "px-5 pb-2 text-xl font-extrabold border-b-4 grow text-center",
          isActive
            ? "text-green-1 border-green-1"
            : props.disabled
            ? "text-grey-4 border-grey-4"
            : "text-grey-2 border-grey-2",
          props.disabled
            ? "cursor-not-allowed"
            : "hover:text-green-2 hover:border-green-2"
        )
      }
      to={props.to}
    >
      {props.content}
    </NavLink>
  );
}
