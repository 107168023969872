import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import BaseButton from "./BaseButton";
import TabsItem from "./TabsItem";

type TabsProps = {
  items: Array<{
    to: string;
    content: React.ReactNode;
    onSubmit?: () => void;
  }>;
  children: React.ReactNode;
  onSubmit?: () => Promise<Response>;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
};

export default function Tabs(props: TabsProps) {
  const [maxStep, setMaxStep] = React.useState<number>(0);
  const [currentStep, setCurrentStep] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbButtons = () => {
    if (currentStep !== undefined) {
      return (
        <div className="flex flex-row gap-3">
          <BaseButton
            type="button"
            styleType="cancel"
            content={currentStep === 0 ? "Annuler" : "Etape précédente"}
            onClick={() => {
              if (currentStep === 0) {
                navigate("/chantiers");
              } else {
                navigate(props.items[currentStep - 1].to);
              }
            }}
          />
          <BaseButton
            type={currentStep === props.items.length - 1 ? "submit" : "button"}
            styleType="primary"
            content={
              currentStep === props.items.length - 1
                ? "Terminer"
                : "Etape suivante"
            }
            icon={
              isLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                  }
                : undefined
            }
            onClick={() => {
              if (
                !props.formRef?.current ||
                props.formRef?.current?.reportValidity()
              ) {
                const submit = props.items[currentStep].onSubmit;
                if (submit) {
                  submit();
                }
                if (currentStep === props.items.length - 1) {
                  if (props.onSubmit) {
                    setIsLoading(true);
                    props.onSubmit().finally(() => {
                      setIsLoading(false);
                    });
                  }
                } else {
                  setMaxStep(Math.max(maxStep, currentStep + 1));
                  navigate(props.items[currentStep + 1].to);
                }
              }
            }}
          />
        </div>
      );
    }
    return null;
  };

  React.useEffect(() => {
    if (props.onSubmit) {
      for (let i = 0; i < props.items.length; i++) {
        if (
          matchPath(
            location.pathname.split("/").slice(0, -1).join("/") +
              "/" +
              props.items[i].to +
              "/*",
            location.pathname
          )
        ) {
          if (i > maxStep) {
            if (props.items.length) {
              navigate(props.items[0].to);
            } else {
              navigate("/");
            }
          }
          setCurrentStep(i);
          break;
        }
      }
    }
  }, [location]);

  return (
    <div className="flex flex-col">
      <div className="w-full flex mb-6 justify-between">
        {props.items.map((item, index) => (
          <TabsItem
            key={index}
            to={item.to}
            content={item.content}
            disabled={props.onSubmit ? index > maxStep : false}
          />
        ))}
        {props.onSubmit && <div className="ml-7">{breadcrumbButtons()}</div>}
      </div>
      {props.children}
      {props.onSubmit && (
        <div className="mx-auto mt-14">{breadcrumbButtons()}</div>
      )}
    </div>
  );
}
