import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LoginForm from "../components/LoginForm";
import LoginFormInput from "../components/LoginFormInput";

export default function ResetPasswordRequest() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<number>();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;

    fetch(
      `${window.location.origin}:8000/api/abstract_users/${email}/reset_password_request`
    )
      .then((resp) => {
        setStatus(resp.status);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function renderDescription(): string {
    switch (status) {
      case 204:
        return "Un e-mail a été envoyé. Il contient un lien sur lequel il vous faudra cliquer pour réinitialiser votre mot de passe. Si vous ne recevez pas l'email, vérifiez votre dossier spam ou essayez à nouveau.";

      case 404:
        return "Cet email n'est pas associé à un compte OMDO.";

      default:
        return "Saisissez votre adresse mail associée afin de créer un nouveau mot de passe";
    }
  }

  return (
    <LoginForm
      handleSubmit={handleSubmit}
      title="Modifier le mot de passe"
      subTitle={renderDescription()}
      submit={
        status !== 204
          ? {
              content: "Réinitialiser le mot de passe",
              icon: isLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                  }
                : undefined,
            }
          : undefined
      }
      link={
        status !== 204
          ? {
              to: "/connexion",
              content: "Retour à la page de connexion",
            }
          : undefined
      }
    >
      <LoginFormInput label="Adresse email" name="email" type="email" />
    </LoginForm>
  );
}
