import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ImageAPI from "./ImageAPI";

type ImagesAPIProps = {
  images: Array<string>;
};

export default function ImagesAPI(props: ImagesAPIProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [currentImage, setCurrentImage] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (currentImage !== null) {
      ref.current?.focus();
    }
  }, [currentImage]);
  function next() {
    if (currentImage !== null) {
      setCurrentImage(
        currentImage >= props.images.length - 1 ? 0 : currentImage + 1
      );
    }
  }
  function prev() {
    if (currentImage !== null) {
      setCurrentImage(
        currentImage <= 0 ? props.images.length - 1 : currentImage - 1
      );
    }
  }

  return (
    <>
      {props.images.map((image, iIndex) => (
        <ImageAPI
          key={iIndex}
          image={image}
          classname="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setCurrentImage(iIndex);
          }}
        />
      ))}
      {currentImage !== null && (
        <div
          className="fixed flex justify-center w-full h-full top-0 left-0 bg-darkgrey bg-opacity-60 z-10 overflow-y-auto py-10"
          tabIndex={0}
          ref={ref}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentImage(null);
          }}
          onKeyDown={(event) => {
            switch (event.key) {
              case "Escape":
                setCurrentImage(null);
                break;
              case "ArrowLeft":
                prev();
                break;
              case "ArrowRight":
                next();
                break;
            }
          }}
        >
          <div
            className="my-auto"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex flex-row gap-2 items-center group">
              <FontAwesomeIcon
                className="invisible group-hover:visible text-5xl cursor-pointer"
                icon={faAngleLeft}
                onClick={prev}
              />
              <ImageAPI image={props.images[currentImage]} />
              <FontAwesomeIcon
                className="invisible group-hover:visible text-5xl cursor-pointer"
                icon={faAngleRight}
                onClick={next}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
