import React from "react";
import { twMerge } from "tailwind-merge";
import { useNavigate, useParams } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LoginForm from "../components/LoginForm";
import LoginFormInput from "../components/LoginFormInput";

type Inputs = {
  password: string;
  repeatPassword: string;
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [input, setInput] = React.useState<Inputs>({
    password: "",
    repeatPassword: "",
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (input.repeatPassword === input.password) {
      setIsLoading(true);
      fetch(
        `${window.location.origin}:8000/api/abstract_users/${params.token}/reset_password`,
        {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
          method: "PATCH",
          body: JSON.stringify({
            password: input.password,
          }),
        }
      )
        .then(() => {
          navigate("/");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <LoginForm
      handleSubmit={handleSubmit}
      title="Créer un nouveau mot de passe"
      subTitle="Nous vous demanderons ce mot de passe à chaque connexion."
      submit={{
        content: "Enregistrer",
        icon: isLoading
          ? {
              icon: faSpinner,
              className: "animate-spin",
            }
          : undefined,
      }}
    >
      <LoginFormInput
        label="Nouveau mot de passe"
        name="password"
        type="password"
        value={input.password}
        onChange={handleChange}
      />
      <LoginFormInput
        label="Entrez le mot de passe à nouveau"
        name="repeatPassword"
        type="password"
        value={input.repeatPassword}
        onChange={handleChange}
        className={twMerge(
          input.password !== input.repeatPassword &&
            "border-red-1 focus:outline-red-1"
        )}
      />
    </LoginForm>
  );
}
