import { twMerge } from "tailwind-merge";
import React from "react";

type BaseTextAreaProps = {
  required?: boolean;
  name?: string;
  value?: string;
  placeholder?: string;
  rows?: number;
  className?: string;
  disabled?: boolean;
  onChange?: React.FormEventHandler<HTMLTextAreaElement>;
};

export default function BaseTextArea(props: BaseTextAreaProps) {
  return (
    <textarea
      required={props.required}
      name={props.name}
      rows={props.rows}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      disabled={props.disabled}
      className={twMerge(
        "w-full p-3 rounded border border-grey-4 resize-none disabled:bg-grey-4",
        props.className
      )}
    />
  );
}
