import React from "react";
import { twMerge } from "tailwind-merge";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import logo115 from "../assets/logo115.png";
import BaseButton from "./BaseButton";
import { getStringRole } from "../utils";

type TheTopbarProps = {
  className?: string;
};

export default function TheTopbar(props: TheTopbarProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [names, setNames] = React.useState<{
    firstname: string;
    lastname: string;
  }>();

  React.useEffect(() => {
    if (auth.user) {
      fetch(
        `${window.location.origin}:8000/api/abstract_users/${auth.user.username}/get_by_email`,
        {
          headers: {
            Accept: "application/json",
          },
          method: "GET",
        }
      )
        .then((resp) => resp.json())
        .then((json) => {
          auth.user.id = json.id;
          setNames({
            firstname: json.firstname as string,
            lastname: json.lastname as string,
          });
        });
    }
  }, [auth, navigate]);

  return (
    <div
      className={twMerge(
        "h-20 flex flex-row items-center gap-3 px-7",
        props.className
      )}
    >
      <Link to="/">
        <img src={logo115} className="h-min" alt="logo" />
      </Link>
      <div className="grow"></div>
      {auth.user && (
        <>
          <FontAwesomeIcon
            icon={faUserCog}
            size="lg"
            className="text-green-2"
          />
          <div className="flex flex-col">
            <span className="font-extrabold">
              {names?.firstname} {names?.lastname}
            </span>
            <span className="font-medium text-grey-1">
              {getStringRole(auth.user.roles)}
            </span>
          </div>
          <BaseButton
            type="button"
            styleType="primary"
            content="Se déconnecter"
            onClick={() => {
              auth.signout(() => navigate("/"));
            }}
          />
        </>
      )}
    </div>
  );
}
