import {
  faArrowsAlt,
  faPen,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import BaseButton from "./BaseButton";
import BaseInput from "./BaseInput";
import BaseLabel from "./BaseLabel";
import BasePopup from "./BasePopup";
import BasePopupConfirmDelete from "./BasePopupConfirmDelete";

export type Lot = {
  "@id": string;
  id: number;
  name: string;
  position: number;
  color: string;
  worksite: string | undefined;
};

type LotsPhasesListLotProps = {
  index: number;
  lot: Lot;
  handleDelete: (id: number) => Promise<void | Response>;
  handleEdit: (lot: Lot) => Promise<void | Response>;
};

export default function LotsPhasesListLot(props: LotsPhasesListLotProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [toEdit, setToEdit] = React.useState<Lot>();
  const [toDelete, setToDelete] = React.useState<number>();

  function handleEdit(lot: Lot) {
    setIsLoading(true);
    props
      .handleEdit(lot)
      .then(() => {
        setToEdit(undefined);
        toast.success(`Le lot ${lot.name} a bien été mis à jour`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Draggable
        draggableId={`lot-${props.index.toString()}`}
        index={props.index}
      >
        {(drag) => (
          <div
            ref={drag.innerRef}
            {...drag.draggableProps}
            {...drag.dragHandleProps}
          >
            <div className="bg-grey-4 h-12 flex items-center gap-10 px-5">
              <div
                className="w-5 h-5 rounded"
                style={{ backgroundColor: props.lot.color }}
              ></div>
              <FontAwesomeIcon icon={faArrowsAlt} />
              <span className="grow font-bold">{props.lot.name}</span>
              {/* <div className="grow"></div> */}
              <div className="flex gap-3 text-grey-2 h-full items-center">
                <FontAwesomeIcon
                  className="cursor-pointer hover:text-green-1"
                  icon={faPen}
                  onClick={() => {
                    setToEdit(props.lot);
                  }}
                />
                <FontAwesomeIcon
                  className="cursor-pointer hover:text-green-1"
                  icon={faTrash}
                  onClick={() => {
                    setToDelete(props.lot.id);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Draggable>
      {toEdit && (
        <BasePopup
          title={`Modification du lot ${props.lot.name}`}
          isVisible={toEdit !== undefined}
          onClose={() => {
            setToEdit(undefined);
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEdit(toEdit);
            }}
          >
            <BaseLabel required label="Couleur du lot">
              <BaseInput
                required
                type="color"
                value={toEdit.color}
                onChange={(event) => {
                  setToEdit({
                    ...toEdit,
                    color: event.currentTarget.value,
                  });
                }}
              />
            </BaseLabel>
            <BaseLabel required label="Nom du lot">
              <BaseInput
                required
                type="text"
                value={toEdit.name}
                onChange={(event) => {
                  setToEdit({
                    ...toEdit,
                    name: event.currentTarget.value,
                  });
                }}
              />
            </BaseLabel>
            <BaseButton
              type="submit"
              styleType="primary"
              content="Terminer"
              icon={
                isLoading
                  ? {
                      icon: faSpinner,
                      className: "animate-spin",
                    }
                  : undefined
              }
              className="mt-4 float-right"
            />
          </form>
        </BasePopup>
      )}
      {toDelete && (
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={toDelete !== undefined}
          onClose={() => {
            setToDelete(undefined);
          }}
          onSuccess={() => {
            props.handleDelete(props.lot.id).then(() => {
              toast.success("Le lot a bien été supprimé");
            });
          }}
        >
          <span>
            {
              "Etes-vous sûr de vouloir supprimer le lot ? Cela entraînera la suppression de toutes les phases associées à ce lot."
            }
          </span>
        </BasePopupConfirmDelete>
      )}
    </>
  );
}
