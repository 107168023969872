import { faBell, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  File,
  FilesCategory as FilesCategoryType,
} from "../routes/worksitesNew";
import Card from "./Card";
import CardContent from "./CardContent";
import FileWidgets from "./FileWidgets";
import Section from "./Section";

type FilesCategoryProps = {
  files: FilesCategoryType;
  onDelete?: (fileIndex: number) => void;
  onEdit?: (file: File, fIndex: number) => void;
};

export default function FilesCategory(props: FilesCategoryProps) {
  const roles: { [key: string]: string } = {
    ROLE_CLIENT: "Client",
    ROLE_PARTNER: "Partenaires",
  };

  return (
    <Section
      title={
        <div className="flex gap-3">
          {props.files.name}
          <div className="group">
            <FontAwesomeIcon
              icon={faUserLock}
              className="text-base text-grey-2 group-hover:text-green-1 cursor-pointer"
            />
            <span className="text-sm font-medium text-green-1 align-top invisible group-hover:visible">
              {props.files.roles.map((role, rIndex) => {
                let item = roles[role];
                if (
                  props.files.roles &&
                  rIndex !== props.files.roles.length - 1
                ) {
                  item += ", ";
                }
                return item;
              })}
            </span>
          </div>
        </div>
      }
    >
      <Card>
        {
        props.files.files.sort((a, b) => {
          if (props.files.name === 'Assurances') {
            return a.name.localeCompare(b.name);
          }
          return b.createdAt.localeCompare(a.createdAt);
        }).map((file, fIndex) => (
          <CardContent key={fIndex}>
            {[
              <div key={0} className="text-grey-2 flex">
                <div className="flex flex-col">
                  <h3 className="font-bold text-darkgrey">{file.name}</h3>
                  {file.startDate && file.endDate ? (
                    <>
                      <span>
                        {new Date(file.startDate).toLocaleDateString()}&nbsp;-&nbsp;
                        {new Date(file.endDate).toLocaleDateString()}
                      </span>
                    </>
                    ):(
                      <span>{new Date(file.createdAt).toLocaleDateString()}</span>
                    )
                  }
                  {file.alerts.map((alert, aIndex) => (
                    <span key={aIndex} className="text-red-2 font-medium">
                      <FontAwesomeIcon icon={faBell} className="mr-2" />
                      {`${alert.name} - ${new Date(
                        alert.dueDate
                      ).toLocaleDateString()} ${
                        alert.hasClientAlert ? " - avec rappel client" : ""
                      }`}
                    </span>
                  ))}
                </div>
                <div className="grow"></div>
                <FileWidgets
                  file={{
                    "@id": file["@id"],
                  }}
                  onDelete={
                    props.onDelete
                      ? () => {
                          if (props.onDelete) {
                            props.onDelete(fIndex);
                          }
                        }
                      : undefined
                  }
                  onEdit={
                    props.onEdit
                      ? () => {
                          if (props.onEdit) {
                            props.onEdit(file, fIndex);
                          }
                        }
                      : undefined
                  }
                />
              </div>,
            ]}
          </CardContent>
        ))}
        {!props.files.files.length && (
          <span>Aucun document dans cette catégorie</span>
        )}
      </Card>
    </Section>
  );
}
