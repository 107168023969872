import {
  faArrowsAlt,
  faPen,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import BaseButton from "./BaseButton";
import BaseInput from "./BaseInput";
import BaseLabel from "./BaseLabel";
import BasePopup from "./BasePopup";
import BasePopupConfirmDelete from "./BasePopupConfirmDelete";
import BaseSelect from "./BaseSelect";
import { Lot } from "./LotsPhasesListLot";

export type Phase = {
  id: number;
  name: string;
  position: number;
  beginDate?: string;
  duration: number;
  target?: string;
  lot: string;
};

type Target = {
  label: string;
  value: string;
};

type LotsPhasesListPhaseProps = {
  index: number;
  phase: Phase;
  lots: Array<Lot>;
  targets?: Array<Target>;
  handleDelete: (id: number) => Promise<void | Response>;
  handleEdit: (phase: Phase) => Promise<void | Response>;
};

export default function LotsPhasesListPhase(props: LotsPhasesListPhaseProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [toEdit, setToEdit] = React.useState<Phase>();
  const [toDelete, setToDelete] = React.useState<number>();

  function handleEdit(phase: Phase) {
    setIsLoading(true);
    props
      .handleEdit(phase)
      .then(() => {
        setToEdit(undefined);
        toast.success(`La phase ${phase.name} a bien été mise à jour`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Draggable
        draggableId={`phase-${props.index.toString()}`}
        index={props.index}
      >
        {(drag, snap) => (
          <tr
            ref={drag.innerRef}
            {...drag.draggableProps}
            {...drag.dragHandleProps}
            className={twMerge(
              "h-12 even:bg-white odd:bg-grey-5 text-grey-1",
              snap.isDragging && "flex items-center"
            )}
          >
            <td className="px-5">
              <div
                className="w-5 h-5 rounded"
                style={{
                  backgroundColor: props.lots.find(
                    (lot) => lot["@id"] === props.phase.lot
                  )?.color,
                }}
              ></div>
            </td>
            <td className="px-5 text-grey-2">
              <FontAwesomeIcon icon={faArrowsAlt} />
            </td>
            <td className="pl-5 pr-20">{props.phase.name}</td>
            {props.targets && (
              <td className="px-5">
                {props.phase.beginDate &&
                  new Date(props.phase.beginDate).toLocaleDateString()}
              </td>
            )}
            <td className="px-5">
              <div className="flex gap-3">
                <BaseInput
                  type="number"
                  className="w-16"
                  value={props.phase.duration}
                  onChange={(event) => {
                    handleEdit({
                      ...props.phase,
                      duration: +event.currentTarget.value,
                    });
                  }}
                />
                jours
              </div>
            </td>
            <td>
              <BaseSelect
                value={props.phase.lot}
                className="w-max"
                options={props.lots.map((lot) => ({
                  label: lot.name,
                  value: lot["@id"],
                }))}
                onChange={(event) => {
                  handleEdit({
                    ...props.phase,
                    lot: event.currentTarget.value,
                  });
                }}
              />
            </td>
            {props.targets && (
              <td>
                <BaseSelect
                  value={props.phase.target}
                  className="w-max"
                  options={props.targets
                    .sort((a, b) => a.label.localeCompare(b.label))
                  }
                  onChange={(event) => {
                    handleEdit({
                      ...props.phase,
                      target: event.currentTarget.value,
                    });
                  }}
                />
              </td>
            )}
            {/* <td className="w-full"></td> */}
            <td className="px-5 text-grey-2">
              <div className="flex gap-3">
                <FontAwesomeIcon
                  className="cursor-pointer hover:text-green-1"
                  icon={faPen}
                  onClick={() => {
                    setToEdit(props.phase);
                  }}
                />
                <FontAwesomeIcon
                  className="cursor-pointer hover:text-green-1"
                  icon={faTrash}
                  onClick={() => {
                    setToDelete(props.phase.id);
                  }}
                />
              </div>
            </td>
          </tr>
        )}
      </Draggable>
      {toEdit && (
        <BasePopup
          title={`Modification de la phase ${props.phase.name}`}
          isVisible={toEdit !== undefined}
          onClose={() => {
            setToEdit(undefined);
          }}
        >
          {
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleEdit(toEdit);
              }}
            >
              <BaseLabel required label="Nom de la phase">
                <BaseInput
                  required
                  type="text"
                  value={toEdit.name}
                  onChange={(event) => {
                    setToEdit({
                      ...toEdit,
                      name: event.currentTarget.value,
                    });
                  }}
                />
              </BaseLabel>
              <BaseButton
                type="submit"
                styleType="primary"
                content="Terminer"
                icon={
                  isLoading
                    ? {
                        icon: faSpinner,
                        className: "animate-spin",
                      }
                    : undefined
                }
                className="mt-4 float-right"
              />
            </form>
          }
        </BasePopup>
      )}
      {toDelete && (
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={toDelete !== undefined}
          onClose={() => {
            setToDelete(undefined);
          }}
          onSuccess={() => {
            props.handleDelete(props.phase.id).then(() => {
              toast.success("La phase a bien été supprimée");
            });
          }}
        >
          <span>
            {
              "Etes-vous sûr de vouloir supprimer la phase ? Cela n'impactera pas les phases des chantiers existants."
            }
          </span>
        </BasePopupConfirmDelete>
      )}
    </>
  );
}
