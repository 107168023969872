import {
  faFileCsv,
  faPen,
  faPlus,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams, useOutletContext } from "react-router-dom";
import BaseButton from "../components/BaseButton";
import BaseForm from "../components/BaseForm";
import BaseInput from "../components/BaseInput";
import BaseInputFile from "../components/BaseInputFile";
import BaseLabel from "../components/BaseLabel";
import BasePopup from "../components/BasePopup";
import BasePopupConfirmDelete from "../components/BasePopupConfirmDelete";
import BaseRadio from "../components/BaseRadio";
import BaseSelect from "../components/BaseSelect";
import BaseTextArea from "../components/BaseTextArea";
import DownloadFileIcon from "../components/DownloadFileIcon";
import Loading from "../components/Loading";
import Table from "../components/Table";
import { useCheckTokenAndFetch } from "../auth";
import { File, Partner, PartnerAPI } from "./worksite";
import { toast } from "react-toastify";
// import { useWorksites } from "./layout";

type Target = {
  "@id": string;
  "@type": string;
  companyName: string;
  firstname: string;
  lastname: string;
};

type Report = {
  id: number;
  date: string;
  partner: Target | null;
  message: string;
  state: number;
  attachedFile: string;
  worksite: string | null;
};

type Lot = {
  "@id": string;
  id: number;
  name: string;
  reports: Array<Report>;
};

type CompleteReport = Report & {
  "@idLot": string | null;
  nameLot: string | null;
};

type WorksiteReportsType = {
  "@id": string;
  name: string;
  reports: Array<CompleteReport>;
  partners: Array<string>;
};

type LotListing = {
  value: string;
  label: string;
};

type ReportForm = {
  date: string;
  partner: string | null;
  message: string;
  state: number;
  lot: string | null;
  attachedFile?: File;
  worksite: string | null;
};

type WorksiteReportsApi = {
  "@id": string;
  name: string;
  lots: Array<Lot>;
  partners: Array<string>;
  generalReports: Array<Report>;
};

type ReportAPI = {
  id: number;
  date: string;
  partner: Target |null;
  lot: Lot | null;
  message: string;
  state: number;
  attachedFile: string;
  worksite: string | null;
};

export type CountsType = {
  tasksCount: number;
  reportsCount: number;
};

export default function WorksiteReports() {
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [worksite, setWorksite] = React.useState<WorksiteReportsType>();
  // const [worksites, setWorksites] = useWorksites();
    const [counts, setCounts] = useOutletContext() as [CountsType, React.Dispatch<React.SetStateAction<CountsType>>];
  const [lots, setLots] = React.useState<Array<LotListing>>();
  const [partners, setPartners] = React.useState<Array<PartnerAPI>>();
  const [currentReport, setCurrentReport] = React.useState<ReportForm>();
  const successMessage = {
    new: "Le PV de réception a bien été créé",
    edit: "Le PV de réception a bien été mis à jour",
    general: "Le PV de réception a bien été généré",
  };
  const [popup, setPopup] = React.useState<
    | {
        type: null | "new" | "general";
      }
    | {
        type: "edit" | "delete";
        id: number;
      }
  >({ type: null });
  const reportStates = [
    {
      label: "En signature client",
      value: 1,
    },
    {
      label: "En signature partenaire",
      value: 2,
    },
    {
      label: "Réserve",
      value: 3,
    },
    {
      label: "Validé",
      value: 4,
    },
  ];

  React.useEffect(() => {
    setCounts({
      ...counts,
      reportsCount: worksite?.reports
        .filter((report: Report) => report.state !== 4)
        .length ?? 0,
    });
  }, [worksite]);

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/${params.id}/get_reports`, {
      method: "GET",
    }).then((worksite: WorksiteReportsApi) => {
      setWorksite({
        ...worksite,
        reports: new Array<CompleteReport>()
          .concat(
            ...worksite.lots.flatMap<CompleteReport>((lot) =>
              lot.reports.map((report) => ({
                ...report,
                "@idLot": lot["@id"],
                nameLot: lot.name,
                worksite: null,
              }))
            )
          )
          .concat(
            ...worksite.generalReports.map<CompleteReport>((report) => ({
              ...report,
              "@idLot": null,
              nameLot: null,
              worksite: worksite["@id"],
            }))
          )
          ,
      });
      setLots(
        worksite.lots.map((lot) => ({
          label: lot.name,
          value: lot["@id"],
        }))
      );
    });
    checkTokenAndFetch(`/api/partners/get_listing`, { method: "GET" }).then(
      (partners: { "hydra:member": Array<Partner> }) => {
        setPartners(
          partners["hydra:member"]
          .sort((a, b) => a.companyName.localeCompare(b.companyName))
          .map((partner) => ({
            label: `${partner.companyName} - ${partner.firstname} ${partner.lastname}`,
            value: partner["@id"],
          }))
        );
      }
    );
  }, [params.id]);

  function csv() {
    let content = "";
    const separator = ";";
    const headersRow = [];
    headersRow.push("Partenaire");
    headersRow.push("Lot");
    headersRow.push("Etat");
    headersRow.push("Date de réception de l'ouvrage");
    content += headersRow.join(separator);
    content += "\r\n";
    worksite?.reports.forEach((report) => {
      const reportRow = [];
      reportRow.push(
        report.partner ?
          `${report.partner.companyName} - ${report.partner.firstname} ${report.partner.lastname}`
          : "-"
      );
      reportRow.push(report.nameLot);
      reportRow.push(
        reportStates.find((state) => state.value === report.state)?.label
      );
      reportRow.push(new Date(report.date).toLocaleDateString());
      content += reportRow.join(separator);
      content += "\r\n";
    });
    return (
      "data:text/csv;base64," +
      window.btoa(unescape(encodeURIComponent("\ufeff" + content)))
    );
  }

  return worksite !== undefined ? (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2">
        <BaseButton
          type="button"
          styleType="primary"
          content="Créer un PV"
          icon={{ icon: faPlus }}
          onClick={() => {
            setCurrentReport({
              date: new Date().toISOString(),
              partner: "",
              message: "",
              state: 1,
              lot: "",
              worksite: null,
            });
            setPopup({ type: "new" });
          }}
        />
        <BaseButton
          type="button"
          styleType="primary"
          content="PV général"
          icon={{ icon: faPlus }}
          onClick={() => {
            setCurrentReport({
              date: new Date().toISOString(),
              partner: null,
              message: "",
              state: 1,
              lot: null,
              worksite: `/api/worksites/${params.id}/get_summary`,
            });
            setPopup({ type: "general" });
          }}
        />
        <div className="flex-grow"></div>
        <a href={csv()} download={`${worksite.name}_pv-de-reception`}>
          <BaseButton
            type="button"
            styleType="csv"
            content="Export CSV"
            icon={{ icon: faFileCsv }}
          />
        </a>
      </div>
      <Table
        headers={[
          { name: "Partenaires", order: true },
          { name: "Lot", order: true },
          { name: "Etat", order: true },
          { name: "Date de réception de l'ouvrage", order: true },
          {},
        ]}
        defaultOrder={{
          field: 3,
          isAsc: false,
        }}
      >
        {worksite.reports.map((report) => {
          return [
            report.partner ?
              {
                elem:
                  report.partner ?
                    report.partner.companyName + " - " + report.partner.firstname + " " + report.partner.lastname
                    : "-",
                value:
                  report.partner ?
                    report.partner.firstname + report.partner.lastname
                    : "-",
              }: {
                elem: "PV général",
                value: "PV général"
              },
            report.nameLot ?
              {
                elem: report.nameLot ? report.nameLot : "-",
                value: report.nameLot ? report.nameLot : "-"
              } :
              {
                elem: "-",
                value: "-"
              },

            {
              elem: (
                <BaseSelect
                  name="state"
                  value={report.state}
                  options={reportStates}
                  onChange={(event) => {
                    const val = +event.currentTarget.value;
                    checkTokenAndFetch(
                      `/api/reports/${report.id}/patch_state`,
                      {
                        headers: {
                          "Content-Type": "application/merge-patch+json",
                        },
                        method: "PATCH",
                        body: JSON.stringify({
                          state: val,
                        }),
                      }
                    ).then(() => {
                      report.state = val;
                      setWorksite({ ...worksite });
                      toast.success(
                        "L'état du PV de réception a bien été mis à jour"
                      );
                    });
                  }}
                />
              ),
              value: report.state,
            },

            {
              elem: new Date(report.date).toLocaleDateString(),
              value: report.date,
            },

            {
              elem: (
                <span className="text-grey-2 flex flex-row items-center gap-3">
                  <DownloadFileIcon url={report.attachedFile} />
                  <FontAwesomeIcon
                    className="cursor-pointer hover:text-green-1"
                    icon={faPen}
                    onClick={() => {
                      checkTokenAndFetch(report.attachedFile, {
                        method: "GET",
                      }).then((returnedFile: File) => {
                        setCurrentReport({
                          date: report.date,
                          partner: report.partner ? report.partner["@id"] : null,
                          message: report.message,
                          state: report.state,
                          lot: report.nameLot ? report["@idLot"] : null,
                          attachedFile: returnedFile,
                          worksite: report.worksite ? report.worksite : null,
                        });
                        setPopup({ type: "edit", id: report.id });
                      });
                    }}
                  />
                  <FontAwesomeIcon
                    className="cursor-pointer hover:text-green-1"
                    icon={faTrash}
                    onClick={() => {
                      setPopup({ type: "delete", id: report.id });
                    }}
                  />
                </span>
              ),
              value: 0,
            },
          ];
        })}
      </Table>
      {(popup.type === "new" || popup.type === "general" || popup.type === "edit") &&
        currentReport !== undefined && (
          <BasePopup
            title={popup.type === "new" ? "Nouveau PV" : "Modifier PV"}
            isVisible={popup.type === "new" || popup.type === "general" || popup.type === "edit"}
            className="min-w-[600px]"
            onClose={() => {
              setPopup({ type: null });
            }}
          >
            <BaseForm
              isNew={popup.type === "new" || popup.type === "general"}
              url={
                popup.type === "edit"
                  ? `/api/reports/${popup.id}/patch`
                  : `/api/reports/post_new`
              }
              body={currentReport}
              loadingSetter={setIsLoading}
              onSuccess={(report: ReportAPI) => {
                switch (popup.type) {
                  case "new":
                    setWorksite({
                      ...worksite,
                      reports: [
                        ...worksite.reports,
                        {
                          id: report.id,
                          date: report.date,
                          partner: report.partner,
                          message: report.message,
                          state: report.state,
                          attachedFile: report.attachedFile,
                          "@idLot": report.lot ? report.lot["@id"] : null,
                          nameLot: report.lot ? report.lot.name : null,
                          worksite: report.worksite,
                        },
                      ],
                    });
                    break;
                  case "general":
                    setWorksite({
                      ...worksite,
                      reports: [
                        ...worksite.reports,
                        {
                          id: report.id,
                          date: report.date,
                          partner: null,
                          message: report.message,
                          state: report.state,
                          attachedFile: report.attachedFile,
                          "@idLot": null,
                          nameLot: null,
                          worksite: report.worksite,
                        },
                      ],
                    });
                    break;
                  case "edit":
                    setWorksite({
                      ...worksite,
                      reports: worksite.reports.map((r) => {
                        if (r.id === popup.id) {
                          return {
                            id: report.id,
                            date: report.date,
                            partner: report.partner,
                            message: report.message,
                            state: report.state,
                            attachedFile: report.attachedFile,
                            "@idLot": report.lot ? report.lot["@id"] : null,
                            nameLot: report.lot ? report.lot.name : null,
                            worksite: report.worksite,
                          };
                        }
                        return r;
                      }),
                    });
                    break;
                }
                setPopup({ type: null });
              }}
              successMessage={successMessage[popup.type]}
            >
              <div className="flex flex-col gap-5">
                <div className="flex gap-5">
                  <BaseLabel required label="Date">
                    <BaseInput
                      required
                      type="date"
                      name="date"
                      value={currentReport.date.slice(0, 10)}
                      disabled={popup.type === "edit"}
                      onChange={(event) => {
                        setCurrentReport({
                          ...currentReport,
                          date: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                  { (popup.type !== "general" && (popup.type === "edit" && currentReport.partner ) || popup.type === "new") &&
                    <BaseLabel required label="Partenaire">
                      <BaseSelect
                        required
                        options={
                          partners === undefined
                            ? []
                            : partners.filter((partner) =>
                                worksite.partners.includes(partner.value)
                              )
                        }
                        value={currentReport.partner ? currentReport.partner : "" }
                        disabled={popup.type === "edit"}
                        onChange={(event) => {
                          setCurrentReport({
                            ...currentReport,
                            partner: event.currentTarget.value,
                          });
                        }}
                      />
                    </BaseLabel>
                  }
                </div>
                { (popup.type !== "general" && (popup.type === "edit" && currentReport.lot ) || popup.type === "new") &&
                  <BaseLabel required label="Lot">
                    <BaseSelect
                      required
                      value={currentReport.lot ? currentReport.lot : ""}
                      disabled={popup.type === "edit"}
                      onChange={(event) => {
                        setCurrentReport({
                          ...currentReport,
                          lot: event.currentTarget.value,
                        });
                      }}
                      options={lots === undefined ? [] :
                        lots.sort((a, b) => a.label.localeCompare(b.label))
                      }
                    />
                  </BaseLabel>
                }
                <BaseLabel label="Notes / Réserves">
                  <BaseTextArea
                    placeholder="Entrez votre message"
                    rows={3}
                    value={currentReport.message}
                    disabled={popup.type === "edit"}
                    onChange={(event) => {
                      setCurrentReport({
                        ...currentReport,
                        message: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
                {popup.type === "edit" && (
                  <>
                    <BaseLabel required label="Etat">
                      <div className="grid grid-flow-col grid-rows-2 gap-x-5 w-min">
                        {reportStates.map((state, sIndex) => (
                          <BaseRadio
                            key={sIndex}
                            label={state.label}
                            value={state.value}
                            currentValue={currentReport.state}
                            onChange={(event) => {
                              setCurrentReport({
                                ...currentReport,
                                state: +event.currentTarget.value,
                              });
                            }}
                            className="w-max"
                          />
                        ))}
                      </div>
                    </BaseLabel>
                    <BaseInputFile
                      label= "Remplacer le fichier"
                      required
                      value={currentReport.attachedFile}
                      onChange={(file) => {
                        if (file) {
                          setCurrentReport({
                            ...currentReport,
                            attachedFile: {
                              ...currentReport.attachedFile,
                              content: file.content,
                              createdAt: new Date().toISOString(),
                            },
                          });
                        } else {
                          setCurrentReport({
                            ...currentReport,
                            attachedFile: undefined,
                          });
                        }
                      }}
                    />
                  </>
                )}
                <BaseButton
                  type="submit"
                  styleType="primary"
                  content="Enregistrer"
                  icon={
                    isLoading
                      ? {
                          icon: faSpinner,
                          className: "animate-spin",
                        }
                      : undefined
                  }
                  className="self-end"
                />
              </div>
            </BaseForm>
          </BasePopup>
        )}
      {popup.type === "delete" && (
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={popup.type === "delete"}
          onClose={() => {
            setPopup({ type: null });
          }}
          onSuccess={() => {
            setWorksite({
              ...worksite,
              reports: worksite.reports.filter(
                (report) => report.id !== popup.id
              ),
            });
            toast.success("Le PV de réception a bien été supprimé");
          }}
          url={`/api/reports/${popup.id}`}
        >
          Etes-vous sûr de vouloir supprimer ce PV de réception ?
        </BasePopupConfirmDelete>
      )}
    </div>
  ) : (
    <Loading />
  );
}
