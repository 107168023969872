import React from "react";
import { twMerge } from "tailwind-merge";

type TableRowProps = {
  children: Array<React.ReactNode>;
  className?: string;
};

export default function TableRow(props: TableRowProps) {
  return (
    <tr
      className={twMerge(
        "odd:bg-grey-4 even:bg-white hover:bg-green-1 hover:bg-opacity-10",
        props.className
      )}
    >
      {props.children.map((child, cIndex) => (
        <td key={cIndex} className="p-4 text-grey-1">
          {child}
        </td>
      ))}
    </tr>
  );
}
