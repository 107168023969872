import React from "react";
import { useNavigate } from "react-router-dom";
import { faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../components/BaseButton";
import Page from "../components/Page";
import BaseForm from "../components/BaseForm";
import ClientsNewForm, { ClientInput } from "../components/ClientsNewForm";

export default function ClientsNew() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [input, setInput] = React.useState<ClientInput>({
    isProfessional: false,
    companyName: "",
    companyStatus: "",
    sex: 0,
    lastname: "",
    firstname: "",
    address: "",
    zipCode: "",
    city: "",
    cellPhone: "",
    homePhone: "",
    email: "",
    password: "",
    repeatPassword: "",
  });
  const buttons = (
    <div className="flex flex-row gap-3">
      <BaseButton
        type="button"
        styleType="cancel"
        content="Annuler"
        onClick={() => {
          navigate(-1);
        }}
      />
      <BaseButton
        type="submit"
        styleType="primary"
        content="Créer le client"
        icon={
          isLoading
            ? {
                icon: faSpinner,
                className: "animate-spin",
              }
            : undefined
        }
      />
    </div>
  );

  return (
    <Page icon={{ icon: faUser }} title="Nouveau client">
      <BaseForm
        isNew={true}
        url={`/api/clients/post_new`}
        body={input}
        loadingSetter={setIsLoading}
        successMessage="Le client a bien été créé"
      >
        <div className="flex mb-14">{buttons}</div>
        <ClientsNewForm
          initialValues={input}
          handler={(client) => {
            setInput(client);
          }}
        />
        <div className="mx-auto mt-14">{buttons}</div>
      </BaseForm>
    </Page>
  );
}
