import React from "react";
import { faColumns } from "@fortawesome/free-solid-svg-icons";
import DoubleColumn from "../components/DoubleColumn";
import Page from "../components/Page";
import TheDashboardMap from "../components/TheDashboardMap";
import TheMemoList from "../components/TheMemoList";
import TheQuotationList from "../components/TheQuotationList";
import TheTaskList from "../components/TheTaskList";

export default function Dashboard() {
  return (
    <Page icon={{ icon: faColumns }} title="Tableau de bord">
      <DoubleColumn
        left={
          <>
            <TheDashboardMap />
            <TheTaskList />
          </>
        }
        right={
          <>
            <TheMemoList />
            <TheQuotationList />
          </>
        }
      />
    </Page>
  );
}
