import React from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { matchPath, NavLink, useLocation } from "react-router-dom";

type TheSidebarNavLinkProps = {
  notClickable?: boolean;
  to: string;
  content: string;
  icon: IconProp;
  children?: React.ReactNode;
};

export default function TheSidebarNavLink(props: TheSidebarNavLinkProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const location = useLocation();

  React.useEffect(() => {
    if (matchPath(props.to + "/*", location.pathname)) {
      setIsOpen(true);
    }
  }, [props.to, location.pathname]);

  return (
    <>
      <NavLink
        className={({ isActive }) =>
          twMerge(
            "h-12 pl-6 flex items-center hover:bg-green-2",
            ((!props.notClickable && isActive) ||
              (props.notClickable &&
                matchPath(props.to + "/*", location.pathname))) &&
              "bg-green-1"
          )
        }
        to={props.notClickable ? "#" : props.to}
        onClick={(event) => {
          if (props.notClickable) {
            event.preventDefault();
            setIsOpen(!isOpen);
          }
        }}
      >
        <FontAwesomeIcon icon={props.icon} className="mr-5" />
        {props.content}
        {props.children && (
          <>
            <div className="grow"></div>
            <div
              className="p-3 mr-2"
              onClick={(event) => {
                event.preventDefault();
                setIsOpen(!isOpen);
              }}
            >
              <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleUp} />
            </div>
          </>
        )}
      </NavLink>
      {isOpen && props.children}
    </>
  );
}
