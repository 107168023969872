import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faPen,
  faPlus,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BasePopupConfirmDelete from "../components/BasePopupConfirmDelete";
import Page from "../components/Page";
import Table from "../components/Table";
import { parsePhoneNumber } from "libphonenumber-js";
import { useCheckTokenAndFetch } from "../auth";
import Loading from "../components/Loading";
import { toast } from "react-toastify";

type Client = {
  id: number;
  companyName: string;
  firstname: string;
  lastname: string;
  address: string;
  zipCode: string;
  city: string;
  cellPhone: string;
  email: string;
  worksites: Array<{
    id: number;
    state: number;
    name: string;
    address: string;
    zipCode: string;
    city: string;
  }>;
  related?: Omit<Client, "parent">;
  parent?: Omit<Client, "parent">;
};

export default function Clients() {
  const [clients, setClients] = React.useState<Array<Client>>();
  const [isPopupActive, setIsPopupActive] = React.useState<boolean>(false);
  const [clientToDelete, setClientToDelete] = React.useState<number>();
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  React.useEffect(() => {
    checkTokenAndFetch(`/api/clients/get_listing`, {
      method: "GET",
    }).then((clients: { "hydra:member": Array<Client> }) => {
      setClients(
        clients["hydra:member"].map((client) => {
          client.worksites = client.worksites.filter(
            (worksite) => worksite.state === 1
          );
          return client;
        })
      );
    });
  }, [navigate]);

  function csv() {
    let content = "";
    const separator = ";";
    const headersRow = [];
    headersRow.push("Prénom");
    headersRow.push("Nom");
    headersRow.push("Société")
    headersRow.push("Addresse");
    headersRow.push("Téléphone");
    headersRow.push("Email");
    headersRow.push("Nom du chantier");
    headersRow.push("Addresse du chantier");
    content += headersRow.join(separator);
    content += "\r\n";
    if (clients) {
      clients.forEach((client) => {
        const clientRow = [];
        clientRow.push(client.firstname);
        clientRow.push(client.lastname);
        clientRow.push(client.companyName);
        clientRow.push(`${client.address} - ${client.zipCode} ${client.city}`);
        clientRow.push(parsePhoneNumber(client.cellPhone).formatNational());
        clientRow.push(client.email);
        content += clientRow.join(separator);
        content += "\r\n";
        client.worksites.forEach((worksite) => {
          const worksiteRow = ["", "", "", "", "", ""];
          worksiteRow.push(worksite.name);
          worksiteRow.push(
            `${worksite.address} - ${worksite.zipCode} ${worksite.city}`
          );
          content += worksiteRow.join(separator);
          content += "\r\n";
        });
      });
    }
    return (
      "data:text/csv;base64," +
      window.btoa(unescape(encodeURIComponent("\ufeff" + content)))
    );
  }

  return (
    <Page icon={{ icon: faUser }} title="Clients">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row">
          <Link to="ajouter">
            <BaseButton
              type="button"
              styleType="primary"
              content="Ajouter un client"
              icon={{ icon: faPlus }}
            />
          </Link>
          <div className="flex-grow"></div>
          <a href={csv()} download="clients">
            <BaseButton
              type="button"
              styleType="csv"
              content="Export CSV"
              icon={{ icon: faFileCsv }}
            />
          </a>
        </div>
        {clients ? (
          <Table
            search={[0, 1, 2]}
            headers={[
              {
                name: "Client",
                order: true,
              },
              {
                name: "Téléphone",
                order: false,
              },
              {
                name: "Adresse email",
                order: false,
              },
              {
                name: "Chantiers en cours",
                order: false,
              },
              {},
            ]}
          >
            {clients
              .filter((client) => !client.parent)
              .map((client) => [
                {
                  elem: (
                    <>
                      {client.companyName && (
                        <div className="text-xs italic text-darkgrey">
                          {client.companyName}
                        </div>
                      )}
                      {client.lastname + " " + client.firstname}
                      {client.related && (
                        <>
                          <br />
                          {client.related.lastname +
                            " " +
                            client.related.firstname}
                        </>
                      )}
                    </>
                  ),
                  value:
                    client.companyName ? client.companyName :
                      client.lastname +
                      client.firstname +
                      (client.related === undefined
                        ? ""
                        : client.related.lastname + client.related.firstname),
                },
                {
                  elem: (
                    <>
                      <a href={`tel:${client.cellPhone}`}>
                        {parsePhoneNumber(client.cellPhone).formatNational()}
                      </a>
                      {client.related && (
                        <>
                          <br />
                          <a href={`tel:${client.related.cellPhone}`}>
                            {parsePhoneNumber(
                              client.related.cellPhone
                            ).formatNational()}
                          </a>
                        </>
                      )}
                    </>
                  ),
                  value:
                    parsePhoneNumber(client.cellPhone)
                      .formatNational()
                      .replace(/\s/g, "") +
                    (client.related === undefined
                      ? ""
                      : parsePhoneNumber(client.related.cellPhone)
                          .formatNational()
                          .replace(/\s/g, "")),
                },
                {
                  elem: (
                    <>
                      <a href={`mailto:${client.email}`}>{client.email}</a>
                      {client.related && (
                        <>
                          <br />
                          <a href={`mailto:${client.related.email}`}>
                            {client.related.email}
                          </a>
                        </>
                      )}
                    </>
                  ),
                  value:
                    client.email +
                    (client.related === undefined ? "" : client.related.email),
                },
                {
                  elem: (
                    <Badge
                      value={client.worksites.length}
                      type={client.worksites.length ? "primary" : "inactive"}
                    />
                  ),
                  value: client.worksites.length,
                },
                {
                  elem: (
                    <span className="text-grey-2 flex flex-row gap-3 items-center">
                      <Link to={`editer/${client.id}`}>
                        <FontAwesomeIcon
                          className="cursor-pointer hover:text-green-1"
                          icon={faPen}
                        />
                      </Link>
                      <FontAwesomeIcon
                        className="cursor-pointer hover:text-green-1"
                        icon={faTrash}
                        onClick={() => {
                          setClientToDelete(client.id);
                          setIsPopupActive(true);
                        }}
                      />
                    </span>
                  ),
                  value: 0,
                },
              ])}
          </Table>
        ) : (
          <Loading />
        )}
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={isPopupActive}
          onClose={() => {
            setIsPopupActive(false);
          }}
          onSuccess={() => {
            if (clients) {
              setClients(clients.filter((item) => item.id !== clientToDelete));
              toast.success("Le client a bien été supprimé");
            }
          }}
          url={`/api/clients/${clientToDelete}`}
        >
          Etes-vous sûr de vouloir supprimer le client ? Cela entrainera la
          suppression de tous ses chantiers.
        </BasePopupConfirmDelete>
      </div>
    </Page>
  );
}
