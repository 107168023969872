import React from "react";
import { twMerge } from "tailwind-merge";

type CardProps = {
  children?: React.ReactNode;
  className?: string;
};

export default function Card(props: CardProps) {
  return (
    <div className="p-4 pr-2 rounded-md bg-white">
      <div
        className={twMerge(
          "max-h-72 overflow-y-auto pr-2 flex flex-col gap-5",
          props.className
        )}
      >
        {props.children}
      </div>
    </div>
  );
}
