import {
  faAngleDown,
  faAngleUp,
  faCheckCircle,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import React from "react";
import { toast } from "react-toastify";
import { useCheckTokenAndFetch } from "../auth";
import BaseButton from "./BaseButton";
import BasePopup from "./BasePopup";
import CardContentItem from "./CardContentItem";
import ImagesAPI from "./ImagesAPI";

export type Task = {
  id: number;
  isDone: boolean;
  dueDate: string;
  target: {
    companyName: string;
    firstname: string;
    lastname: string;
  };
  message: string;
  attachedFiles: Array<string>;
};

type TaskSummaryProps = {
  task: Task;
  link?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  onToggleState: () => void;
};

export default function TaskSummary(props: TaskSummaryProps) {
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isPopupActive, setIsPopupActive] = React.useState<boolean>(false);
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isClamped, setIsClamped] = React.useState<boolean>(false);

  const pRef = React.useCallback((node: HTMLParagraphElement) => {
    if (
      props.task.attachedFiles.length ||
      (node && node.scrollHeight > node.clientHeight)
    ) {
      setIsClamped(true);
    } else {
      setIsClamped(false);
    }
  }, []);

  function toggleTaskState() {
    checkTokenAndFetch(`/api/tasks/${props.task.id}/patch_is_done`, {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
      method: "PATCH",
      body: JSON.stringify({
        isDone: !props.task.isDone,
      }),
    })
      .then(() => {
        props.onToggleState();
        toast.success("L'état de la tâche a bien été modifié");
      })
      .finally(() => {
        setIsPopupActive(false);
      });
  }

  return (
    <>
      <CardContentItem
        link={props.link}
        informations={new Date(props.task.dueDate).toLocaleDateString()}
        title={
          <div className="flex">
            {(props.task.target.companyName
              ? `${props.task.target.companyName} - `
              : "") +
              `${props.task.target.firstname} ${props.task.target.lastname}`}
            <div className="ml-4 flex gap-2 text-grey-2">
              {props.onEdit && (
                <FontAwesomeIcon
                  className="hover:text-green-1 cursor-pointer"
                  icon={faPen}
                  onClick={props.onEdit}
                />
              )}
              {props.onDelete && (
                <FontAwesomeIcon
                  className="hover:text-green-1 cursor-pointer"
                  icon={faTrash}
                  onClick={props.onDelete}
                />
              )}
            </div>
          </div>
        }
        badge={
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={twMerge(
              "text-3xl cursor-pointer",
              props.task.isDone ? "text-green-1" : "text-grey-2"
            )}
            onClick={() => {
              setIsPopupActive(true);
            }}
          />
        }
      >
        <p ref={pRef} className={isActive ? "line-clamp-none" : "line-clamp-3"}>
          {props.task.message}
        </p>
        <div className={twMerge("flex flex-col gap-2", !isActive && "hidden")}>
          <ImagesAPI images={props.task.attachedFiles} />
        </div>
        {(isClamped || isActive) && (
          <button
            onClick={() => {
              setIsActive(!isActive);
            }}
            className="text-green-1 font-semibold"
          >
            Lire la suite
            <FontAwesomeIcon
              icon={isActive ? faAngleUp : faAngleDown}
              className="ml-3"
            />
          </button>
        )}
      </CardContentItem>
      <BasePopup
        title="Changement d'état de la tâche"
        isVisible={isPopupActive}
        onClose={() => {
          setIsPopupActive(false);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            toggleTaskState();
          }
        }}
      >
        <span>{`Etes-vous sûr de vouloir changer l'état de cette tâche ?`}</span>
        <div className="mt-4 floa">
          <div className="flex justify-end gap-6">
            <BaseButton
              type="button"
              styleType="cancel"
              content="Annuler"
              onClick={() => {
                setIsPopupActive(false);
              }}
            />
            <BaseButton
              type="button"
              styleType="primary"
              content="Continuer"
              onClick={() => {
                toggleTaskState();
              }}
            />
          </div>
        </div>
      </BasePopup>
    </>
  );
}
