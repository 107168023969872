import React from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import CardContent from "./CardContent";
import { Link } from "react-router-dom";

type TheMemoListItemProps = {
  memo: {
    id: number;
    name: string;
    description: string;
  };
};

export default function TheMemoListItem(props: TheMemoListItemProps) {
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isClamped, setIsClamped] = React.useState<boolean>(false);

  const pRef = React.useCallback((node: HTMLParagraphElement) => {
    if (node && node.scrollHeight > node.clientHeight) {
      setIsClamped(true);
    } else {
      setIsClamped(false);
    }
  }, []);

  return (
    <CardContent
      title={<Link to={`/chantiers/${props.memo.id}`}>{props.memo.name}</Link>}
    >
      {[
        <div key={0}>
          <p
            ref={pRef}
            className={twMerge(
              "flex flex-col",
              isActive ? "line-clamp-none" : "line-clamp-3"
            )}
          >
            {props.memo.description}
          </p>
          {(isClamped || isActive) && (
            <button
              onClick={() => {
                setIsActive(!isActive);
              }}
              className="text-green-1 font-semibold"
            >
              Lire la suite
              <FontAwesomeIcon
                icon={isActive ? faAngleUp : faAngleDown}
                className="ml-3"
              />
            </button>
          )}
        </div>,
      ]}
    </CardContent>
  );
}
