import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faUser, faBell } from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../components/BaseButton";
import BasePopupConfirmDelete from "../components/BasePopupConfirmDelete";
import Page from "../components/Page";
import Table from "../components/Table";
import { useAuth, useCheckTokenAndFetch } from "../auth";
import Loading from "../components/Loading";
import { toast } from "react-toastify";

type Admin = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  receiveAlerts: boolean;
  ancestors: Array<number>;
};

export default function ConfigurationAdmins() {
  const auth = useAuth();
  const [admins, setAdmins] = React.useState<Array<Admin>>([]);
  const [adminToDelete, setAdminToDelete] = React.useState<number>();
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  React.useEffect(() => {
    checkTokenAndFetch(`/api/admins/get_listing`, {
      method: "GET",
    }).then((admins: { "hydra:member": Array<Admin> }) => {
      setAdmins(admins["hydra:member"]);
    });
  }, [navigate]);

  return (
    <Page icon={{ icon: faUser }} title="Administrateurs">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row">
          <Link to="ajouter">
            <BaseButton
              type="button"
              styleType="primary"
              content="Ajouter un administrateur"
              icon={{ icon: faPlus }}
            />
          </Link>
        </div>
        {admins ? (
          <Table
            search={[0, 1]}
            headers={[
              {
                name: "Administrateur",
                order: true,
              },
              {
                name: "Adresse email",
                order: false,
              },
              {
                name: "Alertes",
                order: false,
              },
              {},
            ]}
          >
            {admins.map((admin) => [
              {
                elem: admin.firstname + " " + admin.lastname,
                value: admin.firstname + admin.lastname,
              },
              {
                elem: <a href={`mailto:${admin.email}`}>{admin.email}</a>,
                value: admin.email,
              },
              {
                elem:
                  <FontAwesomeIcon
                    className={`cursor-pointer ml-4 ${admin.receiveAlerts ? "text-green-1" : "text-grey-2"}`}
                    icon={faBell}
                    onClick={(e) => {
                      e.currentTarget.classList.add("text-grey-1");
                      e.currentTarget.classList.remove("text-grey-2", "text-green-1");
                      checkTokenAndFetch(`/api/admins/${admin.id}/patch_alerts`, {
                        headers: {
                          "Content-Type": "application/merge-patch+json",
                        },
                        method: "PATCH",
                        body: JSON.stringify({
                          receiveAlerts: !admin.receiveAlerts,
                        }),
                      }).then((admin: Admin) => {
                        setAdmins(
                          admins.map((item) => {
                            if (item.id === admin.id) {
                              return admin;
                            }
                            return item;
                          })
                        );
                      });
                    }}
                  />
                ,
                value: admin.receiveAlerts ? 1 : 0,
              },
              {
                elem: (
                  <span className="text-grey-2 flex flex-row gap-3 items-center">
                    {auth.user.id && admin.ancestors.includes(auth.user.id) && (
                      <FontAwesomeIcon
                        className="cursor-pointer hover:text-green-1"
                        icon={faTrash}
                        onClick={() => {
                          setAdminToDelete(admin.id);
                        }}
                      />
                    )}
                  </span>
                ),
                value: 0,
              },
            ])}
          </Table>
        ) : (
          <Loading />
        )}
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={adminToDelete !== undefined}
          onClose={() => {
            setAdminToDelete(undefined);
          }}
          onSuccess={() => {
            setAdmins(admins.filter((item) => item.id !== adminToDelete));
            toast.success("L'administrateur a bien été supprimé");
          }}
          url={`/api/admins/${adminToDelete}`}
        >
          {"Etes-vous sûr de vouloir supprimer l'administrateur ?"}
        </BasePopupConfirmDelete>
      </div>
    </Page>
  );
}
