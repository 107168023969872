import React from "react";
import { useNewWorksite } from "./worksitesNew";
import FilesList from "../components/FilesList";

export default function WorksitesNewFiles() {
  const [worksite, setWorksite] = useNewWorksite().worksite;

  return (
    <FilesList
      worksiteName={worksite.name}
      partners={worksite.partners}
      files={worksite.filesCategories}
      filesSetter={(files) => {
        setWorksite({
          ...worksite,
          filesCategories: files,
        });
      }}
    />
  );
}
