import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LoginForm from "../components/LoginForm";
import LoginFormInput from "../components/LoginFormInput";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const state = location.state as {
    from: { pathname: string };
  };
  const from = state?.from?.pathname || "/";
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const [isUnauthorized, setIsUnauthorized] = React.useState<boolean>(false);
  const [isConnexionValid, setIsConnexionValid] = React.useState<boolean>(false);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsConnexionValid(false);
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    auth.signin(
      username,
      password,
      () => {
        navigate(from, { replace: true });
      },
      () => {
        setIsLoading(false);
        setIsConnexionValid(true);
      }
    );
  }
  React.useEffect(() => {
    if(auth.user && !auth.user.roles.includes("ROLE_ADMIN")) {
      // setIsUnauthorized(true);
      // setIsLoading(false);
      // auth.signout(() => {
      //   navigate('/connexion');
      // });
      setIsLoading(false);
      auth.signout(() => {
        navigate('/telecharger-appli');
      });
    }
    if (auth.user && auth.user.roles.includes("ROLE_ADMIN")) {
      navigate('/');
    }
  }, [auth.user]);

  return (
    <LoginForm
      handleSubmit={handleSubmit}
      title="Bienvenue sur OMDO"
      subTitle="La maison sur mesure"
      alert={isConnexionValid ? "Email et/ou mot de passe incorrect" : undefined}
      submit={{
        content: "Se connecter",
        icon: isLoading
          ? {
              icon: faSpinner,
              className: "animate-spin",
            }
          : undefined,
      }}
      link={{
        to: "/mot-de-passe",
        content: "Modifier le mot de passe",
      }}
    >
      <LoginFormInput label="Adresse email" name="username" type="email" />
      <LoginFormInput label="Mot de passe" name="password" type="password" />
    </LoginForm>
  );
}
