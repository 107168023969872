import { faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  DualListBox from "react-dual-listbox";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import React from "react";
import { PartnerAPI } from "../routes/worksite";
import BaseButton from "./BaseButton";
import BasePopup from "./BasePopup";
import BaseSelect from "./BaseSelect";
import Card from "./Card";
import Section from "./Section";

type WorksitePartnersProps = {
  partners: Array<string>;
  partnersListing: Array<PartnerAPI>;
  onDelete: (partner: string) => Promise<void>;
  onAdd: (partnersList: Array<string>) => Promise<void>;
};

export default function WorksitePartners(props: WorksitePartnersProps) {
  const [isPopupActive, setIsPopupActive] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [worksitePartners, setWorksitePartners] = React.useState<Array<string>>([]);

  const onChange = (value: React.SetStateAction<string[]>) => {
    setSelected(value)
  };

  React.useEffect(() => {
    // console.log(props.partners);
    // console.log(props.partnersListing);
    // setSelected(
    //   props.partnersListing
    //     .filter((partner) => props.partners.includes(partner.value))
    //     .map((partner) => partner.value)
    // );
    setSelected([]);
    props.partnersListing
      .filter((partner) => props.partners.includes(partner.value))
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((partner) => (
        setSelected(selected => [...selected, partner.value])
      ))
  }, [props.partnersListing, props.partners]);

  return (
    <Section
      title={
        <span className="place-self-center grow flex items-center">
          Artisans et partenaires
          <div className="grow"></div>
          <BaseButton
            type="button"
            styleType="primary"
            icon={{ icon: faPlus }}
            content="Ajouter"
            onClick={() => {
              setIsPopupActive(true);
            }}
          />
        </span>
      }
    >
      <Card className="max-h-max">
        {props.partners.length ? (
          <table>
            <tbody>
              {props.partnersListing
                .filter((partner) => props.partners.includes(partner.value))
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((partner, index) => (
                  <tr key={index} className="odd:bg-grey-7 even:bg-grey-5">
                    <td className="py-1 px-4">{partner.label}</td>
                    <td className="py-1 px-4">
                      <FontAwesomeIcon
                        className="text-grey-2 cursor-pointer hover:text-green-1"
                        icon={faTrash}
                        onClick={() => {
                          props.onDelete(partner.value);
                        }}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        ) : (
          <>
            Vous pouvez ajouter des partenaires en cliquant sur le bouton
            Ajouter ci-dessus
          </>
        )}
        <BasePopup
          title="Ajout artisan/partenaire"
          className="w-1/2 max-w-4xl"
          isVisible={isPopupActive}
          onClose={() => {
            setIsPopupActive(false);
          }}
        >
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              setIsLoading(true);
              const partnersToAdd = selected.filter((partner) => !props.partners.includes(partner));
              const partnersToDelete = props.partners.filter((partner) => !selected.includes(partner));
              props
                .onAdd(partnersToAdd)
                .then(() => {
                  return Promise.all(partnersToDelete.map(props.onDelete));
                })
                .then(() => {
                  setIsPopupActive(false);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <DualListBox
              options={props.partnersListing
                .sort((a, b) => a.label.localeCompare(b.label))}
              selected={selected}
              onChange={onChange}
              canFilter
              filterPlaceholder="Rechercher ..."
              className={"h-96"}
              filterCallback={(option, filterInput) => {
                if (filterInput === '') {
                    return true;
                }

                return (new RegExp(filterInput, 'i')).test(option.label);
            }}
            />
            <BaseButton
              type="submit"
              styleType="primary"
              content="Valider"
              className="mt-4 float-right"
              icon={
                isLoading
                  ? {
                      icon: faSpinner,
                      className: "animate-spin",
                    }
                  : undefined
              }
            />
          </form>
        </BasePopup>
      </Card>
    </Section>
  );
}
