import React from "react";
import { twMerge } from "tailwind-merge";

type BaseLabelProps = {
  label: string;
  required?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export default function BaseLabel(props: BaseLabelProps) {
  return (
    <label className={twMerge("font-extrabold w-full", props.className)}>
      <div>
        {props.label}
        {props.required && <span className="text-red-1 ml-1">*</span>}
      </div>
      {props.children}
    </label>
  );
}
