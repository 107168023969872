import {
  faEnvelope,
  faPhone,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parsePhoneNumber } from "libphonenumber-js";
import React from "react";
import { Client } from "../routes/worksite";
import { Worksite } from "../routes/worksitesNew";
import BaseButton from "./BaseButton";
import BaseInput from "./BaseInput";
import BaseLabel from "./BaseLabel";
import Card from "./Card";
import Section from "./Section";
import { useCheckTokenAndFetch } from "../auth";
import { toast } from "react-toastify";

type WorksiteInfos = {
  name: string;
  cadastralSection: string;
  address: string;
  zipCode: string;
  city: string;
};

type WorksiteBaseInfosProps = {
  worksite: WorksiteInfos;
  onChange?: (infos: WorksiteInfos) => void;
  onSubmit?: (infos: WorksiteInfos) => Promise<void | Response>;
  onLocalize?: () => Promise<void | Response>;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
  client?: Client;
};

export default function WorksiteBaseInfos(props: WorksiteBaseInfosProps) {
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [sendLoading, setSendLoading] = React.useState<boolean>(false);
  const [worksiteInfos, setWorksiteInfos] = React.useState<WorksiteInfos>(
    props.worksite
  );
  const sex: { [key: number]: string } = {
    0: "",
    1: "M.",
    2: "Mme.",
    9: "",
  };

  React.useEffect(() => {
    setWorksiteInfos(props.worksite);
  }, [props.worksite]);
  function handleChange(event: React.BaseSyntheticEvent) {
    const param: keyof Worksite = event.currentTarget.name;

    setWorksiteInfos({
      ...worksiteInfos,
      [param]: event.currentTarget.value,
    });

    if (props.onChange) {
      props.onChange({
        ...worksiteInfos,
        [param]: event.currentTarget.value,
      });
    }
  }
  function clientInfos(client: Client) {
    return (
      <div className="flex flex-col text-grey-1 font-normal">
        <div className="flex gap-4">
          <span className="text-darkgrey font-bold">
            {`${sex[client.sex]} ${client.firstname} ${client.lastname}`}
          </span>
          <a href={parsePhoneNumber(client.cellPhone).getURI()}>
            <FontAwesomeIcon
              icon={faPhone}
              className="mr-2"
              flip="horizontal"
            />
            {parsePhoneNumber(client.cellPhone).formatNational()}
          </a>
          <a href={`mailto:${client.email}`}>
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Email
          </a>
        </div>
        {client.isProfessional && (
          <span>
            {`Raison sociale : ${client.companyName} - ${client.companyStatus}`}
          </span>
        )}
        <span>{`${client.address} - ${client.zipCode} ${client.city}`}</span>
        {client.homePhone && (
          <span>
            Fixe :
            <a
              href={parsePhoneNumber(client.homePhone).getURI()}
              className="ml-2"
            >
              {parsePhoneNumber(client.homePhone).formatNational()}
            </a>
          </span>
        )}
      </div>
    );
  }

  function handleSendConnexion(event: React.SyntheticEvent) {
    event.preventDefault();
    setSendLoading(true);
    checkTokenAndFetch(
      `/api/clients/${props.client?.id}/send-connexion`
    )
      .then(() => {
        toast.success(
          "Un email de connexion vient d'être envoyé au client"
        );
        setSendLoading(false);
      })
      .catch((err) => {
        toast.error("Une erreur est survenue");
        setSendLoading(false);
      });
  }

  return (
    <form
      ref={(ref) => {
        if (props.formRef) {
          props.formRef.current = ref;
        }
      }}
      onSubmit={(event) => {
        event.preventDefault();
        if (props.onSubmit) {
          setIsLoading(true);
          props.onSubmit(worksiteInfos).finally(() => {
            setIsLoading(false);
          });
        }
      }}
    >
      <Section title="Informations générales">
        <Card className="max-h-max">
          {props.client && props.client?.id !== 0 && (
            <div className="flex justify-between">
              <span className="font-extrabold">
                Client{props.client.related && "s"}
                {clientInfos(props.client)}
                {props.client.related &&
                  clientInfos({ ...props.client, ...props.client.related })}
              </span>
              <BaseButton
                type="button"
                styleType="csv"
                content="Envoyer infos connexion"
                icon={
                  sendLoading
                    ? {
                        icon: faSpinner,
                        className: "animate-spin",
                      }
                    : undefined
                }
                onClick={(e) => handleSendConnexion(e)}
              />
            </div>
          )}
          <div className="flex flex-row gap-4">
            <BaseLabel required label="Nom du chantier">
              <BaseInput
                required
                type="text"
                name="name"
                value={worksiteInfos.name}
                onChange={handleChange}
              />
            </BaseLabel>
            <BaseLabel required label="Section cadastrale">
              <BaseInput
                required
                type="text"
                name="cadastralSection"
                value={worksiteInfos.cadastralSection}
                onChange={handleChange}
              />
            </BaseLabel>
          </div>
          <BaseLabel required label="Adresse">
            <BaseInput
              required
              type="text"
              name="address"
              value={worksiteInfos.address}
              onChange={handleChange}
            />
          </BaseLabel>
          <div className="flex flex-row gap-4">
            <BaseLabel required label="Code postal">
              <BaseInput
                required
                type="text"
                pattern="[0-9]{5}"
                name="zipCode"
                value={worksiteInfos.zipCode}
                onChange={handleChange}
              />
            </BaseLabel>
            <BaseLabel required label="Ville">
              <BaseInput
                required
                type="text"
                name="city"
                value={worksiteInfos.city}
                onChange={handleChange}
              />
            </BaseLabel>
          </div>
          {props.onLocalize && (
            <BaseButton
              className="self-end"
              type="button"
              styleType="primary"
              content="Localiser"
              icon={
                isLoading
                  ? {
                      icon: faSpinner,
                      className: "animate-spin",
                    }
                  : undefined
              }
              onClick={() => {
                if (props.onLocalize) {
                  setIsLoading(true);
                  props.onLocalize().finally(() => {
                    setIsLoading(false);
                  });
                }
              }}
            />
          )}
          {!props.formRef && (
            <BaseButton
              className="self-end"
              type="submit"
              styleType="primary"
              content="Enregistrer"
              icon={
                isLoading
                  ? {
                      icon: faSpinner,
                      className: "animate-spin",
                    }
                  : undefined
              }
            />
          )}
        </Card>
      </Section>
    </form>
  );
}
