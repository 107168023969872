import React from "react";
import { twMerge } from "tailwind-merge";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

export type BaseButtonStyle =
  | "primary"
  | "secondary"
  | "cancel"
  | "danger"
  | "csv";
export type BaseButtonType = "button" | "submit" | "reset" | undefined;

type BaseButtonProps = {
  disabled?: boolean;
  title?: string;
  styleType: BaseButtonStyle;
  type: BaseButtonType;
  content: string;
  icon?: FontAwesomeIconProps;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  function BaseButton(props, ref) {
    function styleType() {
      switch (props.styleType) {
        case "primary":
          return "bg-green-1 hover:bg-green-2";
        case "secondary":
          return "bg-white text-green-1 box-border border-2 border-green-1 hover:bg-green-1 hover:text-white";
        case "cancel":
          return "bg-grey-1 hover:bg-darkgrey";
        case "danger":
          return "bg-red-1 hover:bg-red-2";
        case "csv":
          return "bg-green-excel hover:bg-green-1";
      }
    }

    return (
      <button
        ref={ref}
        disabled={props.disabled}
        title={props.title}
        type={props.type}
        className={twMerge(
          "text-white font-bold text-base rounded h-8 px-2 mb-2 disabled:opacity-50",
          styleType(),
          props.className
        )}
        onClick={props.onClick}
      >
        {props.icon && (
          <FontAwesomeIcon
            icon={props.icon.icon}
            className={twMerge("mr-2", props.icon.className)}
          />
        )}
        {props.content}
      </button>
    );
  }
);

export default BaseButton;
