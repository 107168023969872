import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Loading() {
  return (
    <div className="w-full h-full flex justify-center">
      <FontAwesomeIcon icon={faSpinner} className="animate-spin text-6xl" />
    </div>
  );
}
