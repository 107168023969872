import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Memo } from "../routes/worksiteTasksMemos";
import CardContentItem from "./CardContentItem";
import ImagesAPI from "./ImagesAPI";

type MemoSummaryProps = {
  memo: Memo;
  link?: string;
  onEdit?: () => void;
  onDelete?: () => void;
};

export default function MemoSummary(props: MemoSummaryProps) {
  return (
    <CardContentItem
      link={props.link}
      informations={
        <div className="flex">
          {new Date(props.memo.createdAt).toLocaleDateString()}
          <div className="grow"></div>
          <div className="flex gap-2 text-grey-2">
            {props.onEdit && (
              <FontAwesomeIcon
                className="hover:text-green-1 cursor-pointer"
                icon={faPen}
                onClick={props.onEdit}
              />
            )}
            {props.onDelete && (
              <FontAwesomeIcon
                className="hover:text-green-1 cursor-pointer"
                icon={faTrash}
                onClick={props.onDelete}
              />
            )}
          </div>
        </div>
      }
      title={
        props.memo.target
          ? (props.memo.target.companyName
              ? `${props.memo.target.companyName} - `
              : "") +
            `${props.memo.target.firstname} ${props.memo.target.lastname}`
          : "TOUS"
      }
    >
      {props.memo.message}
      <div className="flex flex-col gap-2">
        <ImagesAPI images={props.memo.attachedFiles} />
      </div>
    </CardContentItem>
  );
}
