import React from "react";
import BaseInput from "../components/BaseInput";
import BaseRadio from "../components/BaseRadio";
import Card from "../components/Card";
import DoubleColumn from "../components/DoubleColumn";
import Section from "../components/Section";
import BaseLabel from "../components/BaseLabel";
import BaseSelect from "../components/BaseSelect";
import { parsePhoneNumber } from "libphonenumber-js";

export type ClientInput = {
  isProfessional: boolean;
  companyName: string;
  companyStatus: string;
  sex: number;
  lastname: string;
  firstname: string;
  address: string;
  zipCode: string;
  city: string;
  cellPhone: string;
  homePhone?: string;
  email: string;
  password: string;
  repeatPassword: string;
  related?: Omit<
    ClientInput,
    "related" | "isProfessional" | "companyName" | "companyStatus"
  >;
};

type ClientsNewFormProps = {
  initialValues: ClientInput;
  handler: (client: ClientInput) => void;
};

export default function ClientsNewForm(props: ClientsNewFormProps) {
  const [inputs, setInputs] = React.useState<ClientInput>(props.initialValues);

  React.useEffect(() => {
    const ret: ClientInput = JSON.parse(JSON.stringify(inputs));
    if (ret.cellPhone) {
      try {
        const phone = parsePhoneNumber(ret.cellPhone, "FR");
        ret.cellPhone = phone.number;
      } catch (error) {
        ret.cellPhone = "";
      }
    }
    if (ret.homePhone) {
      try {
        const phone = parsePhoneNumber(ret.homePhone, "FR");
        ret.homePhone = phone.number;
      } catch (error) {
        ret.homePhone = "";
      }
    }
    if (ret.related) {
      if (ret.related.cellPhone) {
        try {
          const phone = parsePhoneNumber(ret.related.cellPhone, "FR");
          ret.related.cellPhone = phone.number;
        } catch (error) {
          ret.related.cellPhone = "";
        }
      }
      if (ret.related.homePhone) {
        try {
          const phone = parsePhoneNumber(ret.related.homePhone, "FR");
          ret.related.homePhone = phone.number;
        } catch (error) {
          ret.related.homePhone = "";
        }
      }
    }
    props.handler(ret);
  }, [inputs]);

  return (
    <DoubleColumn
      left={
        <>
          <Section title="Contact principal">
            <Card className="max-h-max">
              <BaseLabel required label="Nature du client">
                <div className="flex flex-row gap-6">
                  <BaseRadio
                    className="shrink"
                    label="Particulier"
                    value={false}
                    currentValue={inputs.isProfessional}
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        isProfessional: !!event.currentTarget.value,
                      });
                    }}
                  />
                  <BaseRadio
                    className="shrink"
                    label="Professionnel"
                    value={true}
                    currentValue={inputs.isProfessional}
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        isProfessional: !!event.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </BaseLabel>
              {inputs.isProfessional ? (
                <div className="flex flex-row gap-4">
                  <BaseLabel required label="Raison sociale">
                    <BaseInput
                      required
                      type="text"
                      value={inputs.companyName}
                      onChange={(event) => {
                        setInputs({
                          ...inputs,
                          companyName: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel required label="Forme juridique">
                    <BaseInput
                      required
                      type="text"
                      value={inputs.companyStatus}
                      onChange={(event) => {
                        setInputs({
                          ...inputs,
                          companyStatus: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                </div>
              ) : null}
              <div className="flex flex-row gap-4">
                <BaseLabel required label="Civilité" className="shrink w-min">
                  <BaseSelect
                    required
                    value={inputs.sex}
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        sex: +event.currentTarget.value,
                      });
                    }}
                    options={[
                      { label: "M.", value: 1 },
                      { label: "Mme.", value: 2 },
                    ]}
                  />
                </BaseLabel>
                <BaseLabel required label="Nom">
                  <BaseInput
                    required
                    type="text"
                    value={inputs.lastname}
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        lastname: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
                <BaseLabel required label="Prénom">
                  <BaseInput
                    required
                    type="text"
                    value={inputs.firstname}
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        firstname: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
              </div>
              <BaseLabel required label="Adresse">
                <BaseInput
                  required
                  type="text"
                  value={inputs.address}
                  onChange={(event) => {
                    setInputs({
                      ...inputs,
                      address: event.currentTarget.value,
                    });
                  }}
                />
              </BaseLabel>
              <div className="flex flex-row gap-4">
                <BaseLabel required label="Code postal">
                  <BaseInput
                    required
                    type="text"
                    pattern="[0-9]{5}"
                    value={inputs.zipCode}
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        zipCode: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
                <BaseLabel required label="Ville">
                  <BaseInput
                    required
                    type="text"
                    value={inputs.city}
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        city: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
              </div>
              <div className="flex flex-row gap-4">
                <BaseLabel required label="Téléphone Port.">
                  <BaseInput
                    required
                    type="text"
                    value={inputs.cellPhone}
                    pattern="[0-9]{10}"
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        cellPhone: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
                <BaseLabel label="Téléphone Fixe">
                  <BaseInput
                    type="text"
                    value={inputs.homePhone}
                    pattern="[0-9]{10}"
                    onChange={(event) => {
                      setInputs({
                        ...inputs,
                        homePhone: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
              </div>
            </Card>
          </Section>
          <Section title="Identifiant du contact principal">
            <Card className="max-h-max">
              <BaseLabel required label="Adresse email">
                <BaseInput
                  required
                  type="email"
                  value={inputs.email}
                  onChange={(event) => {
                    setInputs({
                      ...inputs,
                      email: event.currentTarget.value,
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel required label="Mot de passe">
                <BaseInput
                  required
                  autoComplete="new-password"
                  type="password"
                  value={inputs.password}
                  onChange={(event) => {
                    setInputs({
                      ...inputs,
                      password: event.currentTarget.value,
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel required label="Confirmation du mot de passe">
                <BaseInput
                  required
                  autoComplete="new-password"
                  repeat={inputs.password}
                  type="password"
                  value={inputs.repeatPassword}
                  onChange={(event) => {
                    setInputs({
                      ...inputs,
                      repeatPassword: event.currentTarget.value,
                    });
                  }}
                />
              </BaseLabel>
            </Card>
          </Section>
        </>
      }
      right={
        <>
          <Card>
            <BaseLabel required label="Contact secondaire ?">
              <div className="flex flex-row gap-6">
                <BaseRadio
                  className="shrink"
                  label="Oui"
                  value={true}
                  currentValue={!!inputs.related}
                  onChange={() => {
                    setInputs({
                      ...inputs,
                      related: {
                        sex: 0,
                        lastname: "",
                        firstname: "",
                        address: "",
                        zipCode: "",
                        city: "",
                        cellPhone: "",
                        homePhone: "",
                        email: "",
                        password: "",
                        repeatPassword: "",
                      },
                    });
                  }}
                />
                <BaseRadio
                  className="shrink"
                  label="Non"
                  value={false}
                  currentValue={!!inputs.related}
                  onChange={() => {
                    setInputs({
                      ...inputs,
                      related: undefined,
                    });
                  }}
                />
              </div>
            </BaseLabel>
          </Card>
          {inputs.related && (
            <>
              <Section title="Contact secondaire">
                <Card className="max-h-max">
                  <div className="flex flex-row gap-4">
                    <BaseLabel
                      required
                      label="Civilité"
                      className="shrink w-min"
                    >
                      <BaseSelect
                        required
                        value={inputs.related.sex}
                        onChange={(event) => {
                          if (inputs.related) {
                            setInputs({
                              ...inputs,
                              related: {
                                ...inputs.related,
                                sex: +event.currentTarget.value,
                              },
                            });
                          }
                        }}
                        options={[
                          { label: "M.", value: 1 },
                          { label: "Mme.", value: 2 },
                        ]}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Nom">
                      <BaseInput
                        required
                        type="text"
                        value={inputs.related.lastname}
                        onChange={(event) => {
                          if (inputs.related) {
                            setInputs({
                              ...inputs,
                              related: {
                                ...inputs.related,
                                lastname: event.currentTarget.value,
                              },
                            });
                          }
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Prénom">
                      <BaseInput
                        required
                        type="text"
                        value={inputs.related.firstname}
                        onChange={(event) => {
                          if (inputs.related) {
                            setInputs({
                              ...inputs,
                              related: {
                                ...inputs.related,
                                firstname: event.currentTarget.value,
                              },
                            });
                          }
                        }}
                      />
                    </BaseLabel>
                  </div>
                  <BaseLabel required label="Adresse">
                    <BaseInput
                      required
                      type="text"
                      value={inputs.related.address}
                      onChange={(event) => {
                        if (inputs.related) {
                          setInputs({
                            ...inputs,
                            related: {
                              ...inputs.related,
                              address: event.currentTarget.value,
                            },
                          });
                        }
                      }}
                    />
                  </BaseLabel>
                  <div className="flex flex-row gap-4">
                    <BaseLabel required label="Code postal">
                      <BaseInput
                        required
                        type="text"
                        pattern="[0-9]{5}"
                        value={inputs.related.zipCode}
                        onChange={(event) => {
                          if (inputs.related) {
                            setInputs({
                              ...inputs,
                              related: {
                                ...inputs.related,
                                zipCode: event.currentTarget.value,
                              },
                            });
                          }
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Ville">
                      <BaseInput
                        required
                        type="text"
                        value={inputs.related.city}
                        onChange={(event) => {
                          if (inputs.related) {
                            setInputs({
                              ...inputs,
                              related: {
                                ...inputs.related,
                                city: event.currentTarget.value,
                              },
                            });
                          }
                        }}
                      />
                    </BaseLabel>
                  </div>
                  <div className="flex flex-row gap-4">
                    <BaseLabel required label="Téléphone Port.">
                      <BaseInput
                        required
                        type="text"
                        value={inputs.related.cellPhone}
                        pattern="[0-9]{10}"
                        onChange={(event) => {
                          if (inputs.related) {
                            setInputs({
                              ...inputs,
                              related: {
                                ...inputs.related,
                                cellPhone: event.currentTarget.value,
                              },
                            });
                          }
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel label="Téléphone Fixe">
                      <BaseInput
                        type="text"
                        value={inputs.related.homePhone}
                        pattern="[0-9]{10}"
                        onChange={(event) => {
                          if (inputs.related) {
                            setInputs({
                              ...inputs,
                              related: {
                                ...inputs.related,
                                homePhone: event.currentTarget.value,
                              },
                            });
                          }
                        }}
                      />
                    </BaseLabel>
                  </div>
                </Card>
              </Section>
              <Section title="Identifiant du contact secondaire">
                <Card className="max-h-max">
                  <BaseLabel required label="Adresse email">
                    <BaseInput
                      required
                      type="email"
                      value={inputs.related.email}
                      onChange={(event) => {
                        if (inputs.related) {
                          setInputs({
                            ...inputs,
                            related: {
                              ...inputs.related,
                              email: event.currentTarget.value,
                            },
                          });
                        }
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel required label="Mot de passe">
                    <BaseInput
                      required
                      autoComplete="new-password"
                      type="password"
                      value={inputs.related.password}
                      onChange={(event) => {
                        if (inputs.related) {
                          setInputs({
                            ...inputs,
                            related: {
                              ...inputs.related,
                              password: event.currentTarget.value,
                            },
                          });
                        }
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel required label="Confirmation du mot de passe">
                    <BaseInput
                      required
                      autoComplete="new-password"
                      repeat={inputs.related.password}
                      type="password"
                      value={inputs.related.repeatPassword}
                      onChange={(event) => {
                        if (inputs.related) {
                          setInputs({
                            ...inputs,
                            related: {
                              ...inputs.related,
                              repeatPassword: event.currentTarget.value,
                            },
                          });
                        }
                      }}
                    />
                  </BaseLabel>
                </Card>
              </Section>
            </>
          )}
        </>
      }
    />
  );
}
