import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import BaseButton from "./BaseButton";
import BaseInput from "./BaseInput";
import BaseLabel from "./BaseLabel";
import Card from "./Card";
import CardContent from "./CardContent";
import Section from "./Section";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Map, Marker as HTMLMarker } from "leaflet";
import { marker } from "../utils";

export type Position = {
  lat: number;
  lng: number;
};

type WorksiteLocationProps = {
  position: Position;
  setPosition?: (pos: Position) => Promise<void>;
};

export default function WorksiteLocation(props: WorksiteLocationProps) {
  const mapRef = React.useRef<Map>(null);
  const markerRef = React.useRef<HTMLMarker>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [pos, setPos] = React.useState<{ lat: string; lng: string }>({
    lat: props.position.lat.toString(),
    lng: props.position.lng.toString(),
  });
  const map = (
    <MapContainer
      ref={mapRef}
      className="h-80 rounded-md z-0"
      center={[
        props.position.lat ? props.position.lat : 0,
        props.position.lng ? props.position.lng : 0,
      ]}
      zoom={10}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker
        ref={markerRef}
        draggable={!!props.setPosition}
        eventHandlers={{
          dragend: () => {
            const marker = markerRef.current;
            if (marker != null && props.setPosition) {
              setIsLoading(true);
              props
                .setPosition(marker.getLatLng())
                .then(() => {
                  setPos({
                    lat: marker.getLatLng().lat.toString(),
                    lng: marker.getLatLng().lng.toString(),
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          },
        }}
        icon={marker}
        position={[props.position.lat, props.position.lng]}
      />
    </MapContainer>
  );

  React.useEffect(() => {
    mapRef.current?.setView([props.position.lat, props.position.lng]);
    setPos({
      lat: props.position.lat.toString(),
      lng: props.position.lng.toString(),
    });
  }, [props.position, mapRef.current]);
  function handleChange(event: React.BaseSyntheticEvent) {
    setPos({
      ...pos,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  }

  return (
    <Section title="Localisation">
      {props.setPosition ? (
        <Card className="max-h-max">
          <CardContent>
            {[
              <form
                key={0}
                onSubmit={(event) => {
                  event.preventDefault();
                  if (props.setPosition) {
                    setIsLoading(true);
                    props
                      .setPosition({
                        lat: +pos.lat,
                        lng: +pos.lng,
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }
                }}
              >
                <div className="flex gap-4">
                  <BaseLabel required label="Latitude" className="flex gap-2.5">
                    <BaseInput
                      autoComplete="off"
                      required
                      name="lat"
                      type="text"
                      value={pos.lat}
                      onChange={handleChange}
                    />
                  </BaseLabel>
                  <BaseLabel
                    required
                    label="Longitude"
                    className="flex gap-2.5"
                  >
                    <BaseInput
                      autoComplete="off"
                      required
                      name="lng"
                      type="text"
                      value={pos.lng}
                      onChange={handleChange}
                    />
                  </BaseLabel>
                </div>
                <BaseButton
                  styleType="primary"
                  type="submit"
                  content="Rechercher"
                  icon={
                    isLoading
                      ? {
                          icon: faSpinner,
                          className: "animate-spin",
                        }
                      : undefined
                  }
                  className="float-right mt-3"
                />
              </form>,
            ]}
          </CardContent>
          {map}
        </Card>
      ) : (
        map
      )}
    </Section>
  );
}
