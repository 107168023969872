import React from "react";
import { twMerge } from "tailwind-merge";
import Badge from "./Badge";

type TabProps = {
  title: string;
  value?: number;
  isActive: boolean;
  onClick: () => void;
};

export default function Tab(props: TabProps) {
  return (
    <div
      className={twMerge(
        "px-5 py-3 font-bold cursor-pointer",
        props.isActive
          ? "bg-white border-t-2 border-green-1 border-x border-x-grey-4"
          : "bg-grey-5 text-grey-2"
      )}
      onClick={props.onClick}
    >
      {props.title}
      {props.value !== undefined && (
        <Badge
          value={props.value}
          type={props.isActive ? "primary" : "inactive"}
        />
      )}
    </div>
  );
}
