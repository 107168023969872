import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Badge from "./Badge";
import Card from "./Card";
import CardContent from "./CardContent";
import CardContentItem from "./CardContentItem";
import Section from "./Section";
import { useCheckTokenAndFetch } from "../auth";
import Loading from "./Loading";

type WorksitesList = Array<{
  id: number;
  state: number;
  isOpen: boolean;
  name: string;
  quotations: Array<{
    id: number;
    isSigned: boolean;
    reference: string;
    createdAt: Date;
    amountIncludingTaxes: number;
    partner: {
      category: {
        name: string;
      };
      companyName: string;
      firstname: string;
      lastname: string;
    };
    label: string;
  }>;
}>;

export default function TheQuotationList() {
  const [worksites, setWorksites] = React.useState<WorksitesList>();
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/get_quotations`, {
      method: "GET",
    }).then((worksites: { "hydra:member": WorksitesList }) => {
      setWorksites(
        worksites["hydra:member"].filter((worksite) => {
          worksite.isOpen = true;
          worksite.quotations = worksite.quotations.filter((quotation) => {
            quotation.createdAt = new Date(quotation.createdAt);
            return !quotation.isSigned;
          });
          return [0, 1].includes(worksite.state) && worksite.quotations.length;
        })
      );
    });
  }, [navigate]);

  return (
    <Section
      title={
        <>
          Devis en attente de signature
          {worksites && (
            <Badge
              value={worksites.reduce(
                (length, quotation) => length + quotation.quotations.length,
                0
              )}
              type="danger"
            />
          )}
        </>
      }
    >
      <Card>
        {worksites ? (
          worksites.map((worksite, wIndex) => (
            <CardContent
              key={wIndex}
              title={
                <>
                  <Link to={`/chantiers/${worksite.id}/financier`}>
                    <Badge value={worksite.quotations.length} type="primary" />
                    {worksite.name}
                  </Link>
                  <FontAwesomeIcon
                    icon={worksite.isOpen ? faAngleDown : faAngleUp}
                    className={
                      "float-right text-darkgrey text-md cursor-pointer"
                    }
                    onClick={() => {
                      worksite.isOpen = !worksite.isOpen;
                      setWorksites(worksites.slice());
                    }}
                  />
                </>
              }
            >
              {worksite.isOpen
                ? worksite.quotations.map((quotation, qIndex) => (
                    <CardContentItem
                      key={qIndex}
                      informations={
                        <>
                          <Link
                            to={`/chantiers/${worksite.id}/financier#devis-${quotation.id}`}
                            className="font-medium"
                          >
                            {quotation.reference}
                          </Link>{" "}
                          - {quotation.createdAt.toLocaleDateString()}
                        </>
                      }
                      title={`${quotation.partner.category.name} - ${quotation.partner.companyName} - ${quotation.partner.firstname} ${quotation.partner.lastname}`}
                      badge={
                        <span
                          className={
                            "inline-flex items-center justify-center px-3 py-2 text-sm font-bold leading-none rounded-sm bg-red-2 bg-opacity-20 text-red-2"
                          }
                        >
                          {Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(quotation.amountIncludingTaxes)}
                          <span className="text-[0.5rem] place-self-end pl-1">
                            TTC
                          </span>
                        </span>
                      }
                    >
                      {quotation.label}
                    </CardContentItem>
                  ))
                : []}
            </CardContent>
          ))
        ) : (
          <Loading />
        )}
      </Card>
    </Section>
  );
}
