import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useCheckTokenAndFetch } from "../auth";
import TheSidebar from "../components/TheSidebar";
import TheTopbar from "../components/TheTopbar";

type WorksitesList = Array<{
  "@id": string;
  id: number;
  name: string;
  lots: Array<{
    reports: Array<{
      id: number;
      state: number;
      worksite: string | null;
    }>;
    phases: Array<{
      beginDate: string;
      duration: number;
    }>;
  }>;
  generalReports: Array<{
    id: number;
    state: number;
    worksite: string | null;
  }>;
  tasks: Array<{
    id: number;
    isDone: boolean;
  }>;
  memos: Array<{
    id: number;
  }>;
  state: number;
}>;

const WorksitesContext = React.createContext<
  [WorksitesList, React.Dispatch<React.SetStateAction<WorksitesList>>]
>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

export function useWorksites() {
  return React.useContext(WorksitesContext);
}

export default function Layout() {
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const navigate = useNavigate();
  const [worksites, setWorksites] = React.useState<WorksitesList>([]);

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/get_listing`, {
      method: "GET",
    }).then((worksites: { "hydra:member": WorksitesList }) => {
      setWorksites(worksites["hydra:member"]);
    });
  }, [navigate]);

  return (
    <WorksitesContext.Provider value={[worksites, setWorksites]}>
      <div className="flex flex-col h-screen">
        <TheTopbar className="shrink-0" />
        <div className="flex flex-row grow overflow-hidden">
          <TheSidebar className="shrink-0" />
          <div className="grow bg-grey-7 overflow-y-scroll p-8">
            <Outlet />
          </div>
        </div>
      </div>
    </WorksitesContext.Provider>
  );
}
