import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCheckTokenAndFetch } from "../auth";
import Badge from "./Badge";
import Card from "./Card";
import CardContent from "./CardContent";
import Loading from "./Loading";
import Section from "./Section";
import TaskSummary, { Task } from "./TaskSummary";

type Worksites = Array<{
  id: number;
  state: number;
  name: string;
  tasks: Array<Task>;
}>;

export default function TheTaskList() {
  const [worksites, setWorksites] = React.useState<Worksites>();
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/get_tasks`, {
      method: "GET",
    }).then((worksites: { "hydra:member": Worksites }) => {
      setWorksites(
        worksites["hydra:member"].filter((worksite) => {
          worksite.tasks = worksite.tasks.filter((task) => !task.isDone);
          return [0, 1].includes(worksite.state) && worksite.tasks.length;
        })
      );
    });
  }, [navigate]);

  return (
    <Section
      title={
        <>
          Tâches en cours
          {worksites !== undefined && (
            <Badge
              value={worksites.reduce(
                (length, worksite) => length + worksite.tasks.length,
                0
              )}
              type="danger"
            />
          )}
        </>
      }
    >
      <Card>
        {worksites ? (
          worksites.map((worksite, wIndex) => (
            <CardContent
              key={wIndex}
              title={
                <Link to={`/chantiers/${worksite.id}/taches-infos`}>
                  <Badge value={worksite.tasks.length} type="primary" />
                  {worksite.name}
                </Link>
              }
            >
              {worksite.tasks.map((task, tIndex) => (
                <TaskSummary
                  key={tIndex}
                  task={task}
                  onToggleState={() => {
                    task.isDone = !task.isDone;
                    setWorksites(worksites.slice());
                  }}
                />
              ))}
            </CardContent>
          ))
        ) : (
          <Loading />
        )}
      </Card>
    </Section>
  );
}
