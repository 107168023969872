import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useCheckTokenAndFetch } from "../auth";
import ThePlanningHeader from "../components/ThePlanningHeader";
import PlanningLot from "../components/PlanningLot";
import PlanningLotPhase from "../components/PlanningLotPhase";
import BaseButton from "../components/BaseButton";

type Phase = {
  id: number;
  name: string;
  position: number;
  beginDate: string;
  duration: number;
};

type Lot = {
  id: number;
  name: string;
  position: number;
  color: string;
  phases: Array<Phase>;
};

type WorksitePlanningType = {
  lots: Array<Lot>;
};

export default function WorksitePlanning() {
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const scrollableRef = React.useRef<HTMLDivElement>(null);
  const [zoom, setZoom] = React.useState<number>(100);
  const [weekfocus, setWeekfocus] = React.useState<Date>(new Date());
  const [currentmonth, setCurrentmonth] = React.useState<Date>(new Date());
  const [phasefocusdate, setPhasefocusdate] = React.useState<string>("");
  const [memoizedBegin, setMemoizedBegin] = React.useState<number>(0);
  // const [active, setActive] = React.useState<Array<number>>([]);
  const [worksite, setWorksite] = React.useState<WorksitePlanningType>();
  const [hovered, setHovered] = React.useState<{
    begin: Date;
    duration: number;
  }>({
    begin: new Date(),
    duration: 0,
  });

  const memoizedNbDays = React.useMemo(() => {
    const dates = worksite?.lots.flatMap((lot) => lot.phases);
    let begin = Infinity;
    let end = -Infinity;
    dates?.forEach((date) => {
      begin = Math.min(begin, new Date(date.beginDate).getTime());
      end = Math.max(
        end,
        new Date(date.beginDate).getTime() + date.duration * 24 * 60 * 60 * 1000
      );
    });
    return begin === Infinity ? 0 : Math.round((end - begin) / 1000 / 60 / 60 / 24);
  }, [worksite]);

  React.useEffect(() => {
    const dates = worksite?.lots
      .flatMap((lot) => lot.phases)
      .map((phase) => phase.beginDate);
    setMemoizedBegin(dates
      ? Math.min(...dates.map((date) => new Date(date).getTime()))
      : 0
    );

    const date = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth()));
    const month = date.getMonth();
    date.setMonth(month + 1);
    setCurrentmonth(date);
  }, [worksite]);


  React.useEffect(() => {
      setTwoWeeks();
      setDateFocusPhase('2weeks');
  }, [worksite !== undefined]);

  function setLastWeek() {
    const today = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const day = today.getDay();
    const diff = today.getDate() + (7 - day)
    const target = new Date(today.setDate(diff));
    setWeekfocus(new Date(target));

    setDateFocusPhase('week');

    if(memoizedNbDays !== 0) {
      // const nbWeeks = Math.ceil(memoizedNbDays / 7);
      const nbWeeks = memoizedNbDays / 7;
      setZoom(100 * nbWeeks);
    }
  }

  function setTwoWeeks() {
    const today = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const day = today.getDay() - 7;
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    const target = new Date(today.setDate(diff - 1));
    setWeekfocus(new Date(target));

    setDateFocusPhase('2weeks');

    if(memoizedNbDays !== 0) {
      // const nbWeeks = Math.ceil(memoizedNbDays / 14);
      const nbWeeks = memoizedNbDays / 14;
      setZoom(100 * nbWeeks);
    }
  }

  function setMonthfocus() {
    if(memoizedNbDays !== 0) {
      // const nbMonths = Math.ceil(memoizedNbDays / 33);
      const nbMonths = memoizedNbDays / 31;
      setZoom(100 * nbMonths);
    }
    setDateFocusPhase('month');
    if (currentmonth !== undefined) {
      const target = scrollableRef.current?.querySelector(
        `[data-focus="monthfocus"]`
      );
      if(target) {
        scrollableRef.current?.clientHeight;
        target.scrollIntoView({
          block: "center",
          inline: "end",
        });
      }
    }
  }

  function setDateFocusPhase(selected: string) {
    const today = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const weekDay = today.getDay();
    const monthDay = today.getDate();
    let target;
    switch (selected) {
      case 'week':
        target = new Date(today.setDate(monthDay - weekDay + 1));
        break;
      case '2weeks':
        target = new Date(today.setDate(monthDay - weekDay - 6));
        break;
      case 'month':
        target = new Date(today.setDate(1));
        break;
      case 'global':
        target = new Date(today);
        break;
      default:
        target = new Date(today);
        break;
    }
    setPhasefocusdate(target.toISOString());
  }

  function setPhaseFocus() {
    if (phasefocusdate != undefined && phasefocusdate !== "") {
      const target = scrollableRef.current?.querySelector(
        `[data-phasefocus="phasefocus"]`
      );
      if (target) {
        target.scrollIntoView({
          block: "start",
          inline: "start",
        });
      }
    }
  }

  React.useEffect(() => {
    if (zoom !== undefined) {
      const target = scrollableRef.current?.querySelector(
        `[data-focus="monthfocus"]`
      );
      if (target) {
        target.scrollIntoView({
          block: "center",
          inline: "end",
        });
      }
    }
    setPhaseFocus();
  }, [zoom]);

  React.useEffect(() => {
    if (weekfocus !== undefined) {
      const target = scrollableRef.current?.querySelector(
        `[data-focus="weekfocus"]`
      );
      if (target) {
        target.scrollIntoView({
          block: "center",
          inline: "end",
        });
      }
    }
    setPhaseFocus();
  }, [weekfocus]);

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/${params.id}/get_planning`, {
      method: "GET",
    }).then((worksite: WorksitePlanningType) => {
      setWorksite(worksite);
      // setActive(worksite.lots.map((lot) => lot.id));
    });
  }, [params.id]);

  return worksite !== undefined ? (
    <div className="overflow-x-scroll max-h-[70vh]" ref={scrollableRef}>
      <table className="relative">
        <thead className="sticky top-0 z-20 bg-grey-7">
          <tr>
            <th className="h-20 bg-grey-7 flex flex-wrap px-1 w-48 z-30 sticky left-0 gap-1">
              <BaseButton
                className="w-20 text-xs mb-0 mr-1"
                type="button"
                styleType="primary"
                content="Semaine"
                onClick={() => {
                  setLastWeek();
                }}
              />
              <BaseButton
                className="w-20 text-xs mb-0 mr-1"
                type="button"
                styleType="primary"
                content="2 semaines"
                onClick={() => {
                  setTwoWeeks();
                }}
              />
              <BaseButton
                className="w-20 text-xs mb-0 mr-1"
                type="button"
                styleType="primary"
                content="Mois"
                onClick={() => {
                  setMonthfocus();
                }}
              />
              <BaseButton
                className="w-20 text-xs mb-0 mr-1"
                type="button"
                styleType="primary"
                content="Global"
                onClick={() => {
                  setZoom(100);
                }}
              />
            </th>
            <th>
              <ThePlanningHeader
                begin={memoizedBegin}
                nbDays={memoizedNbDays}
                zoom={zoom}
                weekfocus={weekfocus}
                currentmonth={currentmonth}
                onHover={(b, d) => {
                  setHovered({
                    begin: b,
                    duration: d,
                  });
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody className="overflow-y-scroll">
          {worksite.lots
            .flatMap((lot) => lot.phases.map((phase) => ({...phase, color: lot.color})))
            .sort((a, b) => a.beginDate.localeCompare(b.beginDate))
            .map((phase, pIndex) => (
              <PlanningLotPhase
                key={pIndex}
                scrollableRef={scrollableRef}
                tableWidth={scrollableRef.current?.clientWidth || 0}
                hovered={hovered}
                nbDays={memoizedNbDays}
                begin={memoizedBegin}
                phasefocusdate={phasefocusdate}
                lotColor={phase.color}
                phase={phase}
                zoom={zoom}
                setPhase={(id, p) => {
                  setWorksite({
                    ...worksite,
                    lots: worksite.lots.map((lot) => ({
                      ...lot,
                      phases: lot.phases.map((phase) => {
                        if (phase.id === id) {
                          return p;
                        }
                        return phase;
                      }),
                    })),
                  });
                }}
              />
            ))
          }
        </tbody>
      </table>
    </div>
  ) : (
    <Loading />
  );
}
