import {
  faFileCsv,
  faPen,
  faPlus,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams } from "react-router-dom";
import Amount from "../components/Amount";
import BaseButton from "../components/BaseButton";
import BaseForm from "../components/BaseForm";
import BaseInput from "../components/BaseInput";
import BaseInputFile from "../components/BaseInputFile";
import BaseLabel from "../components/BaseLabel";
import BasePopup from "../components/BasePopup";
import BasePopupConfirmDelete from "../components/BasePopupConfirmDelete";
import BaseRadio from "../components/BaseRadio";
import BaseSelect from "../components/BaseSelect";
import DownloadFileIcon from "../components/DownloadFileIcon";
import Loading from "../components/Loading";
import Table from "../components/Table";
import { currencyFormat } from "../utils";
import { useCheckTokenAndFetch } from "../auth";
import { File, PartnerAPI } from "./worksite";
import { toast } from "react-toastify";
import InvoiceAmountInput from "../components/InvoiceAmountInput";

type Partner = {
  "@id": string;
  companyName: string;
  firstname: string;
  lastname: string;
};

type Invoice = {
  "@id": string;
  id: number;
  partner: Partner;
  label: string;
  reference: string;
  attachedQuotations: Array<string>;
  hasReport: boolean;
  hasIBAN: boolean;
  totalAmountExcludingTaxes: number;
  totalAmountIncludingTaxes: number;
  paidAmountIncludingTaxes: number;
  attachedFile: AttachedFile | string;
  createdAt: string;
};

type InvoiceForm = {
  id?: number;
  partner: string;
  label: string;
  reference: string;
  attachedQuotations: Array<string>;
  hasReport: boolean;
  hasIBAN: boolean;
  totalAmountExcludingTaxes: number;
  totalAmountIncludingTaxes: number;
  paidAmountIncludingTaxes: number;
  attachedFile?: File;
  createdAt: string;
};

type Quotation = {
  "@id": string;
  id: number;
  partner: Partner;
  label: string;
  reference: string;
  amountExcludingTaxes: number;
  amountIncludingTaxes: number;
  isSigned: boolean;
  createdAt: string;
  attachedFile: AttachedFile | string;
};

type QuotationForm = {
  id?: number;
  partner: string;
  label: string;
  reference: string;
  amountExcludingTaxes: number;
  amountIncludingTaxes: number;
  isSigned: boolean;
  createdAt: string;
  attachedFile?: File;
};

type Deposit = {
  "@id": string;
  id: number;
  partner: Partner;
  label: string;
  amountIncludingTaxes: number;
  attachedQuotations: Array<string>;
  isAssociated: boolean;
  createdAt: string;
  attachedFile?: AttachedFile | string | null;
};

type DepositForm = {
  id?: number;
  partner: string;
  label: string;
  amountIncludingTaxes: number;
  attachedQuotations: Array<string>;
  isAssociated: boolean;
  createdAt: string;
  attachedFile?: File | null;
};

type AttachedFile = {
  "@id": string;
  id: number;
};

type WorksiteFinancialType = {
  "@id": string;
  name: string;
  partners: Array<string>;
  invoices: Array<Invoice>;
  quotations: Array<Quotation>;
  deposits: Array<Deposit>;
};

export default function WorksiteFinancial() {
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [worksite, setWorksite] = React.useState<WorksiteFinancialType>();
  const [modifiedInvoices, setModifiedInvoices] = React.useState<Array<Invoice>>([]);
  const [currentInvoice, setCurrentInvoice] = React.useState<Invoice>();
  const [partners, setPartners] = React.useState<Array<PartnerAPI>>();
  const quotationStates = [
    { label: "Devis en attente", value: false },
    { label: "Devis signés", value: true },
  ];
  const depositStates = [
    { label: "Acomptes reçus", value: false },
    { label: "Acomptes associés", value: true },
  ];
  const invoiceStates = [
    { label: "Factures à régler", value: 0 },
    { label: "Rglt partiel", value: 1 },
    { label: "Factures réglées", value: 2 },
  ];
  const [popup, setPopup] = React.useState<
    | {
        type: null;
      }
    | {
        type: "new" | "edit";
        itemType: "quotations";
        item: QuotationForm;
      }
    | {
        type: "new" | "edit";
        itemType: "invoices";
        item: InvoiceForm;
      }
    | {
        type: "new" | "edit";
        itemType: "deposits";
        item: DepositForm;
      }
    | {
        type: "delete";
        itemType: "quotations" | "invoices" | "deposits";
        itemID: number;
      }
  >({ type: null });

  React.useEffect(() => {
    if(currentInvoice && !modifiedInvoices.find(invoice => invoice.id === currentInvoice.id)) {
      setModifiedInvoices([
        ...(modifiedInvoices as []),
        currentInvoice as Invoice,
      ]);
    }
    else if(currentInvoice && modifiedInvoices.find(invoice => invoice.id === currentInvoice.id)) {
      setModifiedInvoices(modifiedInvoices.map(invoice => invoice.id === currentInvoice.id ? currentInvoice as Invoice : invoice));
    }
  }, [currentInvoice]);

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/${params.id}/get_financial`, {
      method: "GET",
    }).then((worksite) => {
      setWorksite(worksite);
    });
    checkTokenAndFetch(`/api/partners/get_listing`, { method: "GET" }).then(
      (partners: { "hydra:member": Array<Partner> }) => {
        setPartners(
          partners["hydra:member"]
            .sort((a, b) => a.companyName < b.companyName ? -1 : 1)
            .map((partner) => ({
              label: `${partner.companyName} - ${partner.firstname} ${partner.lastname}`,
              value: partner["@id"],
            }))
        );
      }
    );
  }, [params.id]);
  function totalQuotations() {
    const result = {
      totalIncludingTaxes: 0,
      totalExcludingTaxes: 0,
    };

    if (worksite !== undefined) {
      worksite.quotations.forEach((quotation) => {
        result.totalIncludingTaxes += quotation.amountIncludingTaxes;
        result.totalExcludingTaxes += quotation.amountExcludingTaxes;
      });
    }

    return result;
  }
  function totalInvoices() {
    const result = {
      totalIncludingTaxes: 0,
      totalExcludingTaxes: 0,
      paidIncludingTaxes: 0,
      remainingIncludingTaxes: 0,
    };

    if (worksite !== undefined) {
      worksite.invoices.forEach((invoice) => {
        result.totalIncludingTaxes += invoice.totalAmountIncludingTaxes;
        result.totalExcludingTaxes += invoice.totalAmountExcludingTaxes;
        result.paidIncludingTaxes += invoice.paidAmountIncludingTaxes;
        result.remainingIncludingTaxes +=
          invoice.totalAmountIncludingTaxes - invoice.paidAmountIncludingTaxes;
      });
    }

    return result;
  }
  function totalDeposits() {
    const result = {
      totalIncludingTaxes: 0,
    };

    if (worksite !== undefined) {
      worksite.deposits.forEach((deposit) => {
        result.totalIncludingTaxes += deposit.amountIncludingTaxes;
      });
    }

    return result;
  }
  function csv() {
    let content = "";
    const separator = ";";
    let headersRow = [];

    headersRow.push("DEVIS");
    content += headersRow.join(separator);
    content += "\r\n";
    headersRow = [];
    headersRow.push("Date de dépôt");
    headersRow.push("N° devis");
    headersRow.push("Libellé");
    headersRow.push("Partenaire");
    headersRow.push("Montant TTC");
    headersRow.push("Etat");
    content += headersRow.join(separator);
    content += "\r\n";
    worksite?.quotations.forEach((quotation) => {
      const quotationRow = [];
      quotationRow.push(new Date(quotation.createdAt).toLocaleDateString());
      quotationRow.push(quotation.reference);
      quotationRow.push(quotation.label);
      quotationRow.push(
        `${quotation.partner.companyName} - ${quotation.partner.firstname} ${quotation.partner.lastname}`
      );
      quotationRow.push(currencyFormat.format(quotation.amountIncludingTaxes));
      quotationRow.push(
        quotationStates.find((state) => state.value === quotation.isSigned)
          ?.label
      );
      content += quotationRow.join(separator);
      content += "\r\n";
    });

    content += "\r\n\r\n";

    headersRow = [];
    headersRow.push("ACOMPTES");
    content += headersRow.join(separator);
    content += "\r\n";
    headersRow = [];
    headersRow.push("Date de dépôt");
    headersRow.push("Libellé");
    headersRow.push("Partenaire");
    headersRow.push("Montant TTC");
    headersRow.push("Etat");
    content += headersRow.join(separator);
    content += "\r\n";
    worksite?.deposits.forEach((deposit) => {
      const depositRow = [];
      depositRow.push(new Date(deposit.createdAt).toLocaleDateString());
      depositRow.push(deposit.label);
      depositRow.push(
        `${deposit.partner.companyName} - ${deposit.partner.firstname} ${deposit.partner.lastname}`
      );
      depositRow.push(currencyFormat.format(deposit.amountIncludingTaxes));
      depositRow.push(
        depositStates.find((state) => state.value === deposit.isAssociated)
          ?.label
      );
      content += depositRow.join(separator);
      content += "\r\n";
    });

    content += "\r\n\r\n";

    headersRow = [];
    headersRow.push("FACTURES");
    content += headersRow.join(separator);
    content += "\r\n";
    headersRow = [];
    headersRow.push("Date de dépôt");
    headersRow.push("N° facture");
    headersRow.push("Libellé");
    headersRow.push("Partenaire");
    headersRow.push("Montant réglé TTC");
    headersRow.push("Etat");
    content += headersRow.join(separator);
    content += "\r\n";
    worksite?.invoices.forEach((invoice) => {
      const invoiceRow = [];
      invoiceRow.push(new Date(invoice.createdAt).toLocaleDateString());
      invoiceRow.push(invoice.reference);
      invoiceRow.push(invoice.label);
      invoiceRow.push(
        `${invoice.partner.companyName} - ${invoice.partner.firstname} ${invoice.partner.lastname}`
      );
      invoiceRow.push(currencyFormat.format(invoice.paidAmountIncludingTaxes));
      invoiceRow.push(
        invoiceStates.find(
          (state) =>
            state.value ===
            (invoice.paidAmountIncludingTaxes <= 0
              ? 0
              : invoice.paidAmountIncludingTaxes >=
                invoice.totalAmountIncludingTaxes
              ? 2
              : 1)
        )?.label
      );
      content += invoiceRow.join(separator);
      content += "\r\n";
    });
    return (
      "data:text/csv;base64," +
      window.btoa(unescape(encodeURIComponent("\ufeff" + content)))
    );
  }
  function popupForm() {
    if (
      worksite === undefined ||
      (popup.type !== "new" && popup.type !== "edit")
    ) {
      return null;
    }

    let formFields = null;
    const params = {
      invoices: {
        new: {
          message: "La facture a bien été créée",
          title: "Nouvelle facture",
          url: `/api/invoices/post_new`,
        },
        edit: {
          message: "La facture a bien été mise à jour",
          title: "Modifier la facture",
          url: `/api/invoices/${popup.item.id}/patch`,
        },
      },
      quotations: {
        new: {
          message: "Le devis a bien été créé",
          title: "Nouveau devis",
          url: `/api/quotations/post_new`,
        },
        edit: {
          message: "Le devis a bien été mis à jour",
          title: "Modifier le devis",
          url: `/api/quotations/${popup.item.id}/patch`,
        },
      },
      deposits: {
        new: {
          message: "L'acompte a bien été créé",
          title: "Nouvel acompte",
          url: `/api/deposits/post_new`,
        },
        edit: {
          message: "L'acompte a bien été mis à jour",
          title: "Modifier l'acompte",
          url: `/api/deposits/${popup.item.id}/patch`,
        },
      },
    };
    switch (popup.itemType) {
      case "quotations":
        formFields = (
          <>
            <BaseLabel required label="Partenaire">
              <BaseSelect
                required
                options={
                  partners === undefined
                    ? []
                    : partners.filter((partner) =>
                        worksite.partners.includes(partner.value)
                      )
                }
                value={popup.item.partner}
                onChange={(event) => {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      partner: event.currentTarget.value,
                    },
                  });
                }}
              />
            </BaseLabel>
            <BaseLabel required label="Libellé">
              <BaseInput
                required
                type="text"
                value={popup.item.label}
                onChange={(event) => {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      label: event.currentTarget.value,
                    },
                  });
                }}
              />
            </BaseLabel>
            <div className="flex gap-5">
              <BaseLabel required label="N° du devis">
                <BaseInput
                  required
                  type="text"
                  value={popup.item.reference}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        reference: event.currentTarget.value,
                      },
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel required label="Montant HT">
                <BaseInput
                  required
                  type="number"
                  step="0.01"
                  value={popup.item.amountExcludingTaxes}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        amountExcludingTaxes: +event.currentTarget.value,
                      },
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel required label="Montant TTC">
                <BaseInput
                  required
                  type="number"
                  step="0.01"
                  value={popup.item.amountIncludingTaxes}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        amountIncludingTaxes: +event.currentTarget.value,
                      },
                    });
                  }}
                />
              </BaseLabel>
            </div>
            <div className="flex gap-5">
              <BaseLabel required label="Etat">
                <div className="flex gap-5">
                  {quotationStates.map((state, sIndex) => (
                    <BaseRadio
                      key={sIndex}
                      label={state.label}
                      value={state.value}
                      currentValue={popup.item.isSigned}
                      onChange={(event) => {
                        setPopup({
                          ...popup,
                          item: {
                            ...popup.item,
                            isSigned: !!event.currentTarget.value,
                          },
                        });
                      }}
                      className="w-max"
                    />
                  ))}
                </div>
              </BaseLabel>
              <BaseInputFile
                required
                value={popup.item.attachedFile}
                onChange={(file) => {
                  if (file) {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        attachedFile: {
                          name: file.name,
                          content: file.content,
                          createdAt: new Date().toISOString(),
                        },
                      },
                    });
                  } else {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        attachedFile: undefined,
                      },
                    });
                  }
                }}
              />
            </div>
          </>
        );
        break;
      case "invoices":
        formFields = (
          <>
            <BaseLabel required label="Partenaire">
              <BaseSelect
                required
                options={
                  partners === undefined
                    ? []
                    : partners.filter((partner) =>
                        worksite.partners.includes(partner.value)
                      )
                }
                value={popup.item.partner}
                onChange={(event) => {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      partner: event.currentTarget.value,
                    },
                  });
                }}
              />
            </BaseLabel>
            <BaseLabel required label="Libellé">
              <BaseInput
                required
                type="text"
                value={popup.item.label}
                onChange={(event) => {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      label: event.currentTarget.value,
                    },
                  });
                }}
              />
            </BaseLabel>
            <div className="flex gap-5">
              <BaseLabel required label="N° de facture">
                <BaseInput
                  required
                  type="text"
                  value={popup.item.reference}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        reference: event.currentTarget.value,
                      },
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel required label="Devis associé">
                <BaseSelect
                  multiple
                  required
                  options={[
                    {value: "0", label: "Aucun devis associé" },
                    ...worksite.quotations
                    .sort((a, b) => a.reference < b.reference ? -1 : 1)
                    .map((quotation) => ({
                      value: quotation["@id"],
                      label: `${quotation.reference} - ${quotation.label}`,
                    }))
                  ]}
                  value={popup.item.attachedQuotations.length > 0 ? popup.item.attachedQuotations : ["0"]}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        attachedQuotations: Array.from(
                          event.currentTarget.selectedOptions
                        ).map((option) => option.value),
                      },
                    });
                  }}
                />
              </BaseLabel>
            </div>
            {/* <div className="flex gap-5">
              <BaseLabel
                label="Joindre PV de réception"
                className="shrink flex flex-row-reverse gap-4 w-auto"
              >
                <BaseInput
                  type="checkbox"
                  checked={popup.item.hasReport}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        hasReport: event.currentTarget.checked,
                      },
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel
                label="Joindre le RIB"
                className="shrink flex flex-row-reverse gap-4 w-auto"
              >
                <BaseInput
                  type="checkbox"
                  checked={popup.item.hasIBAN}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        hasIBAN: event.currentTarget.checked,
                      },
                    });
                  }}
                />
              </BaseLabel>
            </div> */}
            <div className="grid grid-cols-2 gap-5">
              <BaseLabel
                required={popup.type === "new"}
                label={
                  popup.type === "new" ? "Montant HT" : "Montant initial HT"
                }
              >
                <BaseInput
                  required={popup.type === "new"}
                  disabled={popup.type !== "new"}
                  type="number"
                  step="0.01"
                  value={popup.item.totalAmountExcludingTaxes}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        totalAmountExcludingTaxes: +event.currentTarget.value,
                      },
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel
                required={popup.type === "new"}
                label={
                  popup.type === "new" ? "Montant TTC" : "Montant initial TTC"
                }
              >
                <BaseInput
                  required={popup.type === "new"}
                  disabled={popup.type !== "new"}
                  type="number"
                  step="0.01"
                  value={popup.item.totalAmountIncludingTaxes}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        totalAmountIncludingTaxes: +event.currentTarget.value,
                      },
                    });
                  }}
                />
              </BaseLabel>
              {popup.type === "edit" && (
                <>
                  <BaseLabel required label="Montant réglé TTC">
                    <BaseInput
                      required
                      type="number"
                      step="0.01"
                      value={popup.item.paidAmountIncludingTaxes}
                      onChange={(event) => {
                        setPopup({
                          ...popup,
                          item: {
                            ...popup.item,
                            paidAmountIncludingTaxes:
                              +event.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel label="Reste à régler TTC">
                    <BaseInput
                      disabled
                      type="number"
                      step="0.01"
                      value={
                        popup.item.totalAmountIncludingTaxes -
                        popup.item.paidAmountIncludingTaxes
                      }
                    />
                  </BaseLabel>
                </>
              )}
            </div>
            <BaseInputFile
              required
              value={popup.item.attachedFile}
              onChange={(file) => {
                if (file) {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      attachedFile: {
                        name: file.name,
                        content: file.content,
                        createdAt: new Date().toISOString(),
                      },
                    },
                  });
                } else {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      attachedFile: undefined,
                    },
                  });
                }
              }}
            />
          </>
        );
        break;
      case "deposits":
        formFields = (
          <>
            <BaseLabel required label="Partenaire">
              <BaseSelect
                required
                options={
                  partners === undefined
                    ? []
                    : partners.filter((partner) =>
                        worksite.partners.includes(partner.value)
                      )
                }
                value={popup.item.partner}
                onChange={(event) => {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      partner: event.currentTarget.value,
                    },
                  });
                }}
              />
            </BaseLabel>
            <BaseLabel required label="Libellé">
              <BaseInput
                required
                type="text"
                value={popup.item.label}
                onChange={(event) => {
                  setPopup({
                    ...popup,
                    item: {
                      ...popup.item,
                      label: event.currentTarget.value,
                    },
                  });
                }}
              />
            </BaseLabel>
            <div className="flex gap-5">
              <BaseLabel required label="Montant TTC">
                <BaseInput
                  required
                  type="number"
                  step="0.01"
                  value={popup.item.amountIncludingTaxes}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        amountIncludingTaxes: +event.currentTarget.value,
                      },
                    });
                  }}
                />
              </BaseLabel>
              <BaseLabel required label="Devis associé">
                <BaseSelect
                  multiple
                  required
                  options={[
                    {value: "0", label: "Aucun devis associé" },
                    ...worksite.quotations
                    .sort((a, b) => a.reference < b.reference ? -1 : 1)
                    .map((quotation) => ({
                      value: quotation["@id"],
                      label: `${quotation.reference} - ${quotation.label}`,
                    }))
                  ]}
                  value={popup.item.attachedQuotations.length > 0 ? popup.item.attachedQuotations : ["0"]}
                  onChange={(event) => {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        attachedQuotations: Array.from(
                          event.currentTarget.selectedOptions
                        ).map((option) => option.value),
                      },
                    });
                  }}
                />
              </BaseLabel>
            </div>
            <div className="flex gap-5">
              <BaseLabel required label="Etat">
                <div className="flex gap-5">
                  {depositStates.map((state, sIndex) => (
                    <BaseRadio
                      key={sIndex}
                      label={state.label}
                      value={state.value}
                      currentValue={popup.item.isAssociated}
                      onChange={(event) => {
                        setPopup({
                          ...popup,
                          item: {
                            ...popup.item,
                            isAssociated: !!event.currentTarget.value,
                          },
                        });
                      }}
                      className="w-max"
                    />
                  ))}
                </div>
              </BaseLabel>
              <BaseInputFile
                value={popup.item.attachedFile}
                onChange={(file) => {
                  if (file) {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        attachedFile: {
                          name: file.name,
                          content: file.content,
                          createdAt: new Date().toISOString(),
                        },
                      },
                    });
                  } else {
                    setPopup({
                      ...popup,
                      item: {
                        ...popup.item,
                        attachedFile: null,
                      },
                    });
                  }
                }}
              />
            </div>
          </>
        );
        break;
      }
    return (
      <BasePopup
        title={params[popup.itemType][popup.type].title}
        isVisible={popup.type === "new" || popup.type === "edit"}
        className="min-w-[600px]"
        onClose={() => {
          setPopup({ type: null });
        }}
      >
        <BaseForm
          isNew={popup.type === "new"}
          url={params[popup.itemType][popup.type].url}
          body={
            popup.itemType === "quotations" ?
              popup.type === "new"
                ?
                {
                  ...popup.item,
                  worksite: worksite["@id"],
                  createdAt: new Date().toISOString(),
                }
              : popup.item
            :
            popup.type === "new"
              ?
              {
                ...popup.item,
                attachedQuotations: popup.item.attachedQuotations && popup.item.attachedQuotations[0] === "0" ? [] : popup.item.attachedQuotations,
                worksite: worksite["@id"],
                createdAt: new Date().toISOString(),
              }
              :
              {
                ...popup.item,
                attachedQuotations: popup.item.attachedQuotations && popup.item.attachedQuotations[0] === "0" ? [] : popup.item.attachedQuotations,
              }
          }
          loadingSetter={setIsLoading}
          onSuccess={(result: Invoice | Quotation | Deposit) => {
            switch (popup.type) {
              case "new":
                setWorksite({
                  ...worksite,
                  [popup.itemType]: [...worksite[popup.itemType], result],
                });
                break;
              case "edit":
                setWorksite({
                  ...worksite,
                  [popup.itemType]: worksite[popup.itemType].map((item) => {
                    if (item.id === popup.item.id) {
                      return result;
                    }
                    return item;
                  }),
                });
                break;
            }
            setPopup({ type: null });
          }}
          successMessage={params[popup.itemType][popup.type].message}
        >
          <div className="flex flex-col gap-5">
            {formFields}
            <BaseButton
              type="submit"
              styleType="primary"
              content={popup.type === "new" ? "Ajouter" : "Enregistrer"}
              icon={
                isLoading
                  ? {
                      icon: faSpinner,
                      className: "animate-spin",
                    }
                  : undefined
              }
              className="self-end"
            />
          </div>
        </BaseForm>
      </BasePopup>
    );
  }

  return worksite !== undefined ? (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2">
        <Amount
          title="Enveloppe de départ"
          type="positive"
          amountIncludingTaxes={totalQuotations().totalIncludingTaxes}
          amountExcludingTaxes={totalQuotations().totalExcludingTaxes}
          withTaxes
        />
        <Amount
          title="Montant facturé"
          type="neutral"
          amountIncludingTaxes={totalInvoices().totalIncludingTaxes}
          amountExcludingTaxes={totalInvoices().totalExcludingTaxes}
          withTaxes
        />
        <Amount
          title="Montant réglé"
          type="neutral"
          amountIncludingTaxes={totalInvoices().paidIncludingTaxes}
        />
        <Amount
          title="Règlements en attente"
          type="negative"
          amountIncludingTaxes={totalInvoices().remainingIncludingTaxes}
        />
        <Amount
          title='Enveloppe restante'
          type="neutral"
          amountIncludingTaxes={
            totalQuotations().totalIncludingTaxes -
            totalInvoices().totalIncludingTaxes
          }
          amountExcludingTaxes={
            totalQuotations().totalExcludingTaxes -
            totalInvoices().totalExcludingTaxes
          }
          withTaxes
        />
      </div>
      <div className="flex flex-row justify-between gap-3">
        <BaseButton
          type="button"
          styleType="primary"
          content="Ajouter un devis"
          icon={{ icon: faPlus }}
          onClick={() => {
            setPopup({
              itemType: "quotations",
              type: "new",
              item: {
                partner: "",
                label: "",
                reference: "",
                amountExcludingTaxes: 0,
                amountIncludingTaxes: 0,
                isSigned: false,
                createdAt: "",
              },
            });
          }}
        />
        <div className="flex-grow"></div>
        <BaseButton
          type="button"
          styleType="secondary"
          content="Ajouter un acompte"
          icon={{ icon: faPlus }}
          onClick={() => {
            setPopup({
              itemType: "deposits",
              type: "new",
              item: {
                partner: "",
                label: "",
                attachedQuotations: [],
                amountIncludingTaxes: 0,
                isAssociated: false,
                createdAt: "",
              },
            });
          }}
        />
        <div className="flex-grow"></div>
        <BaseButton
          type="button"
          styleType="primary"
          content="Ajouter une facture"
          icon={{ icon: faPlus }}
          onClick={() => {
            setPopup({
              itemType: "invoices",
              type: "new",
              item: {
                partner: "",
                label: "",
                reference: "",
                createdAt: "",
                attachedQuotations: [],
                hasReport: false,
                hasIBAN: false,
                totalAmountExcludingTaxes: 0,
                totalAmountIncludingTaxes: 0,
                paidAmountIncludingTaxes: 0,
              },
            });
          }}
        />
        <a href={csv()} download={`${worksite.name}_devis-et-factures`}>
          <BaseButton
            type="button"
            styleType="csv"
            content="Export CSV"
            icon={{ icon: faFileCsv }}
          />
        </a>
      </div>
      <Table
        left={{
          name: "Devis",
          props: {
            headers: [
              { name: "Date dépôt", order: true },
              { name: "N° Devis", order: true },
              { name: "Libellé", order: true },
              { name: "Partenaire", order: true },
              { name: "Montant TTC" },
              { name: "Etat" },
              {},
            ],
            defaultOrder: {
              field: 1,
              isAsc: true,
            },
            pagination: {
              itemPerPage: 100,
            },
            categories: {
              index: 5,
              default: false,
              labels: quotationStates,
            },
            total: {
              label: "Montant total",
              indexes: [4],
              formatter: (value) => currencyFormat.format(value),
            },
            children: worksite.quotations.map((quotation) => [
              {
                elem: new Date(quotation.createdAt).toLocaleDateString(),
                value: quotation.createdAt,
              },
              { elem: quotation.reference, value: quotation.reference },
              { elem: quotation.label, value: quotation.label },
              {
                elem:
                  quotation.partner.companyName +
                  " - " +
                  quotation.partner.firstname +
                  " " +
                  quotation.partner.lastname,
                value: quotation.partner.firstname + quotation.partner.lastname,
              },
              {
                elem: currencyFormat.format(quotation.amountIncludingTaxes),
                value: quotation.amountIncludingTaxes,
              },
              {
                elem: (
                  <BaseSelect
                    value={quotation.isSigned ? 1 : 0}
                    options={quotationStates.map((state) => ({
                      ...state,
                      value: +state.value,
                    }))}
                    onChange={(event) => {
                      const val = event.currentTarget.value === "1";
                      checkTokenAndFetch(
                        `/api/quotations/${quotation.id}/patch_is_signed`,
                        {
                          headers: {
                            "Content-Type": "application/merge-patch+json",
                          },
                          method: "PATCH",
                          body: JSON.stringify({
                            isSigned: val,
                          }),
                        }
                      ).then(() => {
                        quotation.isSigned = val;
                        setWorksite({ ...worksite });
                        toast.success("L'état du devis a bien été mis à jour");
                      });
                    }}
                  />
                ),
                value: quotation.isSigned,
              },
              {
                elem: (
                  <span className="text-grey-2 flex flex-row items-center gap-3">
                    <DownloadFileIcon url={typeof quotation.attachedFile === 'object' ? quotation.attachedFile['@id'] : quotation.attachedFile} />
                    <FontAwesomeIcon
                      className="cursor-pointer hover:text-green-1"
                      icon={faPen}
                      onClick={() => {
                        checkTokenAndFetch(typeof quotation.attachedFile === 'object' ? quotation.attachedFile['@id'] : quotation.attachedFile, {
                          method: "GET",
                        }).then((returnedFile: File) => {
                          setPopup({
                            type: "edit",
                            itemType: "quotations",
                            item: {
                              ...quotation,
                              partner: quotation.partner["@id"],
                              attachedFile: returnedFile,
                            },
                          });
                        });
                      }}
                    />
                    <FontAwesomeIcon
                      className="cursor-pointer hover:text-green-1"
                      icon={faTrash}
                      onClick={() => {
                        setPopup({
                          type: "delete",
                          itemType: "quotations",
                          itemID: quotation.id,
                        });
                      }}
                    />
                  </span>
                ),
                value: 0,
              },
            ]),
          },
        }}
        center={{
          name: "Acomptes",
          props: {
            headers: [
              { name: "Date de dépôt", order: true },
              { name: "Libellé", order: true },
              { name: "Partenaire", order: true },
              { name: "Montant TTC" },
              { name: "Etat" },
              {},
            ],
            defaultOrder: {
              field: 0,
              isAsc: true,
            },
            pagination: {
              itemPerPage: 100,
            },
            categories: {
              index: 4,
              default: false,
              labels: depositStates,
            },
            total: {
              label: "Montant total",
              indexes: [3],
              formatter: (value) => currencyFormat.format(value),
            },
            children: worksite.deposits.map((deposit) => [
              {
                elem: new Date(deposit.createdAt).toLocaleDateString(),
                value: deposit.createdAt,
              },
              { elem: deposit.label, value: deposit.label },
              {
                elem:
                  deposit.partner.companyName +
                  " - " +
                  deposit.partner.firstname +
                  " " +
                  deposit.partner.lastname,
                value: deposit.partner.firstname + deposit.partner.lastname,
              },
              {
                elem: currencyFormat.format(deposit.amountIncludingTaxes),
                value: deposit.amountIncludingTaxes,
              },
              {
                elem: (
                  <BaseSelect
                    value={deposit.isAssociated ? 1 : 0}
                    options={depositStates.map((state) => ({
                      ...state,
                      value: +state.value,
                    }))}
                    onChange={(event) => {
                      const val = event.currentTarget.value === "1";
                      checkTokenAndFetch(
                        `/api/deposits/${deposit.id}/patch_is_associated`,
                        {
                          headers: {
                            "Content-Type": "application/merge-patch+json",
                          },
                          method: "PATCH",
                          body: JSON.stringify({
                            isAssociated: val,
                          }),
                        }
                      ).then(() => {
                        deposit.isAssociated = val;
                        setWorksite({ ...worksite });
                        toast.success("L'état de l'acompte a bien été mis à jour");
                      });
                    }}
                  />
                ),
                value: deposit.isAssociated,
              },
              {
                elem: (
                  <span className="text-grey-2 flex flex-row items-center gap-3">
                    {deposit.attachedFile && <DownloadFileIcon url={typeof deposit.attachedFile === 'object' ? deposit.attachedFile['@id'] : deposit.attachedFile } />}
                    <FontAwesomeIcon
                      className="cursor-pointer hover:text-green-1"
                      icon={faPen}
                      onClick={() => {
                        deposit.attachedFile ?
                          checkTokenAndFetch(typeof deposit.attachedFile === 'object' ? deposit.attachedFile['@id'] : deposit.attachedFile, {
                            method: "GET",
                          }).then((returnedFile: File) => {
                            setPopup({
                              type: "edit",
                              itemType: "deposits",
                              item: {
                                ...deposit,
                                partner: deposit.partner["@id"],
                                attachedFile: returnedFile,
                              },
                            });
                          })
                        :
                          setPopup({
                            type: "edit",
                            itemType: "deposits",
                            item: {
                              ...deposit,
                              partner: deposit.partner["@id"],
                              attachedFile: undefined,
                            },
                          });
                      }}
                    />
                    <FontAwesomeIcon
                      className="cursor-pointer hover:text-green-1"
                      icon={faTrash}
                      onClick={() => {
                        setPopup({
                          type: "delete",
                          itemType: "deposits",
                          itemID: deposit.id,
                        });
                      }}
                    />
                  </span>
                ),
                value: 0,
              },
            ]),
          },
        }}
        right={{
          name: "Factures",
          props: {
            headers: [
              { name: "Date de dépôt", order: true },
              { name: "N° Facture", order: true },
              { name: "Libellé", order: true },
              { name: "Partenaire", order: true },
              { name: "Montant réglé TTC" },
              { name: "Montant total TTC" },
              {},
            ],
            defaultOrder: {
              field: 1,
              isAsc: true,
            },
            pagination: {
              itemPerPage: 100,
            },
            categories: {
              index: 7,
              default: 0,
              labels: invoiceStates,
            },
            total: {
              label: "Montant total",
              indexes: [3, 4],
              formatter: (value) => currencyFormat.format(value),
            },
            children: worksite.invoices.map((invoice) => [
              {
                elem: new Date(invoice.createdAt).toLocaleDateString(),
                value: invoice.createdAt,
              },
              { elem: invoice.reference, value: invoice.reference },
              { elem: invoice.label, value: invoice.label },
              {
                elem:
                  invoice.partner.companyName +
                  " - " +
                  invoice.partner.firstname +
                  " " +
                  invoice.partner.lastname,
                value: invoice.partner.firstname + invoice.partner.lastname,
              },
              {
                elem: (
                  <InvoiceAmountInput
                    invoice={invoice}
                    onInvoiceFocus={() => {
                      setCurrentInvoice(invoice);
                    }}
                    onInvoiceAmountChange={(amount) => {
                      if(currentInvoice) {
                        setCurrentInvoice({
                          ...currentInvoice,
                          paidAmountIncludingTaxes: amount,
                        })
                      }
                    }}
                    invoiceAmount={
                      modifiedInvoices.find((modifiedInvoice) => modifiedInvoice.id === invoice.id
                      )?.paidAmountIncludingTaxes ?? invoice.paidAmountIncludingTaxes
                    }
                  />
                ),
                value: invoice.paidAmountIncludingTaxes,
              },
              {
                elem: currencyFormat.format(invoice.totalAmountIncludingTaxes),
                value: invoice.totalAmountIncludingTaxes,
              },
              {
                elem: (
                  <span className="text-grey-2 flex flex-row items-center gap-3">
                    <DownloadFileIcon url={typeof invoice.attachedFile === 'object' ? invoice.attachedFile['@id'] : invoice.attachedFile} />
                    <FontAwesomeIcon
                      className="cursor-pointer hover:text-green-1"
                      icon={faPen}
                      onClick={() => {
                        checkTokenAndFetch(typeof invoice.attachedFile === 'object' ? invoice.attachedFile['@id'] : invoice.attachedFile, {
                          method: "GET",
                        }).then((returnedFile: File) => {
                          setPopup({
                            type: "edit",
                            itemType: "invoices",
                            item: {
                              ...invoice,
                              partner: invoice.partner["@id"],
                              attachedFile: returnedFile,
                            },
                          });
                        });
                      }}
                    />
                    <FontAwesomeIcon
                      className="cursor-pointer hover:text-green-1"
                      icon={faTrash}
                      onClick={() => {
                        setPopup({
                          type: "delete",
                          itemType: "invoices",
                          itemID: invoice.id,
                        });
                      }}
                    />
                  </span>
                ),
                value: 0,
              },
              {
                elem:
                  invoice.paidAmountIncludingTaxes <= 0
                    ? 0
                    : invoice.paidAmountIncludingTaxes >=
                      invoice.totalAmountIncludingTaxes
                    ? 2
                    : 1,
                value:
                  invoice.paidAmountIncludingTaxes <= 0
                    ? 0
                    : invoice.paidAmountIncludingTaxes >=
                      invoice.totalAmountIncludingTaxes
                    ? 2
                    : 1,
              },
            ]),
          },
        }}
      />
      <div className="flex justify-end">
        <BaseButton
          styleType="primary"
          type="button"
          content="Enregistrer les nouveaux montants"
          icon={
            isLoading
              ? {
                  icon: faSpinner,
                  className: "animate-spin",
                }
              : undefined
          }
          onClick={() => {
            setIsLoading(true);
            Promise.all(
              modifiedInvoices.map((invoice) =>
                checkTokenAndFetch(`/api/invoices/${invoice.id}/patch`, {
                  headers: {
                    "Content-Type": "application/merge-patch+json",
                  },
                  method: "PATCH",
                  body: JSON.stringify({
                    paidAmountIncludingTaxes: invoice.paidAmountIncludingTaxes,
                  }),
                })
              )
            )
              .then(() => {
                checkTokenAndFetch(`/api/worksites/${params.id}/get_financial`, {
                  method: "GET",
                }).then((worksite) => {
                  setWorksite(worksite);
                  toast.success(
                    "Les montants réglés des factures ont bien été mis à jour"
                  );
                })
                .finally(() => {
                  setIsLoading(false);
                });
              })
          }}
        />
      </div>
      {popupForm()}
      {popup.type === "delete" && (
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={popup.type === "delete"}
          onClose={() => {
            setPopup({ type: null });
          }}
          onSuccess={() => {
            switch (popup.itemType) {
              case "quotations":
                setWorksite({
                  ...worksite,
                  quotations: worksite.quotations.filter(
                    (quotation) => quotation.id !== popup.itemID
                  ),
                });
                toast.success("Le devis a bien été supprimé");
                break;
              case "invoices":
                setWorksite({
                  ...worksite,
                  invoices: worksite.invoices.filter(
                    (invoice) => invoice.id !== popup.itemID
                  ),
                });
                toast.success("La facture a bien été supprimée");
                break;
            }
          }}
          url={`/api/${popup.itemType}/${popup.itemID}`}
        >
          Etes-vous sûr de vouloir supprimer cet élément ?
        </BasePopupConfirmDelete>
      )}
    </div>
  ) : (
    <Loading />
  );
}
