import React from "react";
import { Link } from "react-router-dom";
import logo354 from "../assets/logo354.png";
import googleBadge from "../assets/google-play-badge.png";
import appleBadge from "../assets/app-store-badge.png";

export default function GoToApp() {
  const androidAppUrl = process.env.REACT_APP_ANDROID_APP_URL;
  const iosAppUrl = process.env.REACT_APP_IOS_APP_URL;

  return (
    <div className="bg-grey-7 min-h-screen px-4 flex flex-col gap-20 items-center justify-center">
      <img src={logo354} alt="logo" />
      <div className="flex flex-col items-center justify-center">
        <p>Afin que vous puissiez suivre l&apos;avancée et les tâches du projet de construction,</p>
        <p>veuillez télécharger l&apos;application OMDO sur votre store,</p>
        <p>et vous connecter avec vos identifiants reçus par mail.</p>
        <div className="w-full flex justify-around my-8">
          <a href={androidAppUrl} target="_blank" rel="noreferrer" className="m-4"><img src={googleBadge} alt="Google Store Badge" /></a>
          <a href={iosAppUrl} target="_blank" rel="noreferrer" className="m-4"><img src={appleBadge} alt="App Store (Apple)" /></a>
        </div>

      </div>

      <Link
        className="text-center font-extrabold text-green-1"
        to="/connexion"
      >
        Retour à la page de connexion
      </Link>
    </div>
  );
}
