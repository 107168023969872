import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import {
  faBriefcase,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../components/BaseButton";
import BaseInput from "../components/BaseInput";
import Card from "../components/Card";
import DoubleColumn from "../components/DoubleColumn";
import Page from "../components/Page";
import Section from "../components/Section";
import BaseLabel from "../components/BaseLabel";
import BaseSelect from "../components/BaseSelect";
import BaseForm from "../components/BaseForm";
import BaseSwitch from "../components/BaseSwitch";
import BasePopup from "../components/BasePopup";
import CardContent from "../components/CardContent";
import FileWidgets from "../components/FileWidgets";
import { parsePhoneNumber } from "libphonenumber-js";
import { useCheckTokenAndFetch } from "../auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilesCategory from "../components/FilesCategory";

type Alert = {
  name: string;
  description: string;
  hasClientAlert: false;
  dueDate: string;
  recallDate?: string;
};

type File = {
  name: string;
  createdAt: string;
  content?: string;
  startDate?: string;
  endDate?: string;
  alerts: Array<Alert>;
};

interface Inputs {
  isActive: boolean;
  hasMobileAccess: boolean;
  category: string;
  iban?: File;
  insurances: Array<File>;
  companyName: string;
  companyStatus: string;
  sex: number;
  lastname: string;
  firstname: string;
  address: string;
  zipCode: string;
  city: string;
  cellPhone: string;
  homePhone: string;
  email: string;
  password: string;
  repeatPassword: string;
}

type NewFile = {
  name: string;
  type: "" | "iban" | "insurance";
  startDate?: string;
  endDate?: string;
  alerts: Array<Alert>;
};

type Category = {
  "@id": string;
  id: number;
  name: string;
};

export default function PartnersNew() {
  const auth = useAuth();
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [currentFile, setCurrentFile] = React.useState<{
    fIndex: number;
    file: File;
  } | null>(null);
  const [isNewFileActive, setIsNewFileActive] = React.useState<boolean>(false);
  const newFileRef = React.useRef<HTMLInputElement>(null);
  const [newFile, setNewFile] = React.useState<NewFile>({
    type: "",
    name: "",
    startDate: "",
    endDate: "",
    alerts: [],
  });
  const [input, setInput] = React.useState<Inputs>({
    isActive: false,
    hasMobileAccess: false,
    category: "",
    insurances: [],
    companyName: "",
    companyStatus: "",
    sex: 0,
    lastname: "",
    firstname: "",
    address: "",
    zipCode: "",
    city: "",
    cellPhone: "",
    homePhone: "",
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [categories, setCategories] = React.useState<Array<Category>>([]);
  const buttons = (
    <div className="flex flex-row gap-3">
      <BaseButton
        type="button"
        styleType="cancel"
        content="Annuler"
        onClick={() => {
          navigate(-1);
        }}
      />
      <BaseButton
        type="submit"
        styleType="primary"
        content="Créer le partenaire"
        icon={
          isLoading
            ? {
                icon: faSpinner,
                className: "animate-spin",
              }
            : undefined
        }
      />
    </div>
  );

  React.useEffect(() => {
    checkTokenAndFetch(`/api/partner_categories/get_listing`, {
      method: "GET",
    }).then((categories: { "hydra:member": Array<Category> }) => {
      setCategories(categories["hydra:member"]);
    });
  }, [auth.user.token]);
  function handleNewPartnerChange(event: React.BaseSyntheticEvent) {
    const param: keyof Inputs = event.currentTarget.name;

    setInput({
      ...input,
      [param]: event.currentTarget.value,
    });
  }
  function handleNewFileChange(
    event: React.BaseSyntheticEvent,
    alertIndex?: number,
    alertParam?: keyof Alert
  ) {
    if (alertIndex !== undefined && alertParam !== undefined) {
      setNewFile({
        ...newFile,
        alerts: newFile.alerts.map((alert, aIndex) => {
          if (aIndex === alertIndex) {
            return {
              ...alert,
              [alertParam]: event.currentTarget.value,
            };
          }
          return alert;
        }),
      });
    } else {
      const param: keyof NewFile = event.currentTarget.name;

      setNewFile({
        ...newFile,
        [param]: event.currentTarget.value,
      });
    }
  }
  function handleEditFileChange(
    event: React.BaseSyntheticEvent,
    alertIndex?: number,
    alertParam?: keyof Alert
  ) {
    if (currentFile) {
      if (alertIndex !== undefined && alertParam !== undefined) {
        setCurrentFile({
          ...currentFile,
          file: {
            ...currentFile.file,
            alerts: currentFile.file.alerts.map((alert, aIndex) => {
              if (aIndex === alertIndex) {
                return {
                  ...alert,
                  [alertParam]: event.currentTarget.value,
                };
              }
              return alert;
            }),
          },
        });
      } else {
        const param: Exclude<keyof File, "id"> = event.currentTarget.name;
        setCurrentFile({
          ...currentFile,
          file: { ...currentFile.file, [param]: event.currentTarget.value },
        });
      }
    }
  }
  function handleNewFile(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result as string;
      switch (newFile.type) {
        case "iban":
          setInput({
            ...input,
            iban: {
              name: newFile.name,
              createdAt: new Date().toISOString(),
              content: result,
              alerts: [],
            },
          });
          break;
        case "insurance":
          setInput({
            ...input,
            insurances: [
              ...input.insurances,
              {
                name: newFile.name,
                createdAt: new Date().toISOString(),
                content: result,
                startDate: newFile.startDate,
                endDate: newFile.endDate,
                alerts: newFile.alerts,
              },
            ],
          });
          break;
      }
      setNewFile({
        type: "",
        name: "",
        startDate: "",
        endDate: "",
        alerts: [],
      });
      setIsNewFileActive(false);
    };
    if (newFileRef.current?.files) {
      reader.readAsDataURL(newFileRef.current.files[0]);
    }
  }
  function body(): Inputs {
    const ret: Inputs = JSON.parse(JSON.stringify(input));
    ret.isActive = input.isActive ? true : false;
    ret.hasMobileAccess = input.hasMobileAccess ? true : false;
    ret.sex = +input.sex;
    if (ret.cellPhone) {
      try {
        const phone = parsePhoneNumber(ret.cellPhone, "FR");
        ret.cellPhone = phone.number;
      } catch (error) {
        ret.cellPhone = "";
      }
    }
    if (ret.homePhone) {
      try {
        const phone = parsePhoneNumber(ret.homePhone, "FR");
        ret.homePhone = phone.number;
      } catch (error) {
        ret.homePhone = "";
      }
    }
    ret.insurances = ret.insurances.map((insurance) => ({
      ...insurance,
      alerts: (insurance.alerts = insurance.alerts.map((alert) => {
        if (alert.recallDate === "") {
          alert.recallDate = undefined;
        }
        return alert;
      })),
    }));
    return ret;
  }

  return (
    <Page icon={{ icon: faBriefcase }} title="Nouveau partenaire">
      <>
        <BaseForm
          isNew={true}
          url={`/api/partners/post_new`}
          body={body()}
          loadingSetter={setIsLoading}
          successMessage="Le partenaire a bien été créé"
        >
          <div className="flex mb-14">
            {buttons}
            <div className="grow"></div>
            <BaseButton
              type="button"
              styleType="secondary"
              content="Ajouter un document"
              icon={{
                icon: faPlus,
              }}
              onClick={() => {
                setIsNewFileActive(true);
              }}
            />
          </div>
          <DoubleColumn
            config="llr"
            left={
              <>
                <Section title="Informations générales">
                  <Card className="max-h-max grid grid-cols-12">
                    <BaseLabel required label="Actif" className="col-span-3">
                      <BaseSwitch
                        isChecked={input.isActive}
                        onChange={(isChecked: boolean) => {
                          setInput({
                            ...input,
                            isActive: isChecked,
                          });
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Accès appli"
                      className="col-span-3"
                    >
                      <BaseSwitch
                        isChecked={input.hasMobileAccess}
                        onChange={(isChecked: boolean) => {
                          setInput({
                            ...input,
                            hasMobileAccess: isChecked,
                          });
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Catégorie"
                      className="col-span-6"
                    >
                      <BaseSelect
                        required
                        name="category"
                        value={input?.category}
                        onChange={handleNewPartnerChange}
                        options={categories
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((category) => ({
                            label: category.name,
                            value: category["@id"],
                          }))}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Raison sociale"
                      className="col-span-6"
                    >
                      <BaseInput
                        required
                        type="text"
                        name="companyName"
                        value={input?.companyName}
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Forme juridique"
                      className="col-span-6"
                    >
                      <BaseInput
                        required
                        type="text"
                        name="companyStatus"
                        value={input?.companyStatus}
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Civilité" className="col-span-2">
                      <BaseSelect
                        required
                        name="sex"
                        value={input?.sex}
                        onChange={handleNewPartnerChange}
                        options={[
                          { label: "M.", value: 1 },
                          { label: "Mme.", value: 2 },
                        ]}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Nom" className="col-span-5">
                      <BaseInput
                        required
                        type="text"
                        name="lastname"
                        value={input?.lastname}
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Prénom" className="col-span-5">
                      <BaseInput
                        required
                        type="text"
                        name="firstname"
                        value={input?.firstname}
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Adresse" className="col-span-12">
                      <BaseInput
                        required
                        type="text"
                        name="address"
                        value={input?.address}
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Code postal"
                      className="col-span-3"
                    >
                      <BaseInput
                        required
                        type="text"
                        pattern="[0-9]{5}"
                        name="zipCode"
                        value={input?.zipCode}
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Ville" className="col-span-3">
                      <BaseInput
                        required
                        type="text"
                        name="city"
                        value={input?.city}
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Téléphone Port."
                      className="col-span-3"
                    >
                      <BaseInput
                        required
                        type="text"
                        name="cellPhone"
                        value={input?.cellPhone}
                        pattern="[0-9]{10}"
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel label="Téléphone Fixe" className="col-span-3">
                      <BaseInput
                        type="text"
                        name="homePhone"
                        value={input?.homePhone}
                        pattern="[0-9]{10}"
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                  </Card>
                </Section>
                <Section title="Identifiants">
                  <Card className="max-h-max">
                    <BaseLabel required label="Adresse email">
                      <BaseInput
                        required
                        type="email"
                        name="email"
                        value={input?.email}
                        onChange={handleNewPartnerChange}
                      />
                    </BaseLabel>
                    <div className="flex flex-row gap-4">
                      <BaseLabel required label="Mot de passe">
                        <BaseInput
                          required
                          autoComplete="new-password"
                          type="password"
                          name="password"
                          value={input?.password}
                          onChange={handleNewPartnerChange}
                        />
                      </BaseLabel>
                      <BaseLabel required label="Confirmation">
                        <BaseInput
                          required
                          autoComplete="new-password"
                          repeat={input?.password}
                          type="password"
                          name="repeatPassword"
                          value={input?.repeatPassword}
                          onChange={handleNewPartnerChange}
                        />
                      </BaseLabel>
                    </div>
                  </Card>
                </Section>
              </>
            }
            right={
              <>
                {input.insurances.length > 0 && (
                  <FilesCategory
                    files={{
                      name: "Assurances",
                      roles: [],
                      files: input.insurances,
                    }}
                    onDelete={(fIndex) => {
                      setInput({
                        ...input,
                        insurances: input.insurances.filter(
                          (_, itemIndex) => itemIndex !== fIndex
                        ),
                      });
                    }}
                  />
                )}
                {input.iban !== undefined && (
                  <Section title="RIB">
                    <Card>
                      {[
                        <CardContent key={0} title={input.iban.name}>
                          {[
                            <div key={0} className="text-grey-2">
                              <span>
                                {new Date(
                                  input.iban.createdAt
                                ).toLocaleDateString()}
                              </span>
                              <FileWidgets
                                file={{
                                  name: input.iban.name,
                                  content: input.iban.content,
                                }}
                                onDelete={() => {
                                  setInput({
                                    ...input,
                                    iban: undefined,
                                  });
                                }}
                              />
                            </div>,
                          ]}
                        </CardContent>,
                      ]}
                    </Card>
                  </Section>
                )}
              </>
            }
          />
          <div className="mx-auto mt-14">{buttons}</div>
        </BaseForm>
        <BasePopup
          title="Ajout d'un document"
          isVisible={isNewFileActive}
          onClose={() => {
            setIsNewFileActive(false);
          }}
        >
          <form className="flex flex-col gap-4" onSubmit={handleNewFile}>
            <BaseLabel required label="Type du document">
              <BaseSelect
                required
                name="type"
                value={newFile.type}
                options={[
                  {
                    label: "RIB",
                    value: "iban",
                  },
                  {
                    label: "Assurance",
                    value: "insurance",
                  },
                ]}
                onChange={handleNewFileChange}
              />
            </BaseLabel>
            <BaseLabel required label="Nom du document">
              <BaseInput
                required
                type="text"
                name="name"
                value={newFile.name}
                onChange={handleNewFileChange}
              />
            </BaseLabel>
            {newFile.type === "insurance" && (
              <div className="flex gap-2">
                <BaseLabel required label="Date de début">
                  <BaseInput
                    required
                    type="date"
                    name="startDate"
                    value={newFile.startDate}
                    onChange={handleNewFileChange}
                  />
                </BaseLabel>
                <BaseLabel required label="Date de fin">
                  <BaseInput
                    required
                    type="date"
                    name="endDate"
                    value={newFile.endDate}
                    onChange={handleNewFileChange}
                  />
                </BaseLabel>
              </div>
            )}
            <BaseLabel required label="Document">
              <BaseInput required ref={newFileRef} type="file" />
            </BaseLabel>
            {newFile.type === "insurance" && (
              <>
                <div className="bg-grey-7 rounded p-4">
                  <div className="flex flex-wrap items-center gap-2 text-green-1 font-bold">
                    <div
                      className="flex place-items-center justify-center rounded-full bg-green-3 bg-opacity-20 w-8 h-8 cursor-pointer"
                      onClick={() => {
                        setNewFile({
                          ...newFile,
                          alerts:
                            newFile.type === "insurance"
                              ? [
                                  ...newFile.alerts,
                                  {
                                    name: "",
                                    description: "",
                                    hasClientAlert: false,
                                    dueDate: new Date()
                                      .toISOString()
                                      .slice(0, 10),
                                    recallDate: "",
                                  },
                                ]
                              : [],
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    Alerte
                  </div>
                </div>
                {newFile.alerts.map((alert, aIndex) => (
                  <div
                    key={aIndex}
                    className="bg-grey-7 rounded p-4 flex flex-col gap-3"
                  >
                    <BaseLabel required label="Nom de l'alerte">
                      <BaseInput
                        required
                        type="text"
                        value={alert.name}
                        onChange={(event) => {
                          handleNewFileChange(event, aIndex, "name");
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel label="Description">
                      <BaseInput
                        type="text"
                        value={alert.description}
                        onChange={(event) => {
                          handleNewFileChange(event, aIndex, "description");
                        }}
                      />
                    </BaseLabel>
                    <div className="flex gap-3">
                      <BaseLabel required label="Echeance">
                        <BaseInput
                          required
                          type="date"
                          value={alert.dueDate}
                          onChange={(event) => {
                            handleNewFileChange(event, aIndex, "dueDate");
                          }}
                        />
                      </BaseLabel>
                      <BaseLabel label="Rappel">
                        <BaseInput
                          type="date"
                          value={alert.recallDate}
                          onChange={(event) => {
                            handleNewFileChange(event, aIndex, "recallDate");
                          }}
                        />
                      </BaseLabel>
                    </div>
                    <BaseButton
                      type="button"
                      styleType="danger"
                      content="Supprimer"
                      className="w-min self-end"
                      onClick={() => {
                        setNewFile({
                          ...newFile,
                          alerts: newFile.alerts.filter((_, i) => i !== aIndex),
                        });
                      }}
                    />
                  </div>
                ))}
              </>
            )}
            <BaseButton
              type="submit"
              styleType="primary"
              content="Valider"
              className="mt-4 self-end"
            />
          </form>
        </BasePopup>
        <BasePopup
          title="Modifier le document"
          isVisible={currentFile !== null}
          className="min-w-[400px]"
          onClose={() => {
            setCurrentFile(null);
          }}
        >
          {currentFile && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setInput({
                  ...input,
                  insurances: input.insurances.map((insurance, iIndex) => {
                    if (iIndex === currentFile.fIndex) {
                      return currentFile.file;
                    } else {
                      return insurance;
                    }
                  }),
                });
                setCurrentFile(null);
              }}
            >
              <div className="flex flex-col gap-5">
                <BaseLabel required label="Libellé">
                  <BaseInput
                    required
                    type="text"
                    name="name"
                    value={currentFile.file.name}
                    onChange={handleEditFileChange}
                  />
                </BaseLabel>
                {currentFile.file.startDate && (
                  <div className="flex gap-2">
                    <BaseLabel required label="Date de début">
                      <BaseInput
                        required
                        type="date"
                        name="startDate"
                        value={currentFile.file.startDate}
                        onChange={handleNewFileChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Date de fin">
                      <BaseInput
                        required
                        type="date"
                        name="endDate"
                        value={currentFile.file.endDate}
                        onChange={handleNewFileChange}
                      />
                    </BaseLabel>
                  </div>
                )}
                <div className="bg-grey-7 rounded p-4">
                  <div className="flex flex-wrap items-center gap-2 text-green-1 font-bold">
                    <div
                      className="flex place-items-center justify-center rounded-full bg-green-3 bg-opacity-20 w-8 h-8 cursor-pointer"
                      onClick={() => {
                        setCurrentFile({
                          ...currentFile,
                          file: {
                            ...currentFile.file,
                            alerts: [
                              ...currentFile.file.alerts,
                              {
                                name: "",
                                description: "",
                                hasClientAlert: false,
                                dueDate: new Date().toISOString().slice(0, 10),
                                recallDate: "",
                              },
                            ],
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    Alerte
                  </div>
                </div>
                {currentFile.file.alerts.map((alert, aIndex) => (
                  <div
                    key={aIndex}
                    className="bg-grey-7 rounded p-4 flex flex-col gap-3"
                  >
                    <BaseLabel required label="Nom de l'alerte">
                      <BaseInput
                        required
                        type="text"
                        value={alert.name}
                        onChange={(event) => {
                          //TODO
                          handleEditFileChange(event, aIndex, "name");
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel label="Description">
                      <BaseInput
                        type="text"
                        value={alert.description}
                        onChange={(event) => {
                          handleEditFileChange(event, aIndex, "description");
                        }}
                      />
                    </BaseLabel>
                    <div className="flex gap-3">
                      <BaseLabel required label="Echeance">
                        <BaseInput
                          required
                          type="date"
                          value={alert.dueDate.slice(0, 10)}
                          onChange={(event) => {
                            handleEditFileChange(event, aIndex, "dueDate");
                          }}
                        />
                      </BaseLabel>
                      <BaseLabel label="Rappel">
                        <BaseInput
                          type="date"
                          value={alert.recallDate?.slice(0, 10)}
                          onChange={(event) => {
                            handleEditFileChange(event, aIndex, "recallDate");
                          }}
                        />
                      </BaseLabel>
                    </div>
                    <BaseButton
                      type="button"
                      styleType="danger"
                      content="Supprimer"
                      className="w-min place-self-end justify-self-end"
                      onClick={() => {
                        setCurrentFile({
                          ...currentFile,
                          file: {
                            ...currentFile.file,
                            alerts: currentFile.file.alerts.filter(
                              (_, i) => i !== aIndex
                            ),
                          },
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
              <BaseButton
                type="submit"
                styleType="primary"
                content="Enregistrer"
                className="mt-4 float-right"
              />
            </form>
          )}
        </BasePopup>
      </>
    </Page>
  );
}
