import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useCheckTokenAndFetch } from "../auth";
import BaseButton, { BaseButtonStyle } from "./BaseButton";
import BasePopup from "./BasePopup";

type BasePopupConfirmDeleteProps = {
  button: {
    styleType: BaseButtonStyle;
    content: string;
  };
  isVisible: boolean;
  onClose?: () => void;
  onSuccess: () => void;
  url?: string;
  children?: React.ReactNode;
};

export default function BasePopupConfirmDelete(
  props: BasePopupConfirmDeleteProps
) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const checkTokenAndFetch = useCheckTokenAndFetch();

  function close() {
    props.onClose && props.onClose();
  }
  function handleConfirm() {
    if (props.url) {
      setIsLoading(true);
      checkTokenAndFetch(props.url, { method: "DELETE" })
        .then(() => {
          props.onSuccess();
          close();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      props.onSuccess();
      close();
    }
  }

  return (
    <BasePopup
      title="Confirmation de suppression"
      isVisible={props.isVisible}
      onClose={props.onClose}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleConfirm();
        }
      }}
    >
      <>
        {props.children}
        <div className="mt-4">
          <BaseButton
            type="button"
            className="float-right"
            styleType={props.button.styleType}
            content={props.button.content}
            icon={
              isLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                  }
                : undefined
            }
            onClick={handleConfirm}
          />
        </div>
      </>
    </BasePopup>
  );
}
