import React from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Tab from "./Tab";
import TableRow from "./TableRow";

type TableProps = {
  headers: Array<{
    name?: string;
    order?: boolean;
  }>;
  defaultOrder?: {
    field: number;
    isAsc: boolean;
  };
  pagination?: {
    isActive?: boolean;
    itemPerPage?: number;
  };
  categories?: {
    index: number;
    default: number | boolean;
    labels: Array<{
      label: string;
      value: number | boolean;
    }>;
  };
  search?: Array<number>;
  total?: {
    label: string;
    indexes: Array<number>;
    formatter?: (value: number) => string;
  };
  children: Array<
    Array<{
      elem: React.ReactNode;
      value: number | string | boolean;
    }>
  >;
};

export default function Table(
  props:
    | TableProps
    | {
      left: {
        name: string;
        props: TableProps;
      };
      center: {
        name: string;
        props: TableProps;
      };
      right: {
        name: string;
        props: TableProps;
      };
    }
) {
  const [activeTable, setActiveTable] = React.useState<"left" | "right" | "center">(
    "left"
  );
  const tableProps = "left" in props ? props[activeTable].props : props;
    // const itemPerPage = 10;
  const itemPerPage = React.useMemo<number>(() =>
    tableProps.pagination?.itemPerPage === undefined ? 10 : tableProps.pagination?.itemPerPage
  , [tableProps.pagination?.itemPerPage]
  );
  const [activePage, setActivePage] = React.useState<number>(1);
  const [activeCategory, setActiveCategory] = React.useState<
    number | boolean | undefined
  >(tableProps.categories?.default);
  const [sort, setSort] = React.useState<{
    field: number;
    isAsc: boolean;
  }>({
    field:
      tableProps.defaultOrder === undefined ? 0 : tableProps.defaultOrder.field,
    isAsc:
      tableProps.defaultOrder === undefined
        ? true
        : tableProps.defaultOrder.isAsc,
  });
  const [search, setSearch] = React.useState<string>("");
  const totalIndexes = React.useMemo(
    () => tableProps.total?.indexes.sort((a, b) => a - b),
    [tableProps.total?.indexes]
  );

  React.useEffect(() => {
    const dataSize = tableProps.children.filter(arrayFilter).length;
    const nbPages = Math.ceil(dataSize / itemPerPage);
    if (activePage > nbPages) {
      setActivePage(1);
    }
  }, [tableProps.children]);
  React.useEffect(() => {
    setSort({
      field:
        tableProps.defaultOrder === undefined
          ? 0
          : tableProps.defaultOrder.field,
      isAsc:
        tableProps.defaultOrder === undefined
          ? true
          : tableProps.defaultOrder.isAsc,
    });
  }, [activeTable]);
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }
  function CategoryName(p: { name: string; value: "left" | "right" | "center" }) {
    return (
      <div
        className="bg-white text-green-1 font-extrabold cursor-pointer border-t border-l border-r border-grey-2 mt-1 p-2"
        onClick={() => {
          setActiveTable(p.value);
          if ("left" in props) {
            setActiveCategory(props[p.value].props.categories?.default);
          }
        }}
      >
        {p.name}
      </div>
    );
  }
  function categories(
    labels: Array<{
      label: string;
      value: number | boolean;
    }>,
    table?: "left" | "right" | "center"
  ) {
    const currentProps =
      table && "left" in props ? props[table].props : tableProps;
    return labels.map((category, cIndex) => (
      <Tab
        key={cIndex}
        title={category.label}
        value={
          currentProps.children.filter((child) => {
            if (!currentProps.categories) {
              return false;
            }
            return (
              child[currentProps.categories.index].value === category.value
            );
          }).length
        }
        isActive={
          activeCategory === category.value &&
          (table ? activeTable === table : true)
        }
        onClick={() => {
          if (table) {
            setActiveTable(table);
          }
          setActiveCategory(category.value);
        }}
      />
    ));
  }
  function total(props: TableProps, index: number) {
    return props.children.filter(arrayFilter).reduce((total, child) => {
      if (tableProps.total) {
        const value = child[index].value;
        if (typeof value === "number") {
          return total + value;
        }
      }
      return total;
    }, 0);
  }
  function arrayFilter(
    child: Array<{
      elem: React.ReactNode;
      value: number | string | boolean;
    }>
  ) {
    if (
      tableProps.categories &&
      !(child[tableProps.categories.index].value === activeCategory)
    ) {
      return false;
    }
    if (
      tableProps.search &&
      tableProps.search.length &&
      !child.reduce((inSearch: boolean, item, iIndex) => {
        if (!tableProps.search?.includes(iIndex)) {
          return inSearch || false;
        }
        return (
          inSearch ||
          item.value.toString().toLowerCase().includes(search.toLowerCase())
        );
      }, false)
    ) {
      return false;
    }
    return true;
  }
  function pagination() {
    const dataSize = tableProps.children.filter(arrayFilter).length;
    const nbPages = Math.ceil(dataSize / itemPerPage);
    const suspension = <div className="px-1 rounded">...</div>;

    if (nbPages <= 1) {
      return null;
    }

    return (
      <div className="my-2 flex justify-end items-center gap-3">
        <FontAwesomeIcon
          className={activePage === 1 ? "text-grey-2" : "cursor-pointer"}
          icon={faChevronLeft}
          onClick={() => {
            if (activePage !== 1) {
              setActivePage(activePage - 1);
            }
          }}
        />
        {[...Array(nbPages)].map((_, i) => (
          <React.Fragment key={i}>
            {i + 1 === 2 && activePage > 4 && suspension}
            <div
              className={twMerge(
                "px-1 rounded",
                activePage === i + 1
                  ? "bg-green-1 text-white"
                  : "cursor-pointer",
                !(i + 1 === 1 || i + 1 === nbPages) &&
                  !(
                    (i + 1 === 2 && activePage === 4) ||
                    (i + 1 === nbPages - 1 && activePage === nbPages - 3)
                  ) &&
                  (i + 1 > activePage + 1 || i + 1 < activePage - 1) &&
                  "hidden"
              )}
              onClick={() => {
                if (activePage !== i + 1) {
                  setActivePage(i + 1);
                }
              }}
            >
              {i + 1}
            </div>
            {i + 1 === nbPages - 1 && activePage < nbPages - 3 && suspension}
          </React.Fragment>
        ))}
        <FontAwesomeIcon
          className={activePage === nbPages ? "text-grey-2" : "cursor-pointer"}
          icon={faChevronRight}
          onClick={() => {
            if (activePage !== nbPages) {
              setActivePage(activePage + 1);
            }
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {(tableProps.search || tableProps.categories) && (
        <div className="flex flex-row gap-2.5">
          {"left" in props ? (
            <>
              {/* <CategoryName name={props.left.name} value="left" /> */}
              {props.left.props.categories &&
                categories(props.left.props.categories.labels, "left")}
              <div className="grow"></div>
              { "center" in props && props.center.props.categories &&
                props.center.props.categories &&
                  categories(props.center.props.categories.labels, "center")
              }
              <div className="grow"></div>
              {/* <CategoryName name={props.right.name} value="right" /> */}
              {props.right.props.categories &&
                categories(props.right.props.categories.labels, "right")}
            </>
          ) : (
            props.categories && categories(props.categories.labels)
          )}
          {tableProps.search && (
            <>
              <div className="flex-grow"></div>
              <div className="flex items-center mb-4 -mr-4">
                <input
                  type="text"
                  className="w-48 ring-1 ring-grey-4 py-1.5 pl-5 pr-10 rounded-full font-medium"
                  placeholder="Rechercher"
                  value={search}
                  onChange={handleChange}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="text-grey-2 text-lg relative -left-8"
                />
              </div>
            </>
          )}
        </div>
      )}
      <table>
        <thead className="bg-white">
          <tr>
            {tableProps.headers.map((header, hIndex) => (
              <th key={hIndex} className="p-4 text-left whitespace-nowrap">
                {header.name}
                {header.order && (
                  <span
                    className="ml-3 cursor-pointer"
                    onClick={() => {
                      if (sort.field === hIndex) {
                        setSort({
                          field: hIndex,
                          isAsc: !sort.isAsc,
                        });
                      } else {
                        setSort({
                          field: hIndex,
                          isAsc: true,
                        });
                      }
                    }}
                  >
                    {[faLongArrowAltDown, faLongArrowAltUp].map(
                      (icon, iIndex) => (
                        <FontAwesomeIcon
                          key={iIndex}
                          icon={icon}
                          className={twMerge(
                            "text-xs font-extralight -mx-px",
                            sort.field === hIndex &&
                              (sort.isAsc ? iIndex === 0 : iIndex === 1)
                              ? "text-darkgrey"
                              : "text-grey-2"
                          )}
                        />
                      )
                    )}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableProps.children
            .filter(arrayFilter)
            .sort((a, b) => {
              const aVal = a[sort.field].value;
              const bVal = b[sort.field].value;
              if (typeof aVal === "number" && typeof bVal === "number") {
                return sort.isAsc ? aVal - bVal : bVal - aVal;
              }
              if (typeof aVal === "string" && typeof bVal === "string") {
                return sort.isAsc
                  ? aVal.localeCompare(bVal)
                  : bVal.localeCompare(aVal);
              }
              return 0;
            })
            .filter(
              (_, index) => Math.floor(index / itemPerPage) === activePage - 1
            )
            .map((child, cIndex) => (
              <TableRow key={cIndex}>
                {child
                  .filter((_item, iIndex) => iIndex < tableProps.headers.length)
                  .map((item, iIndex) => (
                    <React.Fragment key={iIndex}>{item.elem}</React.Fragment>
                  ))}
              </TableRow>
            ))}
          {tableProps.children.filter(arrayFilter).length === 0 ? (
            <tr className="h-12">
              <td
                colSpan={tableProps.headers.length}
                className="border-2 border-dashed border-grey-2 text-center italic text-grey-2"
              >
                Aucun élément
              </td>
            </tr>
          ) : (
            tableProps.total &&
            totalIndexes && (
              <tr className="bg-green-1 bg-opacity-20">
                <td colSpan={totalIndexes[0]} className="p-4 font-bold">
                  {tableProps.total.label}
                </td>
                {totalIndexes.map(
                  (totalIndex, tIndex) =>
                    tableProps.total && (
                      <td
                        key={tIndex}
                        colSpan={
                          totalIndexes[tIndex + 1]
                            ? totalIndexes[tIndex + 1] - totalIndex
                            : tableProps.headers.length - totalIndex
                        }
                        className="p-4 font-bold"
                      >
                        {tableProps.total.formatter
                          ? tableProps.total.formatter(
                              total(tableProps, totalIndex)
                            )
                          : total(tableProps, totalIndex)}
                      </td>
                    )
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
      {pagination()}
    </div>
  );
}
