import React from "react";
import { toast } from "react-toastify";
import { useCheckTokenAndFetch } from "../auth";
import BaseTooltip from "./BaseTooltip";

type Phase = {
  id: number;
  name: string;
  position: number;
  beginDate: string;
  duration: number;
};

type Lot = {
  id: number;
  name: string;
  position: number;
  color: string;
  phases: Array<Phase>;
};

type PlanningLotPhaseProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  tableWidth: number;
  hovered: {
    begin: Date;
    duration: number;
  };
  nbDays: number;
  begin: number;
  phasefocusdate: string;
  lotColor: string;
  phase: Phase;
  zoom: number;
  // setZoom: React.Dispatch<React.SetStateAction<number>>;
  setPhase: (id: number, p: Phase) => void;
};

export default function PlanningLotPhase(props: PlanningLotPhaseProps) {
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const ref = React.useRef<HTMLDivElement>(null);
  const scrollTargetRef = React.useRef<HTMLTableCellElement>(null);
  const [tdWidth, setTdWidth] = React.useState<number>(0);
  const [linePosition, setLinePosition] = React.useState<number>(0);
  const [lineWidth, setLineWidth] = React.useState<number>(0);
  const [activeDrag, setActiveDrag] = React.useState<"begin" | "duration" | "begin-duration">();
  const [startDragX, setStartDragX] = React.useState<number>(0);
  const [newBeginDate, setNewBeginDate] = React.useState<Date>(new Date());
  const [newDuration, setNewDuration] = React.useState<number>(1);
  const [phaseEndDate, setPhaseEndDate] = React.useState<string>("");
  const [onZoomParams, setOnZoomParams] = React.useState<{
    xRatio: number;
    xPosition: number;
  }>({
    xRatio: 0,
    xPosition: 0,
  });

  const [moveFocus, setMoveFocus] = React.useState<HTMLElement | null>(null);

  function zoomOnFocus() {
    if(moveFocus) {
      moveFocus.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    }
  }

  const dateToDays = React.useCallback((date: string) => {
    return new Date(date).getTime() / 1000 / 60 / 60 / 24;
  }, []);
  const dateToRelativeDay = React.useCallback(
    (date: string) => {
      return dateToDays(date) - props.begin / 1000 / 60 / 60 / 24 + 1;
    },
    [props.begin]
  );

  React.useEffect(() => {
    setPhaseEndDate(
      new Date(
        new Date(props.phase.beginDate).getTime() +
          props.phase.duration * 24 * 60 * 60 * 1000
      ).toISOString()
    );
  });

  // React.useEffect(() => {
  //   if (scrollTargetRef.current) {
  //     props.scrollableRef.current?.scrollTo(
  //       onZoomParams.xRatio *
  //         scrollTargetRef.current.getBoundingClientRect().width -
  //         onZoomParams.xPosition,
  //       0
  //     );
  //   }
  // }, [onZoomParams]);

  return (
    <>
    <tr>
      <td className="h-5 font-bold leading-4 text-grey-1 bg-grey-55 flex items-center px-1 w-48 sticky left-0 scroll-mt-40"></td>
      <td className="px-3.5 py-0">
        <table className="">
          <tbody>
            <tr>
              {
              // create as much td as there are days in props.nbDays
                Array.from(Array(props.nbDays).keys()).map((day) => {
                  return (
                    <td
                      key={day}
                      className="h-5 border-x border-grey-3 bg-white"
                      style={{
                        width: `${lineWidth / props.nbDays}px`,
                      }}
                    >
                    </td>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <td
        className="h-5 font-bold leading-4 text-grey-1 bg-grey-55 flex items-center px-1 w-48 sticky left-0 z-10 scroll-mt-40"
        data-phasefocus={
          props.phasefocusdate >= props.phase.beginDate && props.phasefocusdate <= phaseEndDate
          ? "phasefocus" : ""
        }
        ref={(ref) => {
          if (ref) {
            const bounds = ref.getBoundingClientRect();
            setLinePosition(bounds.left + bounds.width);
            setTdWidth(ref.clientWidth);
          }
        }}
      >
        {props.phase.name}
      </td>
      <td
        ref={scrollTargetRef}
        className="relative px-3.5 py-0 bg-white w-full"
        // onWheel={(e: React.WheelEvent<HTMLTableCellElement>) => {
        //   if (!e.shiftKey) {
        //     if (e.deltaY > 0) {
        //       props.setZoom(Math.max(100, props.zoom / 1.1));
        //       console.log("zoom : ", Math.max(100, props.zoom / 1.1));
        //     }
        //     if (e.deltaY < 0 && props.nbDays > 15 * (props.zoom / 100)) {
        //       props.setZoom(Math.max(100, props.zoom * 1.1));
        //       console.log("zoom : ", Math.max(100, props.zoom * 1.1));
        //     }
        //     setOnZoomParams({
        //       xRatio:
        //         (e.clientX - e.currentTarget.getBoundingClientRect().x) /
        //         e.currentTarget.getBoundingClientRect().width,
        //       xPosition: e.clientX - linePosition,
        //     });
        //     console.log(
        //       "xRatio : ",
        //       (e.clientX - e.currentTarget.getBoundingClientRect().x) /
        //         e.currentTarget.getBoundingClientRect().width
        //     );
        //     console.log(
        //       "xPosition : ",
        //       e.clientX - linePosition
        //     );
        //   }
        // }}
      >
        <div
          className="absolute top-0 w-4 h-full bg-green-1 bg-opacity-20"
          style={{
            marginLeft: `${
              (dateToRelativeDay(props.hovered.begin.toISOString()) - 1) /
              (props.nbDays / lineWidth)
            }px`,
            width: `${props.hovered.duration / (props.nbDays / lineWidth)}px`,
          }}
        ></div>
        <div
          className="bg-grey-6 rounded-md h-5"
          ref={(ref) => {
            if (ref) {
              setLineWidth(ref.clientWidth);
            }
          }}
          style={{
            width: `calc((${props.tableWidth}px - ${tdWidth}px - 1.75rem) * ${
              props.zoom / 100
            })`,
          }}
        >
          <div
            className="h-5 rounded-md flex items-center cursor-ew-resize"
            style={{
              backgroundColor: props.lotColor,
              marginLeft: `${
                (dateToRelativeDay(props.phase.beginDate) - 1) /
                (props.nbDays / lineWidth)
              }px`,
              width: `${props.phase.duration / (props.nbDays / lineWidth)}px`,
            }}
            draggable
            onDragStart={(e) => {
              e.stopPropagation();
              e.currentTarget.classList.add("opacity-50");
              e.dataTransfer.dropEffect = "move";
              e.dataTransfer.setDragImage(document.createElement("img"), 0, 0);
              setStartDragX(
                e.clientX -
                  (dateToRelativeDay(props.phase.beginDate) - 1) /
                    (props.nbDays / lineWidth)
              );
              setNewBeginDate(new Date(props.phase.beginDate));
              setMoveFocus(e.currentTarget);
            }}
            onDrag={(e) => {
              e.stopPropagation();
              if (activeDrag === undefined) {
                setActiveDrag("begin");
              }
              const bg = new Date(
                (e.clientX - startDragX) *
                  (props.nbDays / lineWidth) *
                  24 *
                  60 *
                  60 *
                  1000 +
                  props.begin
              );
              bg.setHours(2, 0, 0, 0);
              setNewBeginDate(bg);
              if (ref.current) {
                ref.current.style.marginLeft =
                  (dateToRelativeDay(bg.toISOString()) - 1) /
                    (props.nbDays / lineWidth) +
                  "px";
              }
            }}
            onDragEnd={(e) => {
              e.stopPropagation();
              e.currentTarget.classList.remove("opacity-50");
              if (ref.current) {
                const newBegin = new Date(
                  (e.clientX - startDragX) *
                    (props.nbDays / lineWidth) *
                    24 *
                    60 *
                    60 *
                    1000 +
                    props.begin
                );
                checkTokenAndFetch(`/api/phases/${props.phase.id}/patch_edit`, {
                  headers: {
                    "Content-Type": "application/merge-patch+json",
                  },
                  method: "PATCH",
                  body: JSON.stringify({
                    beginDate: newBegin.toISOString().slice(0, 10),
                  }),
                })
                  .then((phase: Phase) => {
                    props.setPhase(props.phase.id, {
                      ...props.phase,
                      beginDate: phase.beginDate,
                    });
                    toast.success(
                      "La date de début de la phase a bien été mise à jour"
                    );
                  })
                  .finally(() => {
                    setActiveDrag(undefined);
                    zoomOnFocus();
                  });
              }
            }}
          >
            <div
              className="rounded-full bg-white h-3 w-3 m-1 cursor-col-resize relative"
              draggable
              onDragStart={(e) => {
                e.stopPropagation();
                e.currentTarget.parentElement?.classList.add("opacity-50");
                e.dataTransfer.dropEffect = "move";
                e.dataTransfer.setDragImage(
                  document.createElement("img"),
                  0,
                  0
                );
                setStartDragX(
                  e.clientX -
                    (dateToRelativeDay(props.phase.beginDate) - 1) /
                      (props.nbDays / lineWidth)
                );
                setNewBeginDate(new Date(props.phase.beginDate));
                setNewDuration(props.phase.duration);
                setActiveDrag("begin-duration");
                setMoveFocus(e.currentTarget);
              }}
              onDrag={(e) => {
                e.stopPropagation();
                const bg = new Date(
                  (e.clientX - startDragX) *
                    (props.nbDays / lineWidth) *
                    24 *
                    60 *
                    60 *
                    1000 +
                    props.begin
                );
                bg.setHours(2, 0, 0, 0);
                setNewBeginDate(bg);

                const duration = Math.floor(
                  dateToRelativeDay(props.phase.beginDate) + props.phase.duration -
                  (e.clientX - startDragX + 8) * (props.nbDays / lineWidth)
                );
                if (duration > 0 && e.clientX > 0) {
                  setNewDuration(duration);
                  if (ref.current) {
                    ref.current.style.width = `${
                      duration / (props.nbDays / lineWidth)
                    }px`;
                    ref.current.style.marginLeft =
                      (dateToRelativeDay(bg.toISOString()) - 1) /
                        (props.nbDays / lineWidth) +
                      "px";
                  }
                }
              }}
              onDragEnd={(e) => {
                e.stopPropagation();
                e.currentTarget.parentElement?.classList.remove("opacity-50");

                if (ref.current) {
                  const newBegin = new Date(
                    (e.clientX - startDragX) *
                      (props.nbDays / lineWidth) *
                      24 *
                      60 *
                      60 *
                      1000 +
                      props.begin
                  );

                  checkTokenAndFetch(`/api/phases/${props.phase.id}/patch_edit`, {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                    method: "PATCH",
                    body: JSON.stringify({
                      duration: newDuration,
                      beginDate: newBegin.toISOString().slice(0, 10)
                    }),
                  })
                    .then((phase: Phase) => {
                      props.setPhase(props.phase.id, {
                        ...props.phase,
                        duration: phase.duration,
                        beginDate: phase.beginDate,
                      });
                      toast.success(
                        "La phase a bien été mise à jour"
                      );
                    })
                    .finally(() => {
                      setActiveDrag(undefined);
                      zoomOnFocus();
                    });
                }
              }}
              ></div>
            <div className="grow"></div>
            <div
              className="rounded-full bg-white h-3 w-3 m-1 cursor-col-resize relative"
              draggable
              onDragStart={(e) => {
                e.stopPropagation();
                e.currentTarget.parentElement?.classList.add("opacity-50");
                e.dataTransfer.dropEffect = "move";
                e.dataTransfer.setDragImage(
                  document.createElement("img"),
                  0,
                  0
                );
                setStartDragX(
                  e.clientX -
                    (dateToRelativeDay(props.phase.beginDate) -
                      1 +
                      props.phase.duration) /
                      (props.nbDays / lineWidth)
                );
                setNewDuration(props.phase.duration);
                setActiveDrag("duration");
                setMoveFocus(e.currentTarget);
              }}
              onDrag={(e) => {
                e.stopPropagation();
                const duration = Math.floor(
                  (e.clientX - startDragX) * (props.nbDays / lineWidth) -
                    dateToRelativeDay(props.phase.beginDate) +
                    1
                );
                if (duration > 0) {
                  setNewDuration(duration);
                  if (ref.current) {
                    ref.current.style.width = `${
                      duration / (props.nbDays / lineWidth)
                    }px`;
                  }
                }
              }}
              onDragEnd={(e) => {
                e.stopPropagation();
                e.currentTarget.parentElement?.classList.remove("opacity-50");
                checkTokenAndFetch(`/api/phases/${props.phase.id}/patch_edit`, {
                  headers: {
                    "Content-Type": "application/merge-patch+json",
                  },
                  method: "PATCH",
                  body: JSON.stringify({
                    duration: newDuration,
                  }),
                })
                  .then((phase: Phase) => {
                    props.setPhase(props.phase.id, {
                      ...props.phase,
                      duration: phase.duration,
                    });
                    toast.success(
                      "La durée de la phase a bien été mise à jour"
                    );
                  })
                  .finally(() => {
                    setActiveDrag(undefined);
                    zoomOnFocus();
                  });
              }}
            ></div>
          </div>
          {activeDrag && (
            <div
              ref={ref}
              className="bg-darkgrey rounded-md opacity-40 pointer-events-none h-5 relative -top-5 z-20"
              style={{
                width: `${props.phase.duration / (props.nbDays / lineWidth)}px`,
                marginLeft: `${
                  (dateToRelativeDay(props.phase.beginDate) - 1) /
                  (props.nbDays / lineWidth)
                }px`,
              }}
            >
              {activeDrag === "duration" && (
                <BaseTooltip direction="right">
                  Durée : {newDuration} jours
                </BaseTooltip>
              )}
              {activeDrag === "begin" && (
                <BaseTooltip direction="bottom">
                  Début : {newBeginDate.toLocaleDateString()}
                </BaseTooltip>
              )}
              {activeDrag === "begin-duration" && (
                <BaseTooltip direction="left">
                  Début : {newBeginDate.toLocaleDateString()}
                  <br />
                  Durée : {newDuration} jours
                </BaseTooltip>
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
    </>
  );
}
