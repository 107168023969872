import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useCheckTokenAndFetch } from "../auth";

type File = {
  id: number;
  createdAt: string;
  name: string;
  content: string;
};

type DownloadFileURL = {
  url: string;
};

export default function DownloadFileIcon(props: DownloadFileURL) {
  const checkTokenAndFetch = useCheckTokenAndFetch();

  return (
    <a
      onClick={() => {
        checkTokenAndFetch(props.url, {
          method: "GET",
        }).then((doc: File) => {
          const link = document.createElement('a');
          link.href = doc.content;
          link.setAttribute(
            'download',
            doc.name,
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      }}
    >
      <FontAwesomeIcon
        className="cursor-pointer hover:text-green-1"
        icon={faFileUpload}
      />
    </a>
  );
}
