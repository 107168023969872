import React from "react";

type CardContentProps = {
  title?: React.ReactNode;
  children: Array<React.ReactNode>;
};

export default function CardContent(props: CardContentProps) {
  return (
    <div className="bg-grey-7 rounded-md px-6 py-5">
      {props.title && <h3 className="font-extrabold mb-5">{props.title}</h3>}
      {React.Children.map(props.children, (child, index) => (
        <>
          {child}
          {index < props.children.length - 1 && (
            <hr className="my-4 border opacity-5" />
          )}
        </>
      ))}
    </div>
  );
}
