import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BasePopupConfirmDelete from "../components/BasePopupConfirmDelete";
import Page from "../components/Page";
import Table from "../components/Table";
import BaseSelect from "../components/BaseSelect";
import { useCheckTokenAndFetch } from "../auth";
import Loading from "../components/Loading";
import { useWorksites } from "./layout";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function Worksites() {
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [worksites, setWorksites] = useWorksites();
  const [isPopupActive, setIsPopupActive] = React.useState<boolean>(false);
  const [worksiteToDelete, setWorksiteToDelete] = React.useState<number>();
  const worksiteStates = [
    {
      label: "En création",
      value: 0,
    },
    {
      label: "Ouverts",
      value: 1,
    },
    {
      label: "En standby",
      value: 2,
    },
    {
      label: "Terminés",
      value: 3,
    },
  ];

  return (
    <Page icon={{ icon: faBuilding }} title="Chantiers">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row">
          <Link to="ajouter">
            <BaseButton
              type="button"
              styleType="primary"
              content="Ajouter un chantier"
              icon={{ icon: faPlus }}
            />
          </Link>
        </div>
        {worksites ? (
          <Table
            categories={{
              index: 4,
              default: 0,
              labels: worksiteStates,
            }}
            headers={[
              {
                name: "Chantier",
                order: true,
              },
              {
                name: "Début",
                order: true,
              },
              {
                name: "Fin prévue",
                order: true,
              },
              {
                name: "Infos / Tâches",
                order: false,
              },
              {
                name: "État",
                order: false,
              },
              {},
            ]}
          >
            {worksites.map((worksite) => {
              let begin = Infinity;
              let end = -Infinity;

              worksite.lots.forEach((lot) => {
                lot.phases.forEach((phase) => {
                  const date = new Date(phase.beginDate);
                  begin = Math.min(begin, date.getTime());
                  date.setDate(date.getDate() + phase.duration);
                  end = Math.max(end, date.getTime());
                });
              });

              return [
                {
                  elem: worksite.name,
                  value: worksite.name,
                },
                {
                  elem: new Date(begin).toLocaleDateString(),
                  value: begin,
                },
                {
                  elem: new Date(end).toLocaleDateString(),
                  value: end,
                },
                {
                  elem: (
                    <div className="flex ">
                      <Badge
                        value={worksite.memos.length}
                        type={worksite.memos.length ? "primary" : "inactive"}
                      />
                      <span>/</span>
                      <Badge
                        value={
                          worksite.tasks.filter((task) => !task.isDone).length
                        }
                        type={worksite.tasks.length ? "primary" : "inactive"}
                      />
                    </div>
                  ),
                  value: 0,
                },
                {
                  elem: (
                    <BaseSelect
                      name="state"
                      value={worksite.state}
                      options={worksiteStates}
                      onChange={(event) => {
                        const val = +event.currentTarget.value;
                        checkTokenAndFetch(
                          `/api/worksites/${worksite.id}/patch_state`,
                          {
                            headers: {
                              "Content-Type": "application/merge-patch+json",
                            },
                            method: "PATCH",
                            body: JSON.stringify({
                              state: val,
                            }),
                          }
                        ).then(() => {
                          worksite.state = val;
                          setWorksites(worksites.slice());
                          toast.success(
                            "L'état du chantier a bien été mis à jour"
                          );
                        });
                      }}
                    />
                  ),
                  value: worksite.state,
                },
                {
                  elem: (
                    <span className="text-grey-2 flex flex-row gap-3 items-center">
                      <Link to={`${worksite.id}`}>
                        <FontAwesomeIcon
                          className="cursor-pointer hover:text-green-1"
                          icon={faPen}
                        />
                      </Link>
                      <FontAwesomeIcon
                        className="cursor-pointer hover:text-green-1"
                        icon={faTrash}
                        onClick={() => {
                          setWorksiteToDelete(worksite.id);
                          setIsPopupActive(true);
                        }}
                      />
                    </span>
                  ),
                  value: 0,
                },
              ];
            })}
          </Table>
        ) : (
          <Loading />
        )}
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={isPopupActive}
          onClose={() => {
            setIsPopupActive(false);
          }}
          onSuccess={() => {
            if (worksites) {
              setWorksites(
                worksites.filter((item) => item.id !== worksiteToDelete)
              );
              toast.success("Le chantier a bien été supprimé");
            }
          }}
          url={`/api/worksites/${worksiteToDelete}`}
        >
          Etes-vous sûr de vouloir supprimer le chantier ?
        </BasePopupConfirmDelete>
      </div>
    </Page>
  );
}
