import React from "react";
import { twMerge } from "tailwind-merge";

type BaseSelectProps = {
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  value?: number | string | Array<string>;
  placeholder?: string;
  options: Array<{
    label: string;
    value: number | string | Array<string>;
  }>;
  className?: string;
  onChange?: React.FormEventHandler<HTMLSelectElement>;
};

export default function BaseSelect(props: BaseSelectProps) {
  return (
    <select
      multiple={props.multiple}
      disabled={props.disabled}
      className={twMerge(
        "bg-white border border-grey-4 text-darkgrey rounded px-1 py-[0.21875rem] w-full disabled:bg-grey-4",
        props.className
      )}
      name={props.name}
      required={props.required}
      value={props.value}
      onChange={props.onChange}
    >
      <option value="" hidden={props.required}>
        {props.placeholder ? props.placeholder : "-"}
      </option>
      {props.options.map((value, vIndex) => (
        <option key={vIndex} value={value.value}>
          {value.label}
        </option>
      ))}
    </select>
  );
}
