import React from "react";
import { useParams } from "react-router-dom";
import FilesList from "../components/FilesList";
import Loading from "../components/Loading";
import { useCheckTokenAndFetch } from "../auth";
import { toast } from "react-toastify";

type Alert = {
  id?: number;
  name: string;
  description: string;
  hasClientAlert: boolean;
  dueDate: string;
  recallDate?: string;
};

type File = {
  id?: number;
  name: string;
  createdAt: string;
  content?: string;
  startDate?: string;
  endDate?: string;
  alerts: Array<Alert>;
};

type FilesCategory = {
  id?: number;
  name: string;
  roles: Array<string>;
  files: Array<File>;
};

type Report = {
  attachedFile: Array<File>;
};

type Lot = {
  reports: Array<Report>;
  phases: Array<Phase>;
};

type Phase = {
  beginDate: string;
  duration: number;
};

type WorksiteFilesType = {
  id: number;
  client: string;
  name: string;
  partners: Array<string>;
  lots: Array<Lot>;
  filesCategories: Array<FilesCategory>;
  generalReports: Array<Report>;
};


export default function WorksiteFiles() {
  const params = useParams();
  const [worksite, setWorksite] = React.useState<WorksiteFilesType>();
  const [generalReports, setGeneralReports] = React.useState<Array<Report>>([]); // [1
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [worksiteBeginDate, setWorksiteBeginDate] = React.useState<string>("");
  const [worksiteEndDate, setWorksiteEndDate] = React.useState<string>("");
  const [partnersDocuments, setPartnersDocuments] = React.useState<Array<File>>([]);

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/${params.id}/get_files`, {
      method: "GET",
    }).then((worksite) => {
      setWorksite(worksite);
    })

    checkTokenAndFetch(`/api/worksites/${params.id}/get_general_reports`, {
      method: "GET",
          }).then((generalReports) => {
            setGeneralReports(generalReports.generalReports);
          })
      }, [params.id]);

  React.useEffect(() => {
    let begin = Infinity;
    let end = -Infinity;

    worksite?.lots.forEach((lot) => {
      lot.phases.forEach((phase) => {
        if (phase.beginDate) {
          const date = new Date(phase.beginDate);
          begin = Math.min(begin, date.getTime());
          date.setDate(date.getDate() + phase.duration);
          end = Math.max(end, date.getTime());
        }
      });
    });

    if (begin !== Infinity && end !== Infinity) {
      setWorksiteBeginDate(new Date(begin).toISOString());
      setWorksiteEndDate(new Date(end).toISOString());
    }
  }, [worksite]);

  React.useEffect(() => {
    if (worksite === undefined) return;
    if(worksiteBeginDate === undefined) return;
    if(worksiteEndDate === undefined) return;
    worksite.partners.map((partner: string) =>
      checkTokenAndFetch(`${partner}/get_files`, {
        method: "GET",
      }).then((partnerDocuments) => {
        partnerDocuments.insurances
        .sort((a: File, b: File) => a.name.localeCompare(b.name))
        .map((document: File) => {
          if(!document.startDate) {
            setPartnersDocuments(partnersDocuments => [...partnersDocuments, document])
          }
          else if(!document.endDate) {
            setPartnersDocuments(partnersDocuments => [...partnersDocuments, document])
          }
          else if(
            document.startDate < worksiteBeginDate && document.endDate > worksiteBeginDate ||
            document.startDate < worksiteEndDate && document.endDate > worksiteEndDate ||
            document.startDate > worksiteBeginDate && document.endDate < worksiteEndDate ||
            document.startDate < worksiteBeginDate && document.endDate > worksiteEndDate ||
            document.endDate === undefined
          ) {
            setPartnersDocuments(partnersDocuments => [...partnersDocuments, document])
          }
        })
      })
    );
  }, [worksiteBeginDate, worksiteEndDate]);

  return worksite !== undefined ? (
    <FilesList
      clientID={worksite.client}
      worksiteID={worksite.id}
      worksiteName={worksite.name}
      partners={worksite.partners}
      insurances={partnersDocuments}
      reports={worksite.lots
        .flatMap((lot) => lot.reports)
        .concat(generalReports)
        .flatMap((report) => report.attachedFile)
      }
      files={worksite.filesCategories}
      filesSetter={(files) => {
        setWorksite({
          ...worksite,
          filesCategories: files,
        });
        return;
      }}
      onNew={(file, category) => {
        file.alerts = file.alerts.map((alert) => {
          if (alert.recallDate === "") {
            alert.recallDate = undefined;
          }
          return alert;
        });
        return checkTokenAndFetch(`/api/files/post_new`, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            ...file,
            filesCategory: category,
          }),
        }).then((resp) => {
          toast.success("Le fichier a bien été créé");
          return resp;
        });
      }}
      onEdit={(file) => {
        file.alerts = file.alerts.map((alert) => {
          if (alert.recallDate === "") {
            alert.recallDate = undefined;
          }
          return alert;
        });
        return checkTokenAndFetch(`/api/files/${file.id}/patch`, {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
          method: "PATCH",
          body: JSON.stringify(file),
        }).then((resp) => {
          toast.success("Le fichier a bien été mis à jour");
          return resp;
        });
      }}
      onDelete={(id) =>
        checkTokenAndFetch(`/api/files/${id}`, { method: "DELETE" }).then(
          (resp) => {
            toast.success("Le fichier a bien été supprimé");
            return resp;
          }
        )
      }
    />
  ) : (
    <Loading />
  );
}
