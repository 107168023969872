import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../components/BaseButton";
import BaseInput from "../components/BaseInput";
import BaseRadio from "../components/BaseRadio";
import BaseSelect from "../components/BaseSelect";
import Card from "../components/Card";
import DoubleColumn from "../components/DoubleColumn";
import Page from "../components/Page";
import Section from "../components/Section";
import BaseLabel from "../components/BaseLabel";
import BaseForm from "../components/BaseForm";
import { parsePhoneNumber } from "libphonenumber-js";
import { useCheckTokenAndFetch } from "../auth";
import { toast } from "react-toastify";

interface Client {
  isProfessional: boolean;
  companyName: string;
  companyStatus: string;
  sex: number;
  firstname: string;
  lastname: string;
  email: string;
  address: string;
  zipCode: string;
  city: string;
  cellPhone: string;
  homePhone?: string;
  password: string;
  repeatPassword: string;
  related?: Omit<
    Client,
    "related" | "isProfessional" | "companyName" | "companyStatus"
  >;
}

export default function ClientsEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [sendLoading, setSendLoading] = React.useState<boolean>(false);
  const [client, setClient] = React.useState<Client>({
    isProfessional: false,
    companyName: "",
    companyStatus: "",
    sex: 0,
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    zipCode: "",
    city: "",
    cellPhone: "",
    homePhone: "",
    password: "",
    repeatPassword: "",
  });
  const buttons = (
    <div className="flex flex-row gap-3">
      <BaseButton
        type="button"
        styleType="cancel"
        content="Annuler"
        onClick={() => {
          navigate(-1);
        }}
      />
      <BaseButton
        type="submit"
        styleType="primary"
        content="Enregistrer les modifications"
        icon={
          isLoading
            ? {
                icon: faSpinner,
                className: "animate-spin",
              }
            : undefined
        }
      />
    </div>
  );

  React.useEffect(() => {
    checkTokenAndFetch(`/api/clients/${params.id}/get_form_infos`, {
      method: "GET",
    }).then((client: Client) => {
      if (client.cellPhone) {
        try {
          const phone = parsePhoneNumber(client.cellPhone)
            .formatNational()
            .replace(/\s/g, "");
          client.cellPhone = phone;
        } catch (error) {
          client.cellPhone = "";
        }
      }
      if (client.homePhone) {
        try {
          const phone = parsePhoneNumber(client.homePhone)
            .formatNational()
            .replace(/\s/g, "");
          client.homePhone = phone;
        } catch (error) {
          client.homePhone = "";
        }
      }
      if (client.related) {
        if (client.related.cellPhone) {
          try {
            const phone = parsePhoneNumber(client.related.cellPhone)
              .formatNational()
              .replace(/\s/g, "");
            client.related.cellPhone = phone;
          } catch (error) {
            client.related.cellPhone = "";
          }
        }
        if (client.related.homePhone) {
          try {
            const phone = parsePhoneNumber(client.related.homePhone)
              .formatNational()
              .replace(/\s/g, "");
            client.related.homePhone = phone;
          } catch (error) {
            client.related.homePhone = "";
          }
        }
      }
      setClient((c) => ({
        ...c,
        ...client,
      }));
    });
  }, [navigate, params.id]);

  function body(): Client {
    const ret: Client = JSON.parse(JSON.stringify(client));
    if (ret.cellPhone) {
      try {
        const phone = parsePhoneNumber(ret.cellPhone, "FR");
        ret.cellPhone = phone.number;
      } catch (error) {
        ret.cellPhone = "";
      }
    }
    if (ret.homePhone) {
      try {
        const phone = parsePhoneNumber(ret.homePhone, "FR");
        ret.homePhone = phone.number;
      } catch (error) {
        ret.homePhone = "";
      }
    }
    if (ret.related) {
      if (ret.related.cellPhone) {
        try {
          const phone = parsePhoneNumber(ret.related.cellPhone, "FR");
          ret.related.cellPhone = phone.number;
        } catch (error) {
          ret.related.cellPhone = "";
        }
      }
      if (ret.related.homePhone) {
        try {
          const phone = parsePhoneNumber(ret.related.homePhone, "FR");
          ret.related.homePhone = phone.number;
        } catch (error) {
          ret.related.homePhone = "";
        }
      }
    }
    return ret;
  }

  function handleSendConnexion(event: React.SyntheticEvent) {
    event.preventDefault();
    setSendLoading(true);
    checkTokenAndFetch(
      `/api/clients/${params.id}/send-connexion`
    )
      .then(() => {
        toast.success(
          "Un email de connexion vient d'être envoyé au client"
        );
        setSendLoading(false);
      })
      .catch((err) => {
        toast.error("Une erreur est survenue");
        setSendLoading(false);
      });
  }

  return (
    <Page
      icon={{ icon: faUser }}
      title={`Modifier le client (${client.firstname} ${client.lastname})`}
    >
      <BaseForm
        isNew={false}
        url={`/api/clients/${params.id}/patch_edit`}
        body={body()}
        loadingSetter={setIsLoading}
        successMessage="Le client a bien été mis à jour"
      >
        <div className="mb-14 flex justify-between">
          {buttons}
          <BaseButton
            type="button"
            styleType="csv"
            content="Envoyer infos connexion"
            icon={
              sendLoading
                ? {
                    icon: faSpinner,
                    className: "animate-spin",
                  }
                : undefined
            }
            onClick={(e) => handleSendConnexion(e)}
          />
        </div>
        <DoubleColumn
          left={
            <>
              <Section title="Contact principal">
                <Card className="max-h-max">
                <BaseLabel required label="Nature du client">
                <div className="flex flex-row gap-6">
                  <BaseRadio
                    className="shrink"
                    label="Particulier"
                    value={false}
                    currentValue={client?.isProfessional}
                    onChange={(event) => {
                      setClient({
                        ...client,
                        isProfessional: !!event.currentTarget.value,
                      });
                    }}
                  />
                  <BaseRadio
                    className="shrink"
                    label="Professionnel"
                    value={true}
                    currentValue={client.isProfessional}
                    onChange={(event) => {
                      setClient({
                        ...client,
                        isProfessional: !!event.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </BaseLabel>
              {client.isProfessional ? (
                <div className="flex flex-row gap-4">
                  <BaseLabel required label="Raison sociale">
                    <BaseInput
                      required
                      type="text"
                      value={client.companyName}
                      onChange={(event) => {
                        setClient({
                          ...client,
                          companyName: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel required label="Forme juridique">
                    <BaseInput
                      required
                      type="text"
                      value={client.companyStatus}
                      onChange={(event) => {
                        setClient({
                          ...client,
                          companyStatus: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                </div>
              ) : null}
              <div className="flex flex-row gap-4">
                <BaseLabel required label="Civilité" className="shrink w-min">
                  <BaseSelect
                    required
                    value={client.sex}
                    onChange={(event) => {
                      setClient({
                        ...client,
                        sex: +event.currentTarget.value,
                      });
                    }}
                    options={[
                      { label: "M.", value: 1 },
                      { label: "Mme.", value: 2 },
                    ]}
                  />
                </BaseLabel>
                <BaseLabel required label="Nom">
                  <BaseInput
                    required
                    type="text"
                    value={client.lastname}
                    onChange={(event) => {
                      setClient({
                        ...client,
                        lastname: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
                <BaseLabel required label="Prénom">
                  <BaseInput
                    required
                    type="text"
                    value={client.firstname}
                    onChange={(event) => {
                      setClient({
                        ...client,
                        firstname: event.currentTarget.value,
                      });
                    }}
                  />
                </BaseLabel>
              </div>
                  <BaseLabel label="Adresse">
                    <BaseInput
                      type="text"
                      value={client?.address}
                      onChange={(event) => {
                        setClient({
                          ...client,
                          address: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                  <div className="flex flex-row gap-4">
                    <BaseLabel label="Code postal">
                      <BaseInput
                        type="text"
                        pattern="[0-9]{5}"
                        value={client?.zipCode}
                        onChange={(event) => {
                          setClient({
                            ...client,
                            zipCode: event.currentTarget.value,
                          });
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel label="Ville">
                      <BaseInput
                        type="text"
                        value={client?.city}
                        onChange={(event) => {
                          setClient({
                            ...client,
                            city: event.currentTarget.value,
                          });
                        }}
                      />
                    </BaseLabel>
                  </div>
                  <div className="flex flex-row gap-4">
                    <BaseLabel label="Téléphone Port.">
                      <BaseInput
                        type="text"
                        value={client?.cellPhone}
                        pattern="[0-9]{10}"
                        onChange={(event) => {
                          setClient({
                            ...client,
                            cellPhone: event.currentTarget.value,
                          });
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel label="Téléphone Fixe">
                      <BaseInput
                        type="text"
                        value={client?.homePhone}
                        pattern="[0-9]{10}"
                        onChange={(event) => {
                          setClient({
                            ...client,
                            homePhone: event.currentTarget.value,
                          });
                        }}
                      />
                    </BaseLabel>
                  </div>
                </Card>
              </Section>
              <Section title="Identifiant du contact principal">
                <Card className="max-h-max">
                  <BaseLabel label="Adresse email">
                    <BaseInput
                      type="email"
                      value={client?.email}
                      onChange={(event) => {
                        setClient({
                          ...client,
                          email: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel label="Mot de passe">
                    <BaseInput
                      autoComplete="new-password"
                      type="password"
                      value={client?.password}
                      onChange={(event) => {
                        setClient({
                          ...client,
                          password: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                  <BaseLabel label="Confirmation du mot de passe">
                    <BaseInput
                      autoComplete="new-password"
                      repeat={client?.password}
                      type="password"
                      value={client?.repeatPassword}
                      onChange={(event) => {
                        setClient({
                          ...client,
                          repeatPassword: event.currentTarget.value,
                        });
                      }}
                    />
                  </BaseLabel>
                </Card>
              </Section>
            </>
          }
          right={
            <>
              <Card>
                <BaseLabel required label="Contact secondaire ?">
                  <div className="flex flex-row gap-6">
                    <BaseRadio
                      className="shrink"
                      label="Oui"
                      value={true}
                      currentValue={!!client.related}
                      onChange={() => {
                        setClient({
                          ...client,
                          related: {
                            sex: 0,
                            lastname: "",
                            firstname: "",
                            address: "",
                            zipCode: "",
                            city: "",
                            cellPhone: "",
                            homePhone: "",
                            email: "",
                            password: "",
                            repeatPassword: "",
                          },
                        });
                      }}
                    />
                    <BaseRadio
                      className="shrink"
                      label="Non"
                      value={false}
                      currentValue={!!client.related}
                      onChange={() => {
                        setClient({
                          ...client,
                          related: undefined,
                        });
                      }}
                    />
                  </div>
                </BaseLabel>
              </Card>
              {client.related && (
                <>
                  <Section title="Contact secondaire">
                    <Card className="max-h-max">
                      <div className="flex flex-row gap-4">
                        <BaseLabel
                          required
                          label="Civilité"
                          className="shrink w-min"
                        >
                          <BaseSelect
                            required
                            value={client.related.sex}
                            onChange={(event) => {
                              if (client.related) {
                                setClient({
                                  ...client,
                                  related: {
                                    ...client.related,
                                    sex: +event.currentTarget.value,
                                  },
                                });
                              }
                            }}
                            options={[
                              { label: "M.", value: 1 },
                              { label: "Mme.", value: 2 },
                            ]}
                          />
                        </BaseLabel>
                        <BaseLabel required label="Nom">
                          <BaseInput
                            required
                            type="text"
                            value={client.related.lastname}
                            onChange={(event) => {
                              if (client.related) {
                                setClient({
                                  ...client,
                                  related: {
                                    ...client.related,
                                    lastname: event.currentTarget.value,
                                  },
                                });
                              }
                            }}
                          />
                        </BaseLabel>
                        <BaseLabel required label="Prénom">
                          <BaseInput
                            required
                            type="text"
                            value={client.related.firstname}
                            onChange={(event) => {
                              if (client.related) {
                                setClient({
                                  ...client,
                                  related: {
                                    ...client.related,
                                    firstname: event.currentTarget.value,
                                  },
                                });
                              }
                            }}
                          />
                        </BaseLabel>
                      </div>
                      <BaseLabel label="Adresse">
                        <BaseInput
                          type="text"
                          value={client.related.address}
                          onChange={(event) => {
                            if (client.related) {
                              setClient({
                                ...client,
                                related: {
                                  ...client.related,
                                  address: event.currentTarget.value,
                                },
                              });
                            }
                          }}
                        />
                      </BaseLabel>
                      <div className="flex flex-row gap-4">
                        <BaseLabel label="Code postal">
                          <BaseInput
                            type="text"
                            pattern="[0-9]{5}"
                            value={client.related.zipCode}
                            onChange={(event) => {
                              if (client.related) {
                                setClient({
                                  ...client,
                                  related: {
                                    ...client.related,
                                    zipCode: event.currentTarget.value,
                                  },
                                });
                              }
                            }}
                          />
                        </BaseLabel>
                        <BaseLabel label="Ville">
                          <BaseInput
                            type="text"
                            value={client.related.city}
                            onChange={(event) => {
                              if (client.related) {
                                setClient({
                                  ...client,
                                  related: {
                                    ...client.related,
                                    city: event.currentTarget.value,
                                  },
                                });
                              }
                            }}
                          />
                        </BaseLabel>
                      </div>
                      <div className="flex flex-row gap-4">
                        <BaseLabel label="Téléphone Port.">
                          <BaseInput
                            required
                            type="text"
                            value={client.related.cellPhone}
                            pattern="[0-9]{10}"
                            onChange={(event) => {
                              if (client.related) {
                                setClient({
                                  ...client,
                                  related: {
                                    ...client.related,
                                    cellPhone: event.currentTarget.value,
                                  },
                                });
                              }
                            }}
                          />
                        </BaseLabel>
                        <BaseLabel label="Téléphone Fixe">
                          <BaseInput
                            type="text"
                            value={client.related.homePhone}
                            pattern="[0-9]{10}"
                            onChange={(event) => {
                              if (client.related) {
                                setClient({
                                  ...client,
                                  related: {
                                    ...client.related,
                                    homePhone: event.currentTarget.value,
                                  },
                                });
                              }
                            }}
                          />
                        </BaseLabel>
                      </div>
                    </Card>
                  </Section>
                  <Section title="Identifiant du contact secondaire">
                    <Card className="max-h-max">
                      <BaseLabel label="Adresse email">
                        <BaseInput
                          type="email"
                          value={client.related.email}
                          onChange={(event) => {
                            if (client.related) {
                              setClient({
                                ...client,
                                related: {
                                  ...client.related,
                                  email: event.currentTarget.value,
                                },
                              });
                            }
                          }}
                        />
                      </BaseLabel>
                      <BaseLabel label="Mot de passe">
                        <BaseInput
                          autoComplete="new-password"
                          type="password"
                          value={client.related.password}
                          onChange={(event) => {
                            if (client.related) {
                              setClient({
                                ...client,
                                related: {
                                  ...client.related,
                                  password: event.currentTarget.value,
                                },
                              });
                            }
                          }}
                        />
                      </BaseLabel>
                      <BaseLabel label="Confirmation du mot de passe">
                        <BaseInput
                          autoComplete="new-password"
                          repeat={client.related.password}
                          type="password"
                          value={client.related.repeatPassword}
                          onChange={(event) => {
                            if (client.related) {
                              setClient({
                                ...client,
                                related: {
                                  ...client.related,
                                  repeatPassword: event.currentTarget.value,
                                },
                              });
                            }
                          }}
                        />
                      </BaseLabel>
                    </Card>
                  </Section>
                </>
              )}
            </>
          }
        />
        <div className="mx-auto mt-14">{buttons}</div>
      </BaseForm>
    </Page>
  );
}
