import React from "react";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logo354 from "../assets/logo354.png";
import BaseButton from "./BaseButton";

type LoginFormProps = React.HTMLAttributes<HTMLDivElement> & {
  handleSubmit: React.FormEventHandler;
  title: string;
  subTitle: string;
  alert?: string;
  submit?: {
    content: string;
    icon?: FontAwesomeIconProps;
  };
  link?: {
    to: string;
    content: string;
  };
  children?: React.ReactNode;
};

export default function LoginForm(props: LoginFormProps) {
  return (
    <div className="bg-grey-7 min-h-screen px-4 flex flex-col gap-20 items-center justify-center">
      <img src={logo354} alt="logo" />
      <form
        onSubmit={props.handleSubmit}
        className="flex flex-col max-w-96 md:w-96"
      >
        <h1 className="font-extrabold text-3xl">{props.title}</h1>
        <h2 className="text-grey-2 font-medium">{props.subTitle}</h2>
        {props.alert && (
          <div
            className="p-4 mb-4 text-sm text-red-1 bg-red-2 bg-opacity-20 rounded-lg"
            role="alert"
          >
            {props.alert}
          </div>
        )}
        {props.children && props.submit && (
          <>
            <div className="flex flex-col bg-white p-5 rounded-md my-7">
              {props.children}
            </div>
            <BaseButton
              styleType="primary"
              type="submit"
              content={props.submit.content}
              icon={props.submit.icon}
            />
          </>
        )}
        {props.link && (
          <Link
            className="text-center font-extrabold text-green-1"
            to={props.link.to}
          >
            {props.link.content}
          </Link>
        )}
      </form>
    </div>
  );
}
