import { twMerge } from "tailwind-merge";
import React from "react";
import { useNavigate } from "react-router-dom";

type CardContentItemProps = {
  informations: React.ReactNode;
  title: React.ReactNode;
  badge?: React.ReactNode;
  children?: React.ReactNode;
  link?: string;
};

export default function CardContentItem(props: CardContentItemProps) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row gap-1.5">
      <div
        className={twMerge(
          "flex flex-col w-full",
          props.link && "cursor-pointer"
        )}
        onClick={() => {
          if (props.link) {
            navigate(props.link);
          }
        }}
      >
        <span className="text-green-1 font-bold">{props.informations}</span>
        <span className="text-grey-1 font-bold">{props.title}</span>
        <div className="mt-2">{props.children}</div>
      </div>
      <div className="grow"></div>
      <div className="shrink-0">{props.badge}</div>
    </div>
  );
}
