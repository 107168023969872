import React from "react";
import { useNavigate } from "react-router-dom";
import { faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../components/BaseButton";
import Page from "../components/Page";
import BaseForm from "../components/BaseForm";
import DoubleColumn from "../components/DoubleColumn";
import Card from "../components/Card";
import BaseLabel from "../components/BaseLabel";
import BaseInput from "../components/BaseInput";

type UserInput = {
  email: string;
  password: string;
  repeatPassword: string;
  firstname: string;
  lastname: string;
};

export default function ConfigurationAdminsNew() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [input, setInput] = React.useState<UserInput>({
    email: "",
    password: "",
    repeatPassword: "",
    firstname: "",
    lastname: "",
  });
  const buttons = (
    <div className="flex flex-row gap-3">
      <BaseButton
        type="button"
        styleType="cancel"
        content="Annuler"
        onClick={() => {
          navigate(-1);
        }}
      />
      <BaseButton
        type="submit"
        styleType="primary"
        content="Créer l'administrateur"
        icon={
          isLoading
            ? {
                icon: faSpinner,
                className: "animate-spin",
              }
            : undefined
        }
      />
    </div>
  );

  function handleChange(event: React.BaseSyntheticEvent) {
    const param: keyof UserInput = event.currentTarget.name;

    setInput({
      ...input,
      [param]: event.currentTarget.value,
    });
  }

  return (
    <Page icon={{ icon: faUser }} title="Nouvel administrateur">
      <BaseForm
        isNew={true}
        url={`/api/admins/post_new`}
        body={input}
        loadingSetter={setIsLoading}
        successMessage="L'administrateur a bien été créé"
      >
        <div className="flex mb-14">{buttons}</div>

        <DoubleColumn
          left={
            <>
              <Card className="max-h-max">
                <div className="flex flex-row gap-4">
                  <BaseLabel required label="Nom">
                    <BaseInput
                      required
                      type="text"
                      name="lastname"
                      value={input.lastname}
                      onChange={handleChange}
                    />
                  </BaseLabel>
                  <BaseLabel required label="Prénom">
                    <BaseInput
                      required
                      type="text"
                      name="firstname"
                      value={input.firstname}
                      onChange={handleChange}
                    />
                  </BaseLabel>
                </div>
                <BaseLabel required label="Adresse email">
                  <BaseInput
                    required
                    type="email"
                    name="email"
                    value={input.email}
                    onChange={handleChange}
                  />
                </BaseLabel>
                <BaseLabel required label="Mot de passe">
                  <BaseInput
                    required
                    autoComplete="new-password"
                    type="password"
                    name="password"
                    value={input.password}
                    onChange={handleChange}
                  />
                </BaseLabel>
                <BaseLabel required label="Confirmation du mot de passe">
                  <BaseInput
                    required
                    autoComplete="new-password"
                    repeat={input.password}
                    type="password"
                    name="repeatPassword"
                    value={input.repeatPassword}
                    onChange={handleChange}
                  />
                </BaseLabel>
              </Card>
            </>
          }
        />
        <div className="mx-auto mt-14">{buttons}</div>
      </BaseForm>
    </Page>
  );
}
