import React from "react";
import { twMerge } from "tailwind-merge";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

type PageProps = {
  icon: FontAwesomeIconProps;
  title: React.ReactNode;
  children?: React.ReactNode;
};

export default function Page(props: PageProps) {
  return (
    <div className="mx-4">
      <div className="w-full">
        <h1 className="font-extrabold text-3xl mb-10">
          <FontAwesomeIcon
            icon={props.icon.icon}
            className={twMerge("mr-6 text-green-1", props.icon.className)}
          />
          {props.title}
        </h1>
        <div className="min-w-[992px]">{props.children}</div>
      </div>
    </div>
  );
}
