import React from "react";
import Card from "./Card";
import CardContent from "./CardContent";
import Section from "./Section";
import Tab from "./Tab";

type TabInfos = {
  title: string;
  value?: number;
};

type SectionWithBooleanTabsProps<T> = {
  title: React.ReactNode;
  array: Array<T>;
  booleanCategory: boolean;
  setBooleanCategory: React.Dispatch<React.SetStateAction<boolean>>;
  initialCategory: (value: T, index?: number, array?: T[]) => boolean;
  trueCategory: TabInfos;
  falseCategory: TabInfos;
  content: (item: T) => React.ReactNode;
};

export default function SectionWithBooleanTabs<T>(
  props: SectionWithBooleanTabsProps<T>
) {
  return (
    <Section title={props.title}>
      <div className="flex flex-row gap-2.5">
        <Tab
          title={props.trueCategory.title}
          value={props.trueCategory.value}
          isActive={props.booleanCategory}
          onClick={() => {
            props.setBooleanCategory(true);
          }}
        />
        <Tab
          title={props.falseCategory.title}
          value={props.falseCategory.value}
          isActive={!props.booleanCategory}
          onClick={() => {
            props.setBooleanCategory(false);
          }}
        />
      </div>
      <Card className="max-h-96">
        {props.array.filter((memo) =>
          props.booleanCategory
            ? props.initialCategory(memo)
            : !props.initialCategory(memo)
        ).length ? (
          props.array
            .filter((memo) =>
              props.booleanCategory
                ? props.initialCategory(memo)
                : !props.initialCategory(memo)
            )
            .map((item, iIndex) => (
              <CardContent key={iIndex}>
                {[<div key={0}>{props.content(item)}</div>]}
              </CardContent>
            ))
        ) : (
          <>Aucune entrée</>
        )}
      </Card>
    </Section>
  );
}
