import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BaseInput from "./BaseInput";
import BaseLabel from "./BaseLabel";

type BaseInputFileProps = {
  required?: boolean;
  label?: string;
} & (
  | {
      multiple: true;
      value?: Array<FileWithContent> | null;
      onChange: (files: Array<FileWithContent>) => void;
    }
  | {
      multiple?: false;
      value?: FileWithContent | null;
      onChange: (file: FileWithContent | null) => void;
    }
);

type FileWithContent = {
  name?: string;
  content: string;
};

export default function BaseInputFile(props: BaseInputFileProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const myRef = React.useRef<HTMLButtonElement | null>(null);
  const [files, setFiles] = React.useState<Array<FileWithContent>>(
    props.value ? (props.multiple ? props.value : [props.value]) : []
  );

  React.useEffect(() => {
    const node = myRef.current;
    node?.setCustomValidity(
      props.required && files.length === 0
        ? "Veuillez renseigner ce champs"
        : ""
    );
  }, [props.required, files]);
  React.useEffect(() => {
    if (props.multiple) {
      props.onChange(files);
    } else {
      props.onChange(files.length ? files[0] : null);
    }
  }, [files]);
  function uploadFiles(files: FileList) {
    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (props.multiple) {
          setFiles((fs) => [
            ...fs,
            {
              name: file.name,
              content: reader.result as string,
            },
          ]);
        } else {
          setFiles([
            {
              name: file.name,
              content: reader.result as string,
            },
          ]);
        }
      };
      reader.readAsDataURL(file);
    });
  }

  return (
    <BaseLabel
      required={props.required}
      label={props.label ? props.label : props.multiple ? "Joindre des fichiers" : "Joindre un fichier"}
    >
      <div
        className="flex flex-wrap items-center gap-2 p-px border-2 border-transparent border-dashed"
        onDragStart={(e) => {
          e.preventDefault();
          e.currentTarget.classList.remove("border-transparent");
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.currentTarget.classList.remove("border-transparent");
          e.currentTarget.classList.add("opacity-70");
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.currentTarget.classList.remove("opacity-70");
          e.currentTarget.classList.add("border-transparent");
        }}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.files) {
            uploadFiles(e.dataTransfer.files);
          }
          e.currentTarget.classList.remove("opacity-70");
          e.currentTarget.classList.add("border-transparent");
        }}
      >
        <div className="flex place-items-center justify-center rounded-full bg-green-3 bg-opacity-20 w-8 h-8 cursor-pointer">
          <button
            ref={myRef}
            onClick={(e) => {
              e.preventDefault();
              inputRef.current?.click();
            }}
          >
            <FontAwesomeIcon icon={faPaperclip} className="text-green-1" />
          </button>
        </div>
        {files.map((file, fIndex) => (
          <span key={fIndex} className="bg-grey-5 rounded p-1 font-normal">
            <a
              href={file.content}
              className="underline hover:text-grey-1"
              download={file.name}
            >
              {file.name}
            </a>
            <FontAwesomeIcon
              icon={faTimes}
              className="cursor-pointer ml-2 hover:text-grey-1"
              onClick={(event) => {
                event.preventDefault();
                setFiles(files.filter((_, index) => index !== fIndex));
              }}
            />
          </span>
        ))}
      </div>
      <BaseInput
        multiple={props.multiple}
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={(e) => {
          const inputFile = e.currentTarget;
          if (inputFile.files) {
            uploadFiles(inputFile.files);
            inputFile.value = "";
          }
        }}
      />
    </BaseLabel>
  );
}
