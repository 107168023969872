import React from "react";
import { twMerge } from "tailwind-merge";

type BaseInputProps = {
  pattern?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  repeat?: number | string;
  type: React.HTMLInputTypeAttribute;
  step?: string;
  name?: string;
  value?: number | string | null;
  checked?: boolean;
  autoComplete?: string;
  className?: string;
  onChange?: React.FormEventHandler<HTMLInputElement>;
  onFocus?: React.FormEventHandler<HTMLInputElement>;
  onBlur?: React.FormEventHandler<HTMLInputElement>;
};

const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(
  function BaseInput(props, ref) {
    const myRef = React.useRef<HTMLInputElement | null>(null);

    React.useEffect(() => {
      const node = myRef.current;
      node?.setCustomValidity(
        props.repeat && props.repeat !== props.value
          ? "Veuillez confirmer le mot de passe."
          : ""
      );
    }, [ref, props]);

    return (
      <input
        pattern={props.pattern}
        multiple={props.multiple}
        autoComplete={props.autoComplete}
        ref={(node) => {
          myRef.current = node;
          if (typeof ref === "function") {
            ref(node);
          } else if (ref && node) {
            (ref as React.MutableRefObject<HTMLInputElement>).current = node;
          }
        }}
        name={props.name}
        required={props.required}
        disabled={props.disabled}
        placeholder={props.placeholder ? props.placeholder : props.required ? "" : "facultatif"}
        type={props.type}
        step={props.step}
        className={twMerge(
          "border placeholder:italic border-grey-4 text-darkgrey rounded px-3 py-0.5 disabled:bg-grey-4",
          props.type !== "radio" && props.type !== "checkbox" && "w-full",
          props.repeat !== undefined &&
            props.repeat !== props.value &&
            "border-red-1 focus:outline-red-1",
          props.className
        )}
        value={
          props.value !== undefined
            ? props.value != null
              ? ((props.value === 0 || props.value === "0") && props.type === "number") ? undefined :
              props.value
              : ""
            : undefined
        }
        checked={props.checked}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
    );
  }
);
export default BaseInput;
