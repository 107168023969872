import React from "react";
import { useParams } from "react-router-dom";
import DoubleColumn from "../components/DoubleColumn";
import Loading from "../components/Loading";
import WorksiteBaseInfos from "../components/WorksiteBaseInfos";
import WorksiteLocation, { Position } from "../components/WorksiteLocation";
import WorksitePartners from "../components/WorksitePartners";
import { useCheckTokenAndFetch } from "../auth";
import { Client, Partner, PartnerAPI } from "./worksite";
import { useWorksites } from "./layout";
import { toast } from "react-toastify";

type WorksiteBaseInfosType = {
  name: string;
  cadastralSection: string;
  address: string;
  zipCode: string;
  city: string;
  locationLatitude: number;
  locationLongitude: number;
  client: Client;
  partners: Array<string>;
};

export default function WorksiteInfos() {
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [worksite, setWorksite] = React.useState<WorksiteBaseInfosType>();
  const [worksites, setWorksites] = useWorksites();
  const [partners, setPartners] = React.useState<Array<PartnerAPI>>();

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/${params.id}/get_base_infos`, {
      method: "GET",
    }).then((worksite) => {
      setWorksite(worksite);
    });
    checkTokenAndFetch(`/api/partners/get_listing`, { method: "GET" }).then(
      (partners: { "hydra:member": Array<Partner> }) => {
        setPartners(
          partners["hydra:member"].map((partner) => ({
            label: `${partner.companyName} - ${partner.firstname} ${partner.lastname}`,
            value: partner["@id"],
          }))
        );
      }
    );
  }, [params.id]);

  return worksite !== undefined ? (
    <DoubleColumn
      left={
        <>
          <WorksiteBaseInfos
            worksite={worksite}
            client={worksite.client}
            onSubmit={(infos) =>
              checkTokenAndFetch(
                `/api/worksites/${params.id}/patch_base_infos`,
                {
                  headers: {
                    "Content-Type": "application/merge-patch+json",
                  },
                  method: "PATCH",
                  body: JSON.stringify(infos),
                }
              ).then(() => {
                setWorksite({
                  ...worksite,
                  ...infos,
                });
                setWorksites(
                  worksites.map((w) => {
                    if (`${w.id}` === params.id) {
                      return {
                        ...w,
                        ...infos,
                      };
                    }
                    return w;
                  })
                );
                toast.success(
                  "Les informations du chantier ont bien été mises à jour"
                );
              })
            }
          />
          {partners !== undefined ? (
            <WorksitePartners
              partners={worksite.partners}
              partnersListing={partners}
              onDelete={async (pID: string) => {
                const newPartners = worksite.partners.filter(
                  (item) => item !== pID
                );
                await checkTokenAndFetch(
                  `/api/worksites/${params.id}/patch_partners`,
                  {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                    method: "PATCH",
                    body: JSON.stringify({
                      partners: newPartners,
                    }),
                  }
                );
                setWorksite({
                  ...worksite,
                  partners: newPartners,
                });
                toast.success("Le partenaire a bien été retiré du chantier");
              }}
              onAdd={async (pIDs: Array<string>) => {
                const newPartners = [...worksite.partners, ...pIDs];
                await checkTokenAndFetch(
                  `/api/worksites/${params.id}/patch_partners`,
                  {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                    method: "PATCH",
                    body: JSON.stringify({
                      partners: newPartners,
                    }),
                  }
                );
                setWorksite({
                  ...worksite,
                  partners: newPartners,
                });
                toast.success("Le partenaire a bien été ajouté du chantier");
              }}
            />
          ) : (
            <Loading />
          )}
        </>
      }
      right={
        <WorksiteLocation
          position={{
            lat: worksite.locationLatitude,
            lng: worksite.locationLongitude,
          }}
          setPosition={(pos: Position) =>
            checkTokenAndFetch(`/api/worksites/${params.id}/patch_location`, {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
              method: "PATCH",
              body: JSON.stringify({
                locationLatitude: pos.lat,
                locationLongitude: pos.lng,
              }),
            }).then(() => {
              setWorksite({
                ...worksite,
                locationLatitude: pos.lat,
                locationLongitude: pos.lng,
              });
              toast.success(
                "La localisation du chantier a bien été mise à jour"
              );
            })
          }
        />
      }
    />
  ) : (
    <Loading />
  );
}
