import React from "react";
import { useAuth } from "../auth";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Page from "../components/Page";
import LotsPhasesList from "../components/LotsPhasesList";
import { useCheckTokenAndFetch } from "../auth";
import Loading from "../components/Loading";
import { Lot } from "../components/LotsPhasesListLot";
import { Phase } from "../components/LotsPhasesListPhase";

export default function ConfigurationLotsPhases() {
  const auth = useAuth();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [defaultLots, setDefaultLots] = React.useState<Array<Lot>>([]);
  const [defaultPhases, setDefaultPhases] = React.useState<Array<Phase>>([]);

  React.useEffect(() => {
    checkTokenAndFetch(`/api/default_lots/get_listing`, { method: "GET" }).then(
      (lots: { "hydra:member": Array<Lot> }) => {
        setDefaultLots(lots["hydra:member"]);
      }
    );

    retrievesPhases();
  }, [auth]);

  function retrievesPhases() {
    return checkTokenAndFetch(`/api/default_phases/get_listing`, {
      method: "GET",
    }).then((phases: { "hydra:member": Array<Phase> }) => {
      setDefaultPhases(phases["hydra:member"]);
    });
  }

  return (
    <Page icon={{ icon: faCog }} title="Configuration > Lots / Phases">
      {defaultLots && defaultPhases ? (
        <LotsPhasesList
          lots={defaultLots}
          worksite={undefined}
          handleNewLot={(newLot) =>
            checkTokenAndFetch(`/api/default_lots/post_new`, {
              headers: {
                "Content-Type": "application/ld+json",
              },
              method: "POST",
              body: JSON.stringify(newLot),
            }).then((lot: Lot) => {
              setDefaultLots((oldLots) => [...oldLots, lot]);
            })
          }
          handleEditLot={(lot) =>
            checkTokenAndFetch(`/api/default_lots/${lot.id}/patch_edit`, {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
              method: "PATCH",
              body: JSON.stringify(lot),
            }).then((lot: Lot) => {
              setDefaultLots((oldLots) => [
                ...oldLots.map((l) => {
                  if (l.id === lot.id) {
                    return lot;
                  }
                  return l;
                }),
              ]);
            })
          }
          handleDeleteLot={(id) =>
            checkTokenAndFetch(`/api/default_lots/${id}`, {
              method: "DELETE",
            }).then(() => {
              setDefaultLots((oldLots) =>
                oldLots.filter((defaultLot) => defaultLot.id !== id)
              );
              retrievesPhases();
            })
          }
          phases={defaultPhases}
          handleNewPhase={(newPhase) =>
            checkTokenAndFetch(`/api/default_phases/post_new`, {
              headers: {
                "Content-Type": "application/ld+json",
              },
              method: "POST",
              body: JSON.stringify(newPhase),
            }).then((phase: Phase) => {
              setDefaultPhases((oldPhases) => [...oldPhases, phase]);
            })
          }
          handleEditPhase={(phase) =>
            checkTokenAndFetch(`/api/default_phases/${phase.id}/patch_edit`, {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
              method: "PATCH",
              body: JSON.stringify(phase),
            }).then((phase: Phase) => {
              setDefaultPhases((oldPhases) => [
                ...oldPhases.map((p) => {
                  if (p.id === phase.id) {
                    return phase;
                  }
                  return p;
                }),
              ]);
            })
          }
          handleDeletePhase={(id) =>
            checkTokenAndFetch(`/api/default_phases/${id}`, {
              method: "DELETE",
            }).then(() => {
              setDefaultPhases((oldPhases) =>
                oldPhases.filter((defaultPhase) => defaultPhase.id !== id)
              );
            })
          }
        />
      ) : (
        <Loading />
      )}
    </Page>
  );
}
