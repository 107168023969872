import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useCheckTokenAndFetch } from "../auth";

type File = {
  id: number;
  createdAt: string;
  name: string;
  content: string;
};

type ImageAPIProps = {
  image: string;
  classname?: string;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
};

export default function ImageAPI(props: ImageAPIProps) {
  const [file, setFile] = React.useState<File>();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  React.useEffect(() => {
    checkTokenAndFetch(props.image, {
      method: "GET",
    }).then((file: File) => {
      setFile(file);
    });
  }, [props.image]);

  return (
    <div>
      {file === undefined ? (
        <div>
          <FontAwesomeIcon icon={faSpinner} className="animate-spin text-2xl" />
        </div>
      ) : (
        <img
          src={file.content}
          alt={file.name}
          className={props.classname}
          onClick={props.onClick}
        />
      )}
    </div>
  );
}
