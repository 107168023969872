import React from "react";
import { useNavigate } from "react-router-dom";
import { useCheckTokenAndFetch } from "../auth";
import Card from "./Card";
import Loading from "./Loading";
import Section from "./Section";
import TheMemoListItem from "./TheMemoListItem";

type WorksitesList = Array<{
  id: number;
  state: number;
  name: string;
  description: string;
}>;

export default function TheMemoList() {
  const [worksites, setWorksites] = React.useState<WorksitesList>();
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/get_memos`, {
      method: "GET",
    }).then((worksites: { "hydra:member": WorksitesList }) => {
      setWorksites(worksites["hydra:member"]);
    });
  }, [navigate]);

  return (
    <Section title="Notes internes">
      <Card>
        {worksites ? (
          worksites
            .filter(
              (worksite) =>
                [0, 1].includes(worksite.state) && worksite.description
            )
            .map((worksite, index) => (
              <TheMemoListItem key={index} memo={worksite} />
            ))
        ) : (
          <Loading />
        )}
      </Card>
    </Section>
  );
}
