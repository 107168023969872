import React from "react";
import { twMerge } from "tailwind-merge";

type LoginFormInputProps = {
  label: string;
  name: string;
  type: React.HTMLInputTypeAttribute;
  value?: string | ReadonlyArray<string> | number;
  className?: string;
  onChange?: React.FormEventHandler<HTMLInputElement>;
};

export default function LoginFormInput(props: LoginFormInputProps) {
  return (
    <label className="font-extrabold w-full">
      <div>{props.label}</div>
      <input
        name={props.name}
        type={props.type}
        className={twMerge(
          "border border-grey-4 text-grey-1 rounded px-3 py-0.5 mb-5 w-full",
          props.className
        )}
        value={props.value}
        onChange={props.onChange}
      />
    </label>
  );
}
