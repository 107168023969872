import React from "react";
import { currencyFormat } from "../utils";
import BaseInput from "./BaseInput";

type Partner = {
  "@id": string;
  companyName: string;
  firstname: string;
  lastname: string;
};

type Invoice = {
  "@id": string;
  id: number;
  partner: Partner;
  label: string;
  reference: string;
  attachedQuotations: Array<string>;
  hasReport: boolean;
  hasIBAN: boolean;
  totalAmountExcludingTaxes: number;
  totalAmountIncludingTaxes: number;
  paidAmountIncludingTaxes: number;
  createdAt: string;
};

type InvoiceRowProps = {
  invoice: Invoice;
  onInvoiceAmountChange: (amount: number) => void;
  invoiceAmount: number;
  onInvoiceFocus: () => void;
};

export default function InvoiceAmountInput(props: InvoiceRowProps) {
  const [isFocus, setIsFocus] = React.useState<boolean>(false);


  return (
    <>
      <BaseInput
        type={isFocus ? "number" : "text"}
        value={isFocus ? props.invoiceAmount : currencyFormat.format(props.invoiceAmount)}
        placeholder={currencyFormat.format(props.invoiceAmount)}
        onChange={(event) => {
          props.onInvoiceAmountChange(+event.currentTarget.value);
        }}
        onFocus={() => {
          setIsFocus(true);
          props.onInvoiceFocus();
        }}
        onBlur={() => {
          setIsFocus(false);
        }}
      />
    </>
  );
}
