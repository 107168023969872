import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileUpload,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DownloadFileIcon from "./DownloadFileIcon";

type FileWidgetsProps = {
  file: {
    "@id"?: string;
    id?: number;
    name?: string;
    content?: string;
  };
  onDelete?: () => void;
  onEdit?: () => void;
};

export default function FileWidgets(props: FileWidgetsProps) {
  return (
    <div className="float-right flex gap-2 items-center">
      {props.file["@id"] && (
        <DownloadFileIcon url={props.file["@id"]} />
      )}
      {props.onEdit && (
        <FontAwesomeIcon
          className="cursor-pointer hover:text-green-1"
          icon={faPen}
          onClick={props.onEdit}
        />
      )}
      {props.onDelete && (
        <FontAwesomeIcon
          className="cursor-pointer hover:text-green-1"
          icon={faTrash}
          onClick={props.onDelete}
        />
      )}
    </div>
  );
}
