import React from "react";
import { twMerge } from "tailwind-merge";
import { useNavigate } from "react-router-dom";
import { useCheckTokenAndFetch } from "../auth";
import { toast } from "react-toastify";

type BaseFormProps<T> = {
  isNew: boolean;
  url: string;
  body: object;
  loadingSetter: React.Dispatch<React.SetStateAction<boolean>>;
  successMessage: string;
  onSuccess?: (json: T) => void;
  children?: React.ReactNode;
  className?: string;
};

export default function BaseForm<T>(props: BaseFormProps<T>) {
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.loadingSetter(true);
    checkTokenAndFetch(props.url, {
      headers: {
        "Content-Type": props.isNew
          ? "application/json"
          : "application/merge-patch+json",
      },
      method: props.isNew ? "POST" : "PATCH",
      body: JSON.stringify(props.body),
    })
      .then((json) => {
        if (props.onSuccess) {
          props.onSuccess(json);
        } else {
          navigate(-1);
        }
        toast.success(props.successMessage);
      })
      .finally(() => {
        props.loadingSetter(false);
      });
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={twMerge("flex flex-col", props.className)}
    >
      {props.children}
    </form>
  );
}
