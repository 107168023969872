import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faFileCsv,
  faPen,
  faPlus,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BasePopupConfirmDelete from "../components/BasePopupConfirmDelete";
import Page from "../components/Page";
import Table from "../components/Table";
import { parsePhoneNumber } from "libphonenumber-js";
import { useCheckTokenAndFetch } from "../auth";
import Loading from "../components/Loading";
import BaseSwitch from "../components/BaseSwitch";
import { toast } from "react-toastify";

type PartnersList = Array<{
  id: number;
  isActive: boolean;
  hasMobileAccess: boolean;
  companyName: string;
  firstname: string;
  lastname: string;
  category: {
    name: string;
  };
  cellPhone: string;
  email: string;
  worksites: Array<{
    id: number;
    state: number;
  }>;
}>;

export default function Partners() {
  const [partners, setPartners] = React.useState<PartnersList>();
  const [isPopupActive, setIsPopupActive] = React.useState<boolean>(false);
  const [partnerToDelete, setPartnerToDelete] = React.useState<number>();
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  React.useEffect(() => {
    checkTokenAndFetch(`/api/partners/get_listing`, {
      method: "GET",
    }).then((partners: { "hydra:member": PartnersList }) => {
      setPartners(
        partners["hydra:member"].map((partner) => {
          partner.worksites = partner.worksites.filter(
            (worksite) => worksite.state === 1
          );
          return partner;
        })
      );
    });
  }, [navigate]);

  function csv() {
    let content = "";
    const separator = ";";
    const headersRow = [];
    headersRow.push("Raison sociale");
    headersRow.push("Prénom");
    headersRow.push("Nom");
    headersRow.push("Catégorie");
    headersRow.push("Téléphone");
    headersRow.push("Email");
    content += headersRow.join(separator);
    content += "\r\n";
    if (partners) {
      partners.forEach((partner) => {
        const partnerRow = [];
        partnerRow.push(partner.companyName);
        partnerRow.push(partner.firstname);
        partnerRow.push(partner.lastname);
        partnerRow.push(partner.category.name);
        partnerRow.push(parsePhoneNumber(partner.cellPhone).formatNational());
        partnerRow.push(partner.email);
        content += partnerRow.join(separator);
        content += "\r\n";
      });
    }
    return (
      "data:text/csv;base64," +
      window.btoa(unescape(encodeURIComponent("\ufeff" + content)))
    );
  }

  return (
    <Page icon={{ icon: faUser }} title="Partenaires">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row">
          <BaseButton
            type="button"
            styleType="primary"
            content="Ajouter un partenaire"
            icon={{ icon: faPlus }}
            onClick={() => {
              navigate("ajouter");
            }}
          />
          <div className="flex-grow"></div>
          <a href={csv()} download="partenaires">
            <BaseButton
              type="button"
              styleType="csv"
              content="Export CSV"
              icon={{ icon: faFileCsv }}
            />
          </a>
        </div>
        {partners ? (
          <Table
            search={[0, 1, 2, 3]}
            categories={{
              index: 7,
              default: true,
              labels: [
                {
                  label: "Actifs",
                  value: true,
                },
                {
                  label: "Inactifs",
                  value: false,
                },
              ],
            }}
            headers={[
              {
                name: "Raison sociale",
                order: true,
              },
              {
                name: "Contact",
                order: true,
              },
              {
                name: "Catégorie",
                order: true,
              },
              {
                name: "Téléphone",
                order: false,
              },
              {
                name: "Accès appli",
                order: false,
              },
              {
                name: "Chantiers",
                order: true,
              },
              {},
            ]}
          >
            {partners.map((partner) => [
              {
                elem: partner.companyName,
                value: partner.companyName,
              },
              {
                elem: partner.firstname + " " + partner.lastname,
                value: partner.firstname + partner.lastname,
              },
              {
                elem: partner.category.name,
                value: partner.category.name,
              },
              {
                elem: (
                  <a
                    href={`tel:${partner.cellPhone}`}
                    className="whitespace-nowrap"
                  >
                    {parsePhoneNumber(partner.cellPhone).formatNational()}
                  </a>
                ),
                value: parsePhoneNumber(partner.cellPhone)
                  .formatNational()
                  .replace(/\s/g, ""),
              },
              {
                elem: (
                  <BaseSwitch
                    isChecked={partner.hasMobileAccess}
                    onChange={(isChecked: boolean) => {
                      checkTokenAndFetch(
                        `/api/partners/${partner.id}/patch_mobile_access`,
                        {
                          headers: {
                            "Content-Type": "application/merge-patch+json",
                          },
                          method: "PATCH",
                          body: JSON.stringify({
                            hasMobileAccess: isChecked,
                          }),
                        }
                      ).then(() => {
                        setPartners(
                          partners.map((p) => {
                            if (p.id === partner.id) {
                              return {
                                ...partner,
                                hasMobileAccess: isChecked,
                              };
                            }
                            return p;
                          })
                        );
                        toast.success(
                          "L'accès à l'appli a bien été mis à jour"
                        );
                      });
                    }}
                  />
                ),
                value: partner.hasMobileAccess,
              },
              {
                elem: (
                  <Badge
                    value={partner.worksites.length}
                    type={partner.worksites.length ? "primary" : "inactive"}
                  />
                ),
                value: partner.worksites.length,
              },
              {
                elem: (
                  <span className="text-grey-2 flex flex-row gap-3 items-center">
                    <a href={`mailto:${partner.email}`}>
                      <FontAwesomeIcon
                        className="cursor-pointer hover:text-green-1"
                        icon={faEnvelope}
                      />
                    </a>
                    <Link to={`editer/${partner.id}`}>
                      <FontAwesomeIcon
                        className="cursor-pointer hover:text-green-1"
                        icon={faPen}
                      />
                    </Link>
                    <FontAwesomeIcon
                      className="cursor-pointer hover:text-green-1"
                      icon={faTrash}
                      onClick={() => {
                        setPartnerToDelete(partner.id);
                        setIsPopupActive(true);
                      }}
                    />
                  </span>
                ),
                value: 0,
              },
              {
                elem: "",
                value: partner.isActive,
              },
            ])}
          </Table>
        ) : (
          <Loading />
        )}
        <BasePopupConfirmDelete
          button={{
            styleType: "danger",
            content: "confirmer",
          }}
          isVisible={isPopupActive}
          onClose={() => {
            setIsPopupActive(false);
          }}
          onSuccess={() => {
            if (partners) {
              setPartners(
                partners.filter((item) => item.id !== partnerToDelete)
              );
              toast.success("Le partenaire a bien été supprimé");
            }
          }}
          url={`/api/partners/${partnerToDelete}`}
        >
          Etes-vous sûr de vouloir supprimer le partenaire ?
        </BasePopupConfirmDelete>
      </div>
    </Page>
  );
}
