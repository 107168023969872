import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import LotsPhasesList from "../components/LotsPhasesList";
import { useCheckTokenAndFetch } from "../auth";
import { Partner, PartnerAPI } from "./worksite";
import { Lot } from "../components/LotsPhasesListLot";
import { Phase } from "../components/LotsPhasesListPhase";

type WorksiteLotsAndPhasesAPI = {
  "@id": string;
  name: string;
  partners: Array<string>;
  lots: Array<Lot & { phases: Array<Phase> }>;
};

type WorksiteLotsAndPhasesType = Omit<WorksiteLotsAndPhasesAPI, "lots"> & {
  lots: Array<Lot>;
  phases: Array<Phase>;
};

export default function WorksiteLotsPhases() {
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [worksite, setWorksite] = React.useState<WorksiteLotsAndPhasesType>();
  const [partners, setPartners] = React.useState<Array<PartnerAPI>>();

  React.useEffect(() => {
    retrievesLotsAndPhases();
    checkTokenAndFetch(`/api/partners/get_listing`, { method: "GET" }).then(
      (partners: { "hydra:member": Array<Partner> }) => {
        setPartners(
          partners["hydra:member"].map((partner) => ({
            label: `${partner.companyName} - ${partner.firstname} ${partner.lastname}`,
            value: partner["@id"],
          }))
        );
      }
    );
  }, [params.id]);

  function retrievesLotsAndPhases() {
    return checkTokenAndFetch(
      `/api/worksites/${params.id}/get_lots_and_phases`,
      { method: "GET" }
    ).then((w: WorksiteLotsAndPhasesAPI) => {
      setWorksite({
        ...w,
        phases: w.lots.flatMap((lot) => lot.phases),
      });
    });
  }

  return worksite !== undefined && partners !== undefined ? (
    <LotsPhasesList
      worksiteName={worksite.name}
      worksite={worksite["@id"]}
      lots={worksite.lots}
      handleNewLot={(newLot) =>
        checkTokenAndFetch(`/api/lots/post_new`, {
          headers: {
            "Content-Type": "application/ld+json",
          },
          method: "POST",
          body: JSON.stringify(newLot),
        }).then((lot: Lot) => {
          setWorksite((oldWorksite) => {
            return oldWorksite === undefined
              ? oldWorksite
              : {
                  ...oldWorksite,
                  lots: [...oldWorksite.lots, lot],
                };
          });
          retrievesLotsAndPhases();
        })
      }
      handleEditLot={(lot) =>
        checkTokenAndFetch(`/api/lots/${lot.id}/patch_edit`, {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
          method: "PATCH",
          body: JSON.stringify(lot),
        }).then((lot: Lot) => {
          setWorksite((oldWorksite) => {
            return oldWorksite === undefined
              ? oldWorksite
              : {
                  ...oldWorksite,
                  lots: oldWorksite.lots.map((l) => {
                    if (l.id === lot.id) {
                      return lot;
                    }
                    return l;
                  }),
                };
          });
        })
      }
      handleDeleteLot={(id) =>
        checkTokenAndFetch(`/api/lots/${id}`, { method: "DELETE" }).then(() => {
          setWorksite((oldWorksite) => {
            return oldWorksite === undefined
              ? oldWorksite
              : {
                  ...oldWorksite,
                  lots: oldWorksite.lots.filter((lot) => lot.id !== id),
                };
          });
          retrievesLotsAndPhases();
        })
      }
      phases={worksite.phases}
      handleNewPhase={(newPhase) =>
        checkTokenAndFetch(`/api/phases/post_new`, {
          headers: {
            "Content-Type": "application/ld+json",
          },
          method: "POST",
          body: JSON.stringify(newPhase),
        }).then((phase: Phase) => {
          setWorksite((oldWorksite) => {
            return oldWorksite === undefined
              ? oldWorksite
              : {
                  ...oldWorksite,
                  phases: [...oldWorksite.phases, phase],
                };
          });
        })
      }
      handleEditPhase={(phase) =>
        checkTokenAndFetch(`/api/phases/${phase.id}/patch_edit`, {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
          method: "PATCH",
          body: JSON.stringify(phase),
        }).then((phase: Phase) => {
          setWorksite((oldWorksite) => {
            return oldWorksite === undefined
              ? oldWorksite
              : {
                  ...oldWorksite,
                  phases: [
                    ...oldWorksite.phases.map((p) => {
                      if (p.id === phase.id) {
                        return phase;
                      }
                      return p;
                    }),
                  ],
                };
          });
        })
      }
      handleDeletePhase={(id) =>
        checkTokenAndFetch(`/api/phases/${id}`, { method: "DELETE" }).then(
          () => {
            setWorksite((oldWorksite) => {
              return oldWorksite === undefined
                ? oldWorksite
                : {
                    ...oldWorksite,
                    phases: oldWorksite.phases.filter(
                      (phase) => phase.id !== id
                    ),
                  };
            });
          }
        )
      }
      targets={partners.filter((partner) =>
        worksite.partners.find((p) => p === partner.value)
      )}
    />
  ) : (
    <Loading />
  );
}
