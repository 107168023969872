import React from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Section from "./Section";

type BasePopupProps = {
  title: string;
  isVisible: boolean;
  onClose?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  className?: string;
  children?: React.ReactNode;
};

export default function BasePopup(props: BasePopupProps) {
  const [show, setShow] = React.useState<boolean>(props.isVisible);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (show) {
      ref.current?.focus();
    }
  }, [show]);
  React.useEffect(() => {
    setShow(props.isVisible);
  }, [props]);
  function close() {
    setShow(false);
    props.onClose && props.onClose();
  }

  return (
    <div
      className={twMerge(
        "fixed flex justify-center w-full h-full top-0 left-0 bg-darkgrey bg-opacity-60 z-10 overflow-y-auto py-10",
        !show && "hidden"
      )}
      tabIndex={0}
      ref={ref}
      onMouseDown={close}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          close();
        }
        if (props.onKeyDown) {
          props.onKeyDown(event);
        }
      }}
    >
      <div
        className={twMerge(
          "bg-white rounded my-auto px-6 py-5 max-w-md h-max",
          props.className
        )}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Section
          title={
            <div className="flex w-full">
              {props.title}
              <div className="grow"></div>
              <FontAwesomeIcon
                icon={faTimes}
                className="text-green-1 cursor-pointer ml-4"
                onClick={close}
              />
            </div>
          }
        >
          {props.children}
        </Section>
      </div>
    </div>
  );
}
