import React from "react";
import { twMerge } from "tailwind-merge";

export type BadgeType = "primary" | "danger" | "inactive";

type BadgeProps = {
  value: number;
  type: BadgeType;
};

export default function Badge(props: BadgeProps) {
  function typeStyle(): string {
    switch (props.type) {
      case "primary":
        return "text-white bg-green-1";
      case "danger":
        return "text-red-2 bg-red-2 bg-opacity-30";
      case "inactive":
        return "text-white bg-grey-2";
    }
  }
  return (
    <span
      className={twMerge(
        "inline-flex items-center justify-center w-6 h-6 mx-3 text-xs font-extrabold leading-none rounded-full",
        typeStyle()
      )}
    >
      {props.value}
    </span>
  );
}
