import React from "react";
import { twMerge } from "tailwind-merge";

type DoubleColumnProps = {
  config?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
};

export default function DoubleColumn(props: DoubleColumnProps) {
  function config() {
    const pattern = RegExp(/^(l+)(r+)$/);
    let matches;
    if (
      !props.config ||
      ![2, 3, 4, 5, 6, 12].includes(props.config.length) ||
      !(matches = pattern.exec(props.config))
    ) {
      return {
        left: "basis-1/2",
        right: "basis-1/2",
      };
    }
    let divisor = matches[0].length;
    let leftDividend = matches[1].length;
    let rightDividend = matches[2].length;
    const minDividend = Math.min(leftDividend, rightDividend);

    if (divisor / minDividend > 1 && divisor % minDividend === 0) {
      divisor /= minDividend;
      leftDividend /= minDividend;
      rightDividend /= minDividend;
    }

    return {
      left: "basis-" + leftDividend + "/" + divisor,
      right: "basis-" + rightDividend + "/" + divisor,
    };
  }

  return (
    <div className="flex flex-row gap-7">
      <div className={twMerge("flex flex-col gap-10", config().left)}>
        {props.left}
      </div>
      <div className={twMerge("flex flex-col gap-10", config().right)}>
        {props.right}
      </div>
    </div>
  );
}
