import { twMerge } from "tailwind-merge";
import React from "react";

type BaseTooltipProps = {
  children: React.ReactNode;
  direction: "left" | "right" | "top" | "bottom";
};

export default function BaseTooltip(props: BaseTooltipProps) {
  function directionalClasses() {
    switch (props.direction) {
      case "left":
        return "right-full mr-2 after:inset-y-1/2 -translate-y-1/2 after:left-full after:border-l-darkgrey";
      case "right":
        return "left-full ml-2 after:inset-y-1/2 -translate-y-1/2 after:right-full after:border-r-darkgrey";
      case "top":
        return "bottom-full mb-2 after:inset-x-1/2 -translate-x-1/2 after:top-full after:border-t-darkgrey";
      case "bottom":
        return "top-full mt-2 after:inset-x-1/2 -translate-x-1/2 after:bottom-full after:border-b-darkgrey";
    }
  }

  return (
    <span
      className={twMerge(
        "w-max absolute bg-darkgrey text-white text-center rounded px-1 after:content-[''] after:absolute after:border-4 after:border-transparent",
        directionalClasses()
      )}
    >
      {props.children}
    </span>
  );
}
