import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider, RequireAuth } from "./auth";
import "./index.css";
import Login from "./routes/login";
import GoToApp from "./routes/goToApp";
import CnlDownload from "./routes/cnlDownload";
import ResetPasswordRequest from "./routes/resetPasswordRequest";
import ResetPassword from "./routes/resetPassword";
import Layout from "./routes/layout";
import Dashboard from "./routes/dashboard";
import Worksites from "./routes/worksites";
import WorksitesNew from "./routes/worksitesNew";
import WorksitesNewClient from "./routes/worksitesNewClient";
import WorksitesNewInfos from "./routes/worksitesNewInfos";
import WorksitesNewFiles from "./routes/worksitesNewFiles";
import WorksitesNewLotsPhases from "./routes/worksitesNewLotsPhases";
import Worksite from "./routes/worksite";
import WorksiteDashboard from "./routes/worksiteDashboard";
import WorksiteInfos from "./routes/worksiteInfos";
import WorksitePlanning from "./routes/worksitePlanning";
import WorksiteTasksMemos from "./routes/worksiteTasksMemos";
import WorksiteFinancial from "./routes/worksiteFinancial";
import WorksiteReports from "./routes/worksiteReports";
import WorksiteLotsPhases from "./routes/worksiteLotsPhases";
import WorksiteFiles from "./routes/worksiteFiles";
import Clients from "./routes/clients";
import ClientsNew from "./routes/clientsNew";
import ClientsEdit from "./routes/clientsEdit";
import Partners from "./routes/partners";
import PartnersNew from "./routes/partnersNew";
import PartnersEdit from "./routes/partnersEdit";
import ConfigurationLotsPhases from "./routes/configurationLotsPhases";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfigurationAdmins from "./routes/configurationAdmins";
import ConfigurationAdminsNew from "./routes/configurationAdminsNew";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="connexion" element={<Login />} />
          <Route
            path="mot-de-passe"
            element={<ResetPasswordRequest />}
          />
          <Route
            path="nouveau-mot-de-passe/:token"
            element={<ResetPassword />}
          />
          <Route path="telecharger-appli" element={<GoToApp />}/>
          <Route path="telecharger-cnl/" element={<CnlDownload />} />
          <Route
            path="/"
            element={
              <RequireAuth role={["ROLE_ADMIN"]}>
                <Layout />
              </RequireAuth>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="chantiers">
              <Route index element={<Worksites />} />
              <Route path="ajouter" element={<WorksitesNew />}>
                <Route path="client" element={<WorksitesNewClient />} />
                <Route path="infos" element={<WorksitesNewInfos />} />
                <Route path="documents" element={<WorksitesNewFiles />} />
                <Route
                  path="lots-phases"
                  element={<WorksitesNewLotsPhases />}
                />
              </Route>
              <Route path=":id" element={<Worksite />}>
                <Route path="tableau-de-bord" element={<WorksiteDashboard />} />
                <Route path="infos" element={<WorksiteInfos />} />
                <Route path="planning" element={<WorksitePlanning />} />
                <Route path="taches-infos" element={<WorksiteTasksMemos />} />
                <Route path="financier" element={<WorksiteFinancial />} />
                <Route path="pv-reception" element={<WorksiteReports />} />
                <Route path="lots-phases" element={<WorksiteLotsPhases />} />
                <Route path="documents" element={<WorksiteFiles />} />
              </Route>
            </Route>
            <Route path="clients">
              <Route index element={<Clients />} />
              <Route path="ajouter" element={<ClientsNew />} />
              <Route path="editer/:id" element={<ClientsEdit />} />
            </Route>
            <Route path="partenaires">
              <Route index element={<Partners />} />
              <Route path="ajouter" element={<PartnersNew />} />
              <Route path="editer/:id" element={<PartnersEdit />} />
            </Route>
            <Route path="configuration">
              <Route path="lots-phases" element={<ConfigurationLotsPhases />} />
              <Route path="administrateurs">
                <Route index element={<ConfigurationAdmins />} />
                <Route path="ajouter" element={<ConfigurationAdminsNew />} />
              </Route>
            </Route>

            <Route
              path="*"
              element={
                <div className="mx-4">
                  <div className="mx-auto w-max">
                    <h1 className="font-extrabold text-3xl mb-10">404</h1>
                    {"La page à laquelle vous essayez d'accéder n'existe pas"}
                  </div>
                </div>
              }
            />
          </Route>
        </Routes>
        <ToastContainer theme="colored" />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
