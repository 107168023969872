import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import L from "leaflet";

declare global {
  interface Date {
    getWeekNumber(): number;
  }
}
Date.prototype.getWeekNumber = function (): number {
  const currentYear = this.getFullYear();
  const currentDay = new Date(this.getTime());
  currentDay.setHours(0, 0, 0, 0);

  const dayOfYear =
    Math.ceil(
      (currentDay.getTime() - new Date(currentYear, 0, 1).getTime()) /
        1000 /
        60 /
        60 /
        24
    ) + 1;
  const firstDayOfYear = 5 - new Date(currentYear, 0, 4).getDay();
  const result = Math.floor((dayOfYear - firstDayOfYear) / 7) + 1;

  return result || new Date(currentYear - 1, 11, 31).getWeekNumber();
};

export const currencyFormat = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
});

export const marker = new L.DivIcon({
  html: ReactDOMServer.renderToStaticMarkup(
    <FontAwesomeIcon
      icon={faMapMarkerAlt}
      className="text-3xl text-darkgrey hover:text-green-1 stroke-white hover:stroke-darkgrey stroke-[10]"
    />
  ),
  iconAnchor: [11.25, 30],
  tooltipAnchor: [0, -20],
  className: "",
});

export function getCenter(
  points: Array<{
    locationLatitude: number;
    locationLongitude: number;
  }>
) {
  let minLat = 90;
  let maxLat = -90;
  let minLng = 180;
  let maxLng = -180;

  points.forEach((point) => {
    minLat = Math.min(minLat, point.locationLatitude);
    maxLat = Math.max(maxLat, point.locationLatitude);
    minLng = Math.min(minLng, point.locationLongitude);
    maxLng = Math.max(maxLng, point.locationLongitude);
  });

  return {
    lat: minLat + (maxLat - minLat) / 2,
    lng: minLng + (maxLng - minLng) / 2,
  };
}

export function getStringRole(roles: Array<string>): string {
  const rolePriorityAndName = [
    { id: "ROLE_ADMIN", name: "Administrateur" },
    { id: "ROLE_PARTNER", name: "Partenaire" },
    { id: "ROLE_CLIENT", name: "Client" },
  ];

  const rankNumber = roles.reduce((highest, role) => {
    const rank = rolePriorityAndName.findIndex(
      (priorityAndName) => priorityAndName.id === role
    );
    return rank < highest ? rank : highest;
  }, Infinity);

  return rankNumber === Infinity ? "" : rolePriorityAndName[rankNumber].name;
}

export function b64toBin(b64: string) {
  function getBinValue(c: number) {
    if (c >= 65 && c <= 90) {
      return c - 65;
    }
    if (c >= 97 && c <= 122) {
      return c - 71;
    }
    if (c >= 48 && c <= 57) {
      return c + 4;
    }
    if (c === 43) {
      return 62;
    }
    if (c === 47) {
      return 63;
    }
    return 0;
  }
  if (b64.length % 4) {
    return false;
  }
  const arr = [];
  for (let i = 0; i < b64.length; i += 4) {
    let int24 = 0;
    for (let j = 0; j < 4; j++) {
      int24 |= getBinValue(b64.charCodeAt(i + j));
      int24 <<= 6;
    }
    int24 >>= 6;
    for (let j = 0; j < 3; j++) {
      arr.push((int24 & 0b111111110000000000000000) >> 16);
      int24 <<= 8;
    }
  }
  while (arr.length && arr[arr.length - 1] === 0) {
    arr.pop();
  }
  return arr;
}
