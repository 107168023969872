import React from "react";
import BaseLabel from "../components/BaseLabel";
import BaseRadio from "../components/BaseRadio";
import BaseSelect from "../components/BaseSelect";
import Card from "../components/Card";
import ClientsNewForm from "../components/ClientsNewForm";
import { useCheckTokenAndFetch } from "../auth";
import { useNewWorksite } from "./worksitesNew";

type ClientAPI = {
  "@id": string;
  firstname: string;
  lastname: string;
  parent?: Omit<ClientAPI, "parent" | "related">;
  related?: Omit<ClientAPI, "parent" | "related">;
};

type ClientOption = {
  label: string;
  value: string;
};

export default function WorksitesNewClient() {
  const context = useNewWorksite();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [worksite, setWorksite] = context.worksite;
  const formRef = context.formRef;
  const [clientID, setClientID] = React.useState<string>("");
  const [clientsOptions, setClientsOptions] = React.useState<
    Array<ClientOption>
  >([]);

  React.useEffect(() => {
    checkTokenAndFetch(`/api/clients/get_listing`, {
      method: "GET",
    }).then((clients: { "hydra:member": Array<ClientAPI> }) => {
      setClientsOptions(
        clients["hydra:member"]
          .filter((client) => !client.parent)
          .sort((a, b) => { // Sort by lastname
            if (a.lastname < b.lastname) {
              return -1;
            }
            if (a.lastname > b.lastname) {
              return 1;
            }
            return 0;
          })
          .map((c) => ({
            label:
              c.lastname +
              " " +
              c.firstname +
              (c.related === undefined
                ? ""
                : " & " + c.related.lastname + " " + c.related.firstname),
            value: c["@id"],
          }))
      );
    });
    if (typeof worksite.client === "string") {
      setClientID(worksite.client);
    }
  }, []);
  function handleClientTypeChange(event: React.BaseSyntheticEvent) {
    if (event.currentTarget.value) {
      setWorksite({
        ...worksite,
        client: {
          isProfessional: false,
          companyName: "",
          companyStatus: "",
          sex: 0,
          lastname: "",
          firstname: "",
          address: "",
          zipCode: "",
          city: "",
          cellPhone: "",
          homePhone: "",
          email: "",
          password: "",
          repeatPassword: "",
        },
      });
    } else {
      setWorksite({
        ...worksite,
        client: clientID,
      });
    }
  }

  return (
    <form
      ref={(ref) => {
        formRef.current = ref;
      }}
      className="flex flex-col gap-9"
    >
      <div className="flex flex-row gap-9">
        <div className="basis-1/3">
          <Card>
            <BaseLabel label="Client existant ou nouveau ?">
              <div className="flex flex-row gap-6">
                <BaseRadio
                  className="shrink"
                  name="isNewClient"
                  label="Existant"
                  value={false}
                  currentValue={typeof worksite.client !== "string"}
                  onChange={handleClientTypeChange}
                />
                <BaseRadio
                  className="shrink"
                  label="Nouveau"
                  name="isNewClient"
                  value={true}
                  currentValue={typeof worksite.client !== "string"}
                  onChange={handleClientTypeChange}
                />
              </div>
            </BaseLabel>
          </Card>
        </div>
        {typeof worksite.client === "string" && (
          <div className="basis-2/3">
            <Card>
              <BaseLabel required label="Sélectionner le client">
                <BaseSelect
                  required
                  name="clientID"
                  value={clientID}
                  options={clientsOptions}
                  onChange={(event) => {
                    setClientID(event.currentTarget.value);
                    setWorksite({
                      ...worksite,
                      client: event.currentTarget.value,
                    });
                  }}
                />
              </BaseLabel>
            </Card>
          </div>
        )}
      </div>
      {typeof worksite.client !== "string" && (
        <ClientsNewForm
          initialValues={worksite.client}
          handler={(client) => {
            setWorksite({
              ...worksite,
              client: client,
            });
          }}
        />
      )}
    </form>
  );
}
