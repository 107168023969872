import React from "react";
import { twMerge } from "tailwind-merge";
import { NavLink } from "react-router-dom";

type TheSidebarNavLinkSubLinkProps = {
  to: string;
  content: string;
};

export default function TheSidebarNavLinkSubLink(
  props: TheSidebarNavLinkSubLinkProps
) {
  return (
    <NavLink
      className={({ isActive }) =>
        twMerge(
          "pl-6 mt-2 hover:bg-green-2",
          isActive ? "text-green-1 font-bold" : "opacity-60"
        )
      }
      to={props.to}
    >
      {props.content}
    </NavLink>
  );
}
