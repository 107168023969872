import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

interface UserType {
  id?: number;
  iat: number;
  exp: number;
  roles: Array<string>;
  username: string;
  token: string;
  refreshToken: string;
}

interface AuthContextType {
  user: UserType;
  signin: (
    username: string,
    password: string,
    success: VoidFunction,
    error: VoidFunction
  ) => void;
  signout: (callback: VoidFunction) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const userCookie = Cookies.get("user");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [user, setUser] = React.useState<any>(
    userCookie === undefined ? null : JSON.parse(userCookie)
  );

  const signin = (
    username: string,
    password: string,
    success: VoidFunction,
    error: VoidFunction
  ) => {
    fetch(`${window.location.origin}:8000/api/token/login`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: username,
        password: password,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw new Error();
      })
      .then((json) => setUser(updateUser(json)))
      .then(success)
      .catch(error);
  };

  const signout = (callback: VoidFunction) => {
    setUser(null);
    Cookies.remove("user");
    callback();
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function updateUser(json: { token: string; refresh_token: string }): UserType {
  const userInfos = jwtDecode<UserType>(json.token);
  userInfos.token = json.token;
  userInfos.refreshToken = json.refresh_token;
  Cookies.set("user", JSON.stringify(userInfos));

  return userInfos;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth(props: { role: Array<string>; children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (
    !auth.user ||
    !auth.user.roles.filter((role) => props.role.includes(role)).length
  ) {
    // toast.error(
    //   "Vous n'avez pas les droits suffisants pour accéder à cette page",
    //   {
    //     toastId: "noauth",
    //   }
    // );
    return <Navigate to="/connexion" state={{ from: location }} replace />;
  }

  return props.children;
}

function useCheckTokenAndFetch() {
  const auth = useAuth();
  const navigate = useNavigate();

  const checkTokenAndFetch = React.useCallback(
    (input: string, init?: RequestInit | undefined) => {
      let p = init;
      if (auth.user) {
        p = {
          ...init,
          headers: {
            ...init?.headers,
            accept: "application/ld+json",
            Authorization: `Bearer ${auth.user.token}`,
          },
        };
      }
      return fetch(`${window.location.origin}:8000${input}`, p)
        .then((resp) => {
          switch (resp.status) {
            case 204:
              return resp;
            case 401:
              throw new Error();
          }
          return resp.json();
        })
        .then((json) => {
          if (json["@type"] === "hydra:Error") {
            toast.error(json["hydra:description"]);
            throw Error();
          }
          return json;
        })
        .catch(() => {
          return fetch(`${window.location.origin}:8000/api/token/refresh`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              refresh_token: auth.user.refreshToken,
            }),
          })
            .then((resp) => {
              if (resp.ok) {
                return resp.json();
              }
              throw new Error();
            })
            .then((json) => {
              const user = updateUser(json);
              auth.user = user;
              p = {
                ...init,
                headers: {
                  ...init?.headers,
                  Authorization: `Bearer ${auth.user.token}`,
                },
              };
            })
            .then(() => {
              return fetch(`${window.location.origin}:8000${input}`, p)
                .then((resp) => {
                  if (resp.status === 401) {
                    throw new Error();
                  }
                  return resp.json();
                })
                .catch(() => {
                  auth.signout(() => navigate("/"));
                  return new Promise<Response>((resolve) => resolve);
                });
            })
            .catch(() => {
              auth.signout(() => navigate("/"));
              return new Promise<Response>((resolve) => resolve);
            });
        });
    },
    []
  );

  return checkTokenAndFetch;
}

export {
  useAuth,
  updateUser,
  AuthProvider,
  RequireAuth,
  useCheckTokenAndFetch,
};
