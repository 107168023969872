import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../auth";
import {
  faBriefcase,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../components/BaseButton";
import BaseInput from "../components/BaseInput";
import Card from "../components/Card";
import DoubleColumn from "../components/DoubleColumn";
import Page from "../components/Page";
import Section from "../components/Section";
import BaseLabel from "../components/BaseLabel";
import BaseSelect from "../components/BaseSelect";
import BaseForm from "../components/BaseForm";
import BaseSwitch from "../components/BaseSwitch";
import BasePopup from "../components/BasePopup";
import CardContent from "../components/CardContent";
import FileWidgets from "../components/FileWidgets";
import { parsePhoneNumber } from "libphonenumber-js";
import { useCheckTokenAndFetch } from "../auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilesCategory from "../components/FilesCategory";
import { toast } from "react-toastify";

type Alert = {
  name: string;
  description: string;
  hasClientAlert: false;
  dueDate: string;
  recallDate?: string;
};

type File = {
  "@id"?: string;
  name: string;
  createdAt: string;
  content?: string;
  startDate?: string;
  endDate?: string;
  alerts: Array<Alert>;
  type?: "" | "iban" | "insurance";
};

interface Partner {
  firstname: string;
  lastname: string;
  sex: number;
  email: string;
  isActive: boolean;
  hasMobileAccess: boolean;
  category: string;
  iban: File | null;
  insurances: Array<File>;
  address: string;
  zipCode: string;
  city: string;
  cellPhone: string;
  homePhone: string;
  password: string;
  repeatPassword: string;
  companyName: string;
  companyStatus: string;
}

type NewFile = {
  name: string;
  type: "" | "iban" | "insurance";
  startDate?: string;
  endDate?: string;
  alerts: Array<Alert>;
};

type Category = {
  "@id": string;
  id: number;
  name: string;
};

export default function PartnersEdit() {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const checkTokenAndFetch = useCheckTokenAndFetch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [sendLoading, setSendLoading] = React.useState<boolean>(false);
  const [currentFile, setCurrentFile] = React.useState<{
    fIndex: number;
    file: File;
  } | null>(null);
  const [isNewFileActive, setIsNewFileActive] = React.useState<boolean>(false);
  const newFileRef = React.useRef<HTMLInputElement>(null);
  const [newFile, setNewFile] = React.useState<NewFile>({
    type: "",
    name: "",
    startDate: "",
    endDate: "",
    alerts: [],
  });
  const [partner, setPartner] = React.useState<Partner>({
    firstname: "",
    lastname: "",
    sex: 0,
    email: "",
    isActive: false,
    hasMobileAccess: false,
    category: "",
    iban: null,
    insurances: [],
    address: "",
    zipCode: "",
    city: "",
    cellPhone: "",
    homePhone: "",
    password: "",
    repeatPassword: "",
    companyName: "",
    companyStatus: "",
  });
  const [categories, setCategories] = React.useState<Array<Category>>([]);
  const buttons = (
    <div className="flex flex-row gap-3">
      <BaseButton
        type="button"
        styleType="cancel"
        content="Annuler"
        onClick={() => {
          navigate(-1);
        }}
      />
      <BaseButton
        type="submit"
        styleType="primary"
        content="Enregistrer les modifications"
        icon={
          isLoading
            ? {
                icon: faSpinner,
                className: "animate-spin",
              }
            : undefined
        }
      />
    </div>
  );

  React.useEffect(() => {
    checkTokenAndFetch(`/api/partners/${params.id}/get_form_infos`, {
      method: "GET",
    }).then((p: Partner) => {
      if (p.cellPhone) {
        try {
          const phone = parsePhoneNumber(p.cellPhone)
            .formatNational()
            .replace(/\s/g, "");
          p.cellPhone = phone;
        } catch (error) {
          p.cellPhone = "";
        }
      }
      if (p.homePhone) {
        try {
          const phone = parsePhoneNumber(p.homePhone)
            .formatNational()
            .replace(/\s/g, "");
          p.homePhone = phone;
        } catch (error) {
          p.homePhone = "";
        }
      }
      setPartner(p);
    });
  }, [auth.user.token, params.id]);
  React.useEffect(() => {
    checkTokenAndFetch(`/api/partner_categories/get_listing`, {
      method: "GET",
    }).then((categories: { "hydra:member": Array<Category> }) => {
      setCategories(categories["hydra:member"]);
    });
  }, [auth.user.token]);
  function handleEditPartnerChange(event: React.BaseSyntheticEvent) {
    const param: keyof Partner = event.currentTarget.name;

    setPartner({
      ...partner,
      [param]: event.currentTarget.value,
    });
  }
  function handleNewFileChange(
    event: React.BaseSyntheticEvent,
    alertIndex?: number,
    alertParam?: keyof Alert
  ) {
    if (alertIndex !== undefined && alertParam !== undefined) {
      setNewFile({
        ...newFile,
        alerts: newFile.alerts.map((alert, aIndex) => {
          if (aIndex === alertIndex) {
            return {
              ...alert,
              [alertParam]: event.currentTarget.value,
            };
          }
          return alert;
        }),
      });
    } else {
      const param: keyof NewFile = event.currentTarget.name;

      setNewFile({
        ...newFile,
        [param]: event.currentTarget.value,
      });
    }
  }
  function handleEditFileChange(
    event: React.BaseSyntheticEvent,
    alertIndex?: number,
    alertParam?: keyof Alert
  ) {
    if (currentFile) {
      if (alertIndex !== undefined && alertParam !== undefined) {
        setCurrentFile({
          ...currentFile,
          file: {
            ...currentFile.file,
            alerts: currentFile.file.alerts.map((alert, aIndex) => {
              if (aIndex === alertIndex) {
                return {
                  ...alert,
                  [alertParam]: event.currentTarget.value,
                };
              }
              return alert;
            }),
          },
        });
      } else {
        const param: Exclude<keyof File, "id"> = event.currentTarget.name;
        setCurrentFile({
          ...currentFile,
          file: { ...currentFile.file, [param]: event.currentTarget.value },
        });
      }
    }
  }
  function handleNewFile(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result as string;
      switch (newFile.type) {
        case "iban":
          setPartner({
            ...partner,
            iban: {
              name: newFile.name,
              createdAt: new Date().toISOString(),
              content: result,
              alerts: [],
            },
          });
          break;
        case "insurance":
          setPartner({
            ...partner,
            insurances: [
              ...partner.insurances,
              {
                name: newFile.name,
                createdAt: new Date().toISOString(),
                content: result,
                startDate: newFile.startDate,
                endDate: newFile.endDate,
                alerts: newFile.alerts,
              },
            ],
          });
          break;
      }
      setNewFile({
        type: "",
        name: "",
        startDate: "",
        endDate: "",
        alerts: [],
      });
      // clear newFileRef
      if (newFileRef.current) {
        newFileRef.current.value = "";
      }
      setIsNewFileActive(false);
    };
    if (newFileRef.current?.files) {
      reader.readAsDataURL(newFileRef.current.files[0]);
    }
  }
  function body(): Partner {
    const ret: Partner = JSON.parse(JSON.stringify(partner));
    ret.isActive = partner.isActive ? true : false;
    ret.hasMobileAccess = partner.hasMobileAccess ? true : false;
    ret.sex = +partner.sex;
    if (ret.cellPhone) {
      try {
        const phone = parsePhoneNumber(ret.cellPhone, "FR");
        ret.cellPhone = phone.number;
      } catch (error) {
        ret.cellPhone = "";
      }
    }
    if (ret.homePhone) {
      try {
        const phone = parsePhoneNumber(ret.homePhone, "FR");
        ret.homePhone = phone.number;
      } catch (error) {
        ret.homePhone = "";
      }
    }
    ret.insurances = ret.insurances.map((insurance) => ({
      ...insurance,
      alerts: (insurance.alerts = insurance.alerts.map((alert) => {
        if (alert.recallDate === "") {
          alert.recallDate = undefined;
        }
        return alert;
      })),
    }));
    return ret;
  }

  function handleSendConnexion(event: React.SyntheticEvent) {
    event.preventDefault();
    setSendLoading(true);
    checkTokenAndFetch(
      `/api/partners/${params.id}/send-connexion`
    )
      .then(() => {
        toast.success(
          "Un email de connexion vient d'être envoyé au partenaire"
        );
        setSendLoading(false);
      })
      .catch((err) => {
        toast.error("Une erreur est survenue");
        setSendLoading(false);
      });
  }

  return (
    <Page
      icon={{ icon: faBriefcase }}
      title={`Modifier le partenaire (${partner.firstname} ${partner.lastname})`}
    >
      <>
        <BaseForm
          isNew={false}
          url={`/api/partners/${params.id}/patch_edit`}
          body={body()}
          loadingSetter={setIsLoading}
          successMessage="Le partenaire a bien été mis à jour"
        >
          <div className="flex mb-14">
            {buttons}
            <div className="grow"></div>
            <BaseButton
              type="button"
              styleType="secondary"
              content="Ajouter un document"
              icon={{
                icon: faPlus,
              }}
              onClick={() => {
                setIsNewFileActive(true);
              }}
            />
          </div>
          <DoubleColumn
            config="llr"
            left={
              <>
                <Section title="Informations générales">
                  <Card className="max-h-max grid grid-cols-12">
                    <BaseLabel required label="Actif" className="col-span-2">
                      <BaseSwitch
                        isChecked={partner.isActive}
                        onChange={(isChecked: boolean) => {
                          setPartner({
                            ...partner,
                            isActive: isChecked,
                          });
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Accès appli"
                      className="col-span-2"
                    >
                      <BaseSwitch
                        isChecked={partner.hasMobileAccess}
                        onChange={(isChecked: boolean) => {
                          setPartner({
                            ...partner,
                            hasMobileAccess: isChecked,
                          });
                        }}
                      />
                    </BaseLabel>
                    {partner.hasMobileAccess ? (
                      <BaseButton
                        type="button"
                        styleType="csv"
                        content="Envoyer infos connexion"
                        icon={
                          sendLoading
                            ? {
                                icon: faSpinner,
                                className: "animate-spin",
                              }
                            : undefined
                        }
                        onClick={(e) => handleSendConnexion(e)}
                        className="col-span-2 h-12 leading-5 mb-0"
                      />
                    )
                    : (
                      <div className="col-span-2"></div>
                    )
                    }
                    <BaseLabel
                      required
                      label="Catégorie"
                      className="col-span-6"
                    >
                      <BaseSelect
                        required
                        name="category"
                        value={partner?.category}
                        onChange={handleEditPartnerChange}
                        options={categories
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((category) => ({
                            label: category.name,
                            value: category["@id"],
                          }))}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Raison sociale"
                      className="col-span-6"
                    >
                      <BaseInput
                        required
                        type="text"
                        name="companyName"
                        value={partner?.companyName}
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Forme juridique"
                      className="col-span-6"
                    >
                      <BaseInput
                        required
                        type="text"
                        name="companyStatus"
                        value={partner?.companyStatus}
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Civilité" className="col-span-2">
                      <BaseSelect
                        required
                        name="sex"
                        value={partner?.sex}
                        onChange={handleEditPartnerChange}
                        options={[
                          { label: "M.", value: 1 },
                          { label: "Mme.", value: 2 },
                        ]}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Nom" className="col-span-5">
                      <BaseInput
                        required
                        type="text"
                        name="lastname"
                        value={partner?.lastname}
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Prénom" className="col-span-5">
                      <BaseInput
                        required
                        type="text"
                        name="firstname"
                        value={partner?.firstname}
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Adresse" className="col-span-12">
                      <BaseInput
                        required
                        type="text"
                        name="address"
                        value={partner?.address}
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Code postal"
                      className="col-span-3"
                    >
                      <BaseInput
                        required
                        type="text"
                        pattern="[0-9]{5}"
                        name="zipCode"
                        value={partner?.zipCode}
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Ville" className="col-span-3">
                      <BaseInput
                        required
                        type="text"
                        name="city"
                        value={partner?.city}
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel
                      required
                      label="Téléphone Port."
                      className="col-span-3"
                    >
                      <BaseInput
                        required
                        type="text"
                        name="cellPhone"
                        value={partner?.cellPhone}
                        pattern="[0-9]{10}"
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                    <BaseLabel label="Téléphone Fixe" className="col-span-3">
                      <BaseInput
                        type="text"
                        name="homePhone"
                        value={partner?.homePhone}
                        pattern="[0-9]{10}"
                        onChange={handleEditPartnerChange}
                      />
                    </BaseLabel>
                  </Card>
                </Section>
                <Section title="Identifiants">
                  <Card className="max-h-max">
                    {[
                      <React.Fragment key={0}>
                        <BaseLabel label="Adresse email">
                          <BaseInput
                            type="email"
                            name="email"
                            value={partner?.email}
                            onChange={handleEditPartnerChange}
                          />
                        </BaseLabel>
                        <div className="flex flex-row gap-4">
                          <BaseLabel label="Mot de passe">
                            <BaseInput
                              autoComplete="new-password"
                              type="password"
                              name="password"
                              value={partner?.password ? partner?.password : ""}
                              onChange={handleEditPartnerChange}
                            />
                          </BaseLabel>
                          <BaseLabel label="Confirmation">
                            <BaseInput
                              autoComplete="new-password"
                              repeat={partner?.password}
                              type="password"
                              name="repeatPassword"
                              value={partner?.repeatPassword ? partner?.repeatPassword : ""}
                              onChange={handleEditPartnerChange}
                            />
                          </BaseLabel>
                        </div>
                      </React.Fragment>,
                    ]}
                  </Card>
                </Section>
              </>
            }
            right={
              <>
                {partner.insurances.length > 0 && (
                  <FilesCategory
                    files={{
                      name: "Assurances",
                      roles: [],
                      files: partner.insurances,
                    }}
                    onDelete={(fIndex) => {
                      setPartner({
                        ...partner,
                        insurances: partner.insurances.filter(
                          (_, itemIndex) => itemIndex !== fIndex
                        ),
                      });
                    }}
                    onEdit={(file, fIndex) => {
                      setCurrentFile({
                        fIndex: fIndex,
                        file: {
                          ...file,
                          type: "insurance",
                          alerts: file.alerts.map((alert) => ({
                            ...alert,
                            hasClientAlert: false,
                          })),
                        },
                      });
                    }}
                  />
                )}
                {partner.iban && (
                  <Section title="RIB">
                    <Card>
                      {[
                        <CardContent key={0} title={partner.iban.name}>
                          {[
                            <div key={0} className="text-grey-2">
                              <span>
                                {new Date(
                                  partner.iban.createdAt
                                ).toLocaleDateString()}
                              </span>
                              <FileWidgets
                                file={{
                                  "@id": partner.iban["@id"],
                                  name: partner.iban.name,
                                  content: partner.iban.content,
                                }}
                                onDelete={() => {
                                  setPartner({
                                    ...partner,
                                    iban: null,
                                  });
                                }}
                              />
                            </div>,
                          ]}
                        </CardContent>,
                      ]}
                    </Card>
                  </Section>
                )}
              </>
            }
          />
          <div className="mx-auto mt-14">{buttons}</div>
        </BaseForm>
        <BasePopup
          title="Ajout d'un document"
          isVisible={isNewFileActive}
          onClose={() => {
            setIsNewFileActive(false);
          }}
        >
          <form className="flex flex-col gap-4" onSubmit={handleNewFile}>
            <BaseLabel required label="Type du document">
              <BaseSelect
                required
                name="type"
                value={newFile.type}
                options={[
                  {
                    label: "RIB",
                    value: "iban",
                  },
                  {
                    label: "Assurance",
                    value: "insurance",
                  },
                ]}
                onChange={handleNewFileChange}
              />
            </BaseLabel>
            <BaseLabel required label="Nom du document">
              <BaseInput
                required
                type="text"
                name="name"
                value={newFile.name}
                onChange={handleNewFileChange}
              />
            </BaseLabel>
            {newFile.type === "insurance" && (
              <div className="flex gap-2">
                <BaseLabel required label="Date de début">
                  <BaseInput
                    required
                    type="date"
                    name="startDate"
                    value={newFile.startDate}
                    onChange={handleNewFileChange}
                  />
                </BaseLabel>
                <BaseLabel required label="Date de fin">
                  <BaseInput
                    required
                    type="date"
                    name="endDate"
                    value={newFile.endDate}
                    onChange={handleNewFileChange}
                  />
                </BaseLabel>
              </div>
            )}
            <BaseLabel required label="Document">
              <BaseInput required ref={newFileRef} type="file" />
            </BaseLabel>
            {newFile.type === "insurance" && (
              <>
                <div className="bg-grey-7 rounded p-4">
                  <div className="flex flex-wrap items-center gap-2 text-green-1 font-bold">
                    <div
                      className="flex place-items-center justify-center rounded-full bg-green-3 bg-opacity-20 w-8 h-8 cursor-pointer"
                      onClick={() => {
                        setNewFile({
                          ...newFile,
                          alerts:
                            newFile.type === "insurance"
                              ? [
                                  ...newFile.alerts,
                                  {
                                    name: "",
                                    description: "",
                                    hasClientAlert: false,
                                    // dueDate: new Date()
                                    //   .toISOString()
                                    //   .slice(0, 10),
                                    dueDate: newFile.endDate ?? new Date().toISOString().slice(0, 10),
                                    // recallDate: "",
                                    recallDate: new Date(new Date(newFile.endDate ?? new Date()).setMonth(new Date(newFile.endDate ?? new Date()).getMonth() + 1)).toISOString().slice(0, 10)
                                  },
                                ]
                              : [],
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    Alerte
                  </div>
                </div>
                {newFile.alerts.map((alert, aIndex) => (
                  <div
                    key={aIndex}
                    className="bg-grey-7 rounded p-4 flex flex-col gap-3"
                  >
                    <BaseLabel required label="Nom de l'alerte">
                      <BaseInput
                        required
                        type="text"
                        value={alert.name}
                        onChange={(event) => {
                          handleNewFileChange(event, aIndex, "name");
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel label="Description">
                      <BaseInput
                        type="text"
                        value={alert.description}
                        onChange={(event) => {
                          handleNewFileChange(event, aIndex, "description");
                        }}
                      />
                    </BaseLabel>
                    <div className="flex gap-3">
                      <BaseLabel required label="Echeance">
                        <BaseInput
                          required
                          type="date"
                          value={alert.dueDate}
                          onChange={(event) => {
                            handleNewFileChange(event, aIndex, "dueDate");
                          }}
                        />
                      </BaseLabel>
                      <BaseLabel label="Rappel">
                        <BaseInput
                          type="date"
                          value={alert.recallDate}
                          onChange={(event) => {
                            handleNewFileChange(event, aIndex, "recallDate");
                          }}
                        />
                      </BaseLabel>
                    </div>
                    <BaseButton
                      type="button"
                      styleType="danger"
                      content="Supprimer"
                      className="w-min self-end"
                      onClick={() => {
                        setNewFile({
                          ...newFile,
                          alerts: newFile.alerts.filter((_, i) => i !== aIndex),
                        });
                      }}
                    />
                  </div>
                ))}
              </>
            )}
            <BaseButton
              type="submit"
              styleType="primary"
              content="Valider"
              className="mt-4 self-end"
            />
          </form>
        </BasePopup>
        <BasePopup
          title="Modifier le document"
          isVisible={currentFile !== null}
          className="min-w-[400px]"
          onClose={() => {
            setCurrentFile(null);
          }}
        >
          {currentFile && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setPartner({
                  ...partner,
                  insurances: partner.insurances.map((insurance, iIndex) => {
                    if (iIndex === currentFile.fIndex) {
                      return currentFile.file;
                    } else {
                      return insurance;
                    }
                  }),
                });
                setCurrentFile(null);
              }}
            >
              <div className="flex flex-col gap-5">
                <BaseLabel required label="Libellé">
                  <BaseInput
                    required
                    type="text"
                    name="name"
                    value={currentFile.file.name}
                    onChange={handleEditFileChange}
                  />
                </BaseLabel>
                {currentFile.file.type === "insurance" && (
                  <div className="flex gap-2">
                    <BaseLabel required label="Date de début">
                      <BaseInput
                        required
                        type="date"
                        name="startDate"
                        value={currentFile.file.startDate?.slice(0, 10)}
                        onChange={handleEditFileChange}
                      />
                    </BaseLabel>
                    <BaseLabel required label="Date de fin">
                      <BaseInput
                        required
                        type="date"
                        name="endDate"
                        value={currentFile.file.endDate?.slice(0, 10)}
                        onChange={handleEditFileChange}
                      />
                    </BaseLabel>
                  </div>
                )}
                <div className="bg-grey-7 rounded p-4">
                  <div className="flex flex-wrap items-center gap-2 text-green-1 font-bold">
                    <div
                      className="flex place-items-center justify-center rounded-full bg-green-3 bg-opacity-20 w-8 h-8 cursor-pointer"
                      onClick={() => {
                        setCurrentFile({
                          ...currentFile,
                          file: {
                            ...currentFile.file,
                            alerts: [
                              ...currentFile.file.alerts,
                              {
                                name: "",
                                description: "",
                                hasClientAlert: false,
                                dueDate: new Date().toISOString().slice(0, 10),
                                recallDate: "",
                              },
                            ],
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    Alerte
                  </div>
                </div>
                {currentFile.file.alerts.map((alert, aIndex) => (
                  <div
                    key={aIndex}
                    className="bg-grey-7 rounded p-4 flex flex-col gap-3"
                  >
                    <BaseLabel required label="Nom de l'alerte">
                      <BaseInput
                        required
                        type="text"
                        value={alert.name}
                        onChange={(event) => {
                          //TODO
                          handleEditFileChange(event, aIndex, "name");
                        }}
                      />
                    </BaseLabel>
                    <BaseLabel label="Description">
                      <BaseInput
                        type="text"
                        value={alert.description}
                        onChange={(event) => {
                          handleEditFileChange(event, aIndex, "description");
                        }}
                      />
                    </BaseLabel>
                    <div className="flex gap-3">
                      <BaseLabel required label="Echeance">
                        <BaseInput
                          required
                          type="date"
                          value={alert.dueDate.slice(0, 10)}
                          onChange={(event) => {
                            handleEditFileChange(event, aIndex, "dueDate");
                          }}
                        />
                      </BaseLabel>
                      <BaseLabel label="Rappel">
                        <BaseInput
                          type="date"
                          value={alert.recallDate?.slice(0, 10)}
                          onChange={(event) => {
                            handleEditFileChange(event, aIndex, "recallDate");
                          }}
                        />
                      </BaseLabel>
                    </div>
                    <BaseButton
                      type="button"
                      styleType="danger"
                      content="Supprimer"
                      className="w-min place-self-end justify-self-end"
                      onClick={() => {
                        setCurrentFile({
                          ...currentFile,
                          file: {
                            ...currentFile.file,
                            alerts: currentFile.file.alerts.filter(
                              (_, i) => i !== aIndex
                            ),
                          },
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
              <BaseButton
                type="submit"
                styleType="primary"
                content="Enregistrer"
                className="mt-4 float-right"
              />
            </form>
          )}
        </BasePopup>
      </>
    </Page>
  );
}
