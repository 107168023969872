import React from "react";
import { useNavigate } from "react-router-dom";
import Badge from "./Badge";
import Section from "./Section";
import { useCheckTokenAndFetch } from "../auth";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { Map } from "leaflet";
import { marker } from "../utils";

type WorksitesList = Array<{
  id: number;
  name: string;
  locationLatitude: number;
  locationLongitude: number;
}>;

export default function TheDashboardMap() {
  const mapRef = React.useRef<Map>(null);
  const [worksites, setWorksites] = React.useState<WorksitesList>([]);
  const navigate = useNavigate();
  const checkTokenAndFetch = useCheckTokenAndFetch();

  React.useEffect(() => {
    checkTokenAndFetch(`/api/worksites/get_localisations?state=1`, {
      method: "GET",
    }).then((worksites: { "hydra:member": WorksitesList }) => {
      setWorksites(worksites["hydra:member"]);
    });
  }, [navigate]);
  React.useEffect(() => {
    if (worksites.length) {
      mapRef.current?.fitBounds(
        new L.LatLngBounds(
          worksites.map((worksite) => [
            worksite.locationLatitude,
            worksite.locationLongitude,
          ])
        )
      );
    }
  }, [worksites]);

  return (
    <Section
      title={
        <>
          Chantiers ouverts
          <Badge value={worksites.length} type="primary" />
        </>
      }
    >
      <MapContainer
        ref={mapRef}
        className="h-80 rounded-md z-0"
        center={[0, 0]}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {worksites.map((worksite, index) => (
          <Marker
            key={index}
            eventHandlers={{
              click: () => {
                navigate(`/chantiers/${worksite.id}`);
              },
            }}
            icon={marker}
            position={[worksite.locationLatitude, worksite.locationLongitude]}
          >
            <Tooltip>{worksite.name}</Tooltip>
          </Marker>
        ))}
      </MapContainer>
    </Section>
  );
}
