import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import logo354 from "../assets/logo354.png";
import BaseButton from "../components/BaseButton";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function CnlDownload() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDownloadLoading, setIsDownloadLoading] = React.useState<boolean>(false);

  function handleDownloadCNL(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsDownloadLoading(true);
    fetch(
      `${window.location.origin}:8000/api/worksites/${searchParams.get("worksite")}/export-cnl`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${searchParams.get("bearer")}`,
        },
      }
    )
    .then((res) => {
      setIsDownloadLoading(false);
      if (res.ok) {
        res.blob().then((blob) => {
          const contentDisposition = res.headers.get("Content-Disposition");
          const filenameMatch = contentDisposition?.match(/filename\*=UTF-8''(.+)/);
          const filename = filenameMatch ? decodeURIComponent(filenameMatch[1]) : "carnet-numerique-du-logement.zip";
          saveAs(blob, filename);
        });
        toast.success(
          "Votre dossier est en cours de téléchargement."
        );
      } else {
        toast.error("Ce contenu ne peut pas être téléchargé.");
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error("Une erreur est survenue");
    })
  }

  return (
    <div className="bg-grey-7 min-h-screen px-4 flex flex-col gap-20 items-center justify-center">
      <img src={logo354} alt="logo" />
      <div className="flex flex-col items-center justify-center">
        <BaseButton
          type="button"
          styleType="primary"
          content="Télécharger mon carnet numérique du logement"
          icon={
            isDownloadLoading
              ? {
                  icon: faSpinner,
                  className: "animate-spin",
                }
              : undefined
          }
          onClick={(e) => handleDownloadCNL(e)}
        />
      </div>

      <Link
        className="text-center font-extrabold text-green-1"
        to="/connexion"
      >
        Retour à la page de connexion
      </Link>
    </div>
  );
}
