import React from "react";
import { twMerge } from "tailwind-merge";
import { Switch } from "@headlessui/react";

type BaseSwitchProps = {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
};

export default function BaseSwitch(props: BaseSwitchProps) {
  const [isChecked, setIsChecked] = React.useState<boolean>(props.isChecked);

  React.useEffect(() => {
    setIsChecked(props.isChecked);
  }, [props]);

  return (
    <Switch
      checked={isChecked}
      onChange={props.onChange}
      className={twMerge(
        "inline-flex flex-shrink-0 h-4 w-7 border-2 border-transparent rounded-full cursor-pointer transition-colors",
        props.isChecked ? "bg-green-1" : "bg-grey-2"
      )}
    >
      <span
        className={twMerge(
          "h-3 w-3 rounded-full bg-white transition",
          isChecked ? "translate-x-3" : "translate-x-0"
        )}
      />
    </Switch>
  );
}
